import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { TrashIcon, PlusIcon, PencilIcon } from '@heroicons/react/outline'
import AdminField from '../admin-field'
import Button from '../button'
import EditCreateTranslationDialog from '../../reviews/your-reviews/dialogs/edit-create-translation.dialog'

const ReviewTranslationControls = ({
  review,
  isSubItem,
  locale,
  onDelete,
  alwaysVisible
}) => {
  const { t } = useTranslation()
  const [openEditDialogMode, setOpenEditDialogMode] = useState(false) // CREATE / EDIT

  return (
    <>
      <AdminField className="mt-8" alwaysVisible={alwaysVisible}>
        <div className="flex justify-between">
          <Button
            mode="error"
            Icon={TrashIcon}
            disabled={!isSubItem}
            onClick={() =>
              onDelete({
                reviewId: review.id,
                locale
              })
            }
          >
            {t('content:delete')}
          </Button>

          {isSubItem && (
            <Button
              mode="primary"
              Icon={PencilIcon}
              onClick={() => setOpenEditDialogMode('EDIT')}
            >
              {t('content:edit')}
            </Button>
          )}

          {!isSubItem && (
            <Button
              mode="primary"
              Icon={PlusIcon}
              onClick={() => setOpenEditDialogMode('CREATE')}
            >
              {t('content:create')}
            </Button>
          )}
        </div>
      </AdminField>

      {!!openEditDialogMode && (
        <EditCreateTranslationDialog
          isOpen={true}
          review={review}
          locale={locale}
          onClose={setOpenEditDialogMode}
          mode={openEditDialogMode}
        />
      )}
    </>
  )
}

ReviewTranslationControls.propTypes = {}

ReviewTranslationControls.defaultProps = {}

export default ReviewTranslationControls
