import React, { useEffect, useMemo, useState } from 'react'
import { bool, func, object, oneOf } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useCreateReviewTranslation from '../hooks/useCreateReviewTranslation'
import useEditReviewTranslation from '../hooks/useEditReviewTranslation'
import useDeeplTranslator from '../../../../hooks/useDeeplTranslator'

import Dialog from '../../../shared/dialog'
import ReviewItemForm from '../item-form'

const EditCreateTranslationDialog = ({ isOpen, review, locale, onClose, mode }) => {
  const { t } = useTranslation()
  const { translateTextArray, translateTextArrayLoading } = useDeeplTranslator()
  const [formData, setFormData] = useState({})
  const [validationErrors, setValidationErrors] = useState({})

  const { createReviewTranslation, createReviewTranslationLoading } =
    useCreateReviewTranslation()
  const { editReviewTranslation, editReviewTranslationLoading } =
    useEditReviewTranslation()

  const isEditMode = mode === 'EDIT'
  const editInitialized = Object.keys(formData).length > 0

  const requiredAnswerIdsdsMemo = useMemo(() => {
    if (!review.questions) {
      return []
    }

    const fieldIdsToValidate = [
      'locale',
      ...review.questions
        .filter((question) => ['INPUT', 'TEXTAREA'].includes(question.questionType))
        .map((question) => question.answerInstanceId)
    ]

    if (!!review.title?.[locale]) {
      fieldIdsToValidate.unshift('title')
    }

    return fieldIdsToValidate
  }, [review.questions, review.title, locale])

  // Init formdata if editing
  useEffect(() => {
    if (editInitialized || !isEditMode || !locale) {
      return
    }

    if (!requiredAnswerIdsdsMemo.length) {
      return
    }

    const formDataToInit = {
      title: review.title[locale],
      locale
    }

    requiredAnswerIdsdsMemo.forEach((answerId) => {
      const foundAnswer = review.questions.find(
        (question) => question.answerInstanceId === answerId
      )

      if (!foundAnswer) {
        return
      }

      formDataToInit[answerId] = foundAnswer.answerText[locale] || ''
    })

    setFormData(formDataToInit)
  }, [
    requiredAnswerIdsdsMemo,
    editInitialized,
    isEditMode,
    locale,
    review.questions,
    review.title
  ])

  const handleFormChange = (field, value) => {
    setValidationErrors((prevState) => {
      delete prevState[field]
      return prevState
    })

    setFormData((prevState) => {
      return {
        ...prevState,
        [field]: value
      }
    })
  }

  const handlePretranslate = (translatorInputData) => {
    translateTextArray(translatorInputData, (translatorResult) => {
      const mappedTranslatedItems = translatorResult.reduce((acc, translatedItem) => {
        acc[translatedItem.key] = translatedItem.text
        return acc
      }, {})

      setFormData((prevState) => {
        return {
          ...prevState,
          ...mappedTranslatedItems
        }
      })
      setValidationErrors({})
    })
  }

  const validateFormData = () => {
    let errors = {}

    requiredAnswerIdsdsMemo.forEach((reqQuestionId) => {
      const fieldValue = formData[reqQuestionId]

      if (!fieldValue || !fieldValue.trim().length) {
        errors[reqQuestionId] = 'forms:validation.general.required'
      }
    })

    setValidationErrors(errors)
    return !Object.keys(errors).length
  }

  const handleSubmit = () => {
    if (!validateFormData()) {
      return
    }

    const { locale, ...restFormData } = formData

    if (mode === 'CREATE') {
      createReviewTranslation(review.id, locale, restFormData, () => {
        onClose(false)
      })
    } else {
      // Edit
      editReviewTranslation(review.id, locale, restFormData, () => {
        onClose(false)
      })
    }
  }

  return (
    <Dialog
      className="!max-w-5xl"
      isOpen={isOpen}
      title={isEditMode ? t('content:edit') : t('content:create')}
      confirmText={t('content:save')}
      onConfirm={handleSubmit}
      onClose={onClose}
      loading={createReviewTranslationLoading || editReviewTranslationLoading}
    >
      <div className="flex gap-4 flex-col">
        <ReviewItemForm review={review} locale={review.originalLocale} />

        <hr />

        <ReviewItemForm
          review={review}
          locale={review.originalLocale}
          formData={formData}
          onChange={handleFormChange}
          isEditing={true}
          validationErrors={validationErrors}
          disableSitesSelect={isEditMode}
          onPretranslate={handlePretranslate}
          pretranslateLoading={translateTextArrayLoading}
        />
      </div>
    </Dialog>
  )
}

EditCreateTranslationDialog.propTypes = {
  isOpen: bool,
  review: object,
  onClose: func.isRequired,
  mode: oneOf(['EDIT', 'CREATE'])
}

EditCreateTranslationDialog.defaultProps = {}

export default EditCreateTranslationDialog
