import React from 'react'
import { bool, number, oneOfType, string } from 'prop-types'

import LoadingSpinner from './loading-spinner'

const ConditionalLoading = ({ loading, children, size }) => {
  if (loading) {
    return <LoadingSpinner className={`h-${size} w-${size}`} />
  }

  return children
}

ConditionalLoading.propTypes = {
  loading: bool,
  size: oneOfType([string, number])
}

ConditionalLoading.defaultProps = {
  size: 8
}

export default ConditionalLoading
