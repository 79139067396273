import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useUserContext } from '../contexts'

const UPDATE_USER_GQL = gql`
  mutation UpdateUserSettings($data: UserSettingsInput!) {
    appUpdateUserSettings(data: $data) {
      noNotificationsInquiry
      guideNotifications
      buyingIntentActivityNotifications
    }
  }
`

export default function useUpdateUSerSettings() {
  const { updateUser } = useUserContext()
  const [updateUserMutation] = useMutation(UPDATE_USER_GQL)

  const updateUserSettings = useCallback(
    (data) => {
      updateUser(data)

      return updateUserMutation({
        variables: {
          data
        }
      })
    },
    [updateUserMutation, updateUser]
  )

  return {
    updateUserSettings
  }
}
