import React, { useState, useEffect, useRef } from 'react'
import { bool, func, object, oneOfType, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useGenericFormManager from '../../../hooks/useGenericFormManager'
import useCreateReviewQuestion from '../../../hooks/manage-review-templates/useCreateReviewQuestion'
import useUpdateReviewQuestion from '../../../hooks/manage-review-templates/useUpdateReviewQuestion'
import useFetchAvailableSites from '../../../hooks/useFetchAvailableSites'

import ArchiveQuestion from './review-question/archive-question'
import Dialog from '../../shared/dialog'
import Button from '../../shared/button'
import MultipleTextareaI18nInputs from '../../shared/multiple-textarea-i18n-inputs'
import Alert from '../../shared/alert'
import RadioQuestionAlternatives from './question-alternatives/radio-question-alternatives'

import { hasRequiredString } from '../../../validators/general'
import { Select, ListItemBase } from '../../shared/select'
import Checkbox from '../../shared/checkbox'
import Label from '../../shared/label'

import { reviewQuestionSpecialNamesOptions } from './constants/review-question.constants'

const FIELD_TYPE = 'type'
const FIELD_SPECIAL_NAME = 'specialName'
const FIELD_REQUIRED = 'required'

const QUESTION_FORM_INSTR = [
  {
    name: FIELD_TYPE,
    validators: [
      { validator: hasRequiredString, error: 'forms:validation.general.required' }
    ]
  },
  {
    name: FIELD_REQUIRED
  }
]

const QUESTION_TYPES = [
  { value: 'RADIO', label: 'RADIO' },
  { value: 'TILES', label: 'TILES' },
  { value: 'INPUT', label: 'INPUT' },
  { value: 'TEXTAREA', label: 'TEXTAREA' },
  { value: 'RATING', label: 'RATING' }
]

const ManageReviewQuestionDialog = ({
  reviewTemplate,
  reviewQuestionId,
  onClose,
  allowEditLinkedQuestion
}) => {
  const { t } = useTranslation('adminPages')
  const open = !!reviewQuestionId
  const creatorMode = typeof reviewQuestionId === 'boolean'
  const reviewTemplateId = reviewTemplate.id
  const textAreasI18nRef = useRef(null)
  const [initTexts, setInitTexts] = useState({})

  const { locales } = useFetchAvailableSites()
  const { createReviewQuestion, createReviewQuestionLoading } = useCreateReviewQuestion()
  const { updateReviewQuestion, updateReviewQuestionLoading } = useUpdateReviewQuestion()

  const {
    formData,
    onChange,
    validate,
    changedFields,
    resetForm,
    validationErrors,
    setFormData
  } = useGenericFormManager(QUESTION_FORM_INSTR)

  const commonQuestionMode = formData.isCommonQuestion && !allowEditLinkedQuestion

  useEffect(() => {
    if (!reviewTemplate?.reviewQuestions) {
      return
    }

    const foundQuestion = reviewTemplate.reviewQuestions.find(
      (question) => question.id === reviewQuestionId
    )

    if (foundQuestion) {
      setFormData(foundQuestion)
      setInitTexts(foundQuestion.texts || {})
    }
  }, [reviewTemplate.reviewQuestions, reviewQuestionId, setFormData])

  const formLoading = createReviewQuestionLoading || updateReviewQuestionLoading

  const handleSubmit = () => {
    const thisFormValid = validate()
    const i18nFormValid = textAreasI18nRef?.current?.validate()

    if (!thisFormValid || !i18nFormValid) {
      return
    }

    if (creatorMode) {
      createReviewQuestion(reviewTemplateId, changedFields, handleClose)
    } else {
      updateReviewQuestion(reviewQuestionId, changedFields, handleClose)
    }
  }

  const handleClose = () => {
    onClose(false)

    setTimeout(() => {
      setInitTexts({})
      resetForm()
    }, 300)
  }

  const renderCustomFooter = () => {
    return (
      <div className="flex justify-between gap-3 mt-5 sm:mt-6">
        <div className="flex">
          <ArchiveQuestion
            reviewTemplateId={reviewTemplateId}
            reviewQuestionId={reviewQuestionId}
            onArchive={handleClose}
          />
        </div>

        <div className="flex gap-3">
          <Button mode="default" onClick={handleClose} disabled={formLoading}>
            {t('content:cancel')}
          </Button>

          <Button
            onClick={handleSubmit}
            loading={formLoading}
            disabled={commonQuestionMode}
          >
            {creatorMode ? t('content:create') : t('content:save')}
          </Button>
        </div>
      </div>
    )
  }
  const renderSpecialNameListItem = ({ option, focusedItemId, handleSelect }) => {
    return (
      <ListItemBase
        key={option.value}
        onClick={handleSelect.bind(null, option.value)}
        active={option.value === focusedItemId}
      >
        <div className="flex flex-col">
          <div>{option.label}</div>
          <div className="text-xs italic">{option.description}</div>
        </div>
      </ListItemBase>
    )
  }

  return (
    <Dialog
      className="!max-w-4xl w-fit"
      isOpen={open}
      title={creatorMode ? t('content:create') : t('content:edit')}
      renderFooter={renderCustomFooter}
    >
      {commonQuestionMode && (
        <div className="mb-4">
          <Alert>{t('reviewTemplates:template.manage.questionLinkedCantModify')}</Alert>
        </div>
      )}
      <div className="grid grid-flow-row gap-4">
        <MultipleTextareaI18nInputs
          ref={textAreasI18nRef}
          id="texts"
          locales={locales}
          onChange={onChange}
          disabled={formLoading || commonQuestionMode}
          texts={initTexts}
        />

        <Label title="TODO Special Name" marginless>
          <Select
            id={FIELD_SPECIAL_NAME}
            options={reviewQuestionSpecialNamesOptions}
            onChange={onChange}
            value={formData[FIELD_SPECIAL_NAME]}
            disabled={formLoading || commonQuestionMode}
            renderListItem={renderSpecialNameListItem}
            rawChangeEvent={false}
            isClearable
          />
        </Label>

        <Label title={t('content:type')} marginless>
          <Select
            id={FIELD_TYPE}
            options={QUESTION_TYPES}
            value={formData[FIELD_TYPE]}
            rawChangeEvent={false}
            onChange={onChange}
            errorText={t(validationErrors[FIELD_TYPE])}
            disabled={formLoading || commonQuestionMode}
          />
        </Label>

        <Checkbox
          id={FIELD_REQUIRED}
          label={t('content:required')}
          onChange={onChange}
          rawChangeEvent={false}
          disabled={formLoading || commonQuestionMode}
          checked={formData[FIELD_REQUIRED]}
        />

        {['RADIO', 'TILES'].includes(formData.type) && (
          <RadioQuestionAlternatives question={formData} readOnly={commonQuestionMode} />
        )}
      </div>
    </Dialog>
  )
}

ManageReviewQuestionDialog.propTypes = {
  reviewTemplate: object,
  reviewQuestionId: oneOfType([string, bool]),
  onClose: func.isRequired
}

ManageReviewQuestionDialog.defaultProps = {}

export default ManageReviewQuestionDialog
