import { useCallback } from 'react'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { REVIEW_TEMPLATE_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

export const GQL_FETCH_REVIEW_TEMPLATE = gql`
  query FetchReviewTemplate($reviewTemplateId: ID!) {
    reviewTemplate(reviewTemplateId: $reviewTemplateId) {
      ${REVIEW_TEMPLATE_GQL}
    }
  }
`

const EMPTY_OBJ = {}

export default function useFetchReviewTemplate(skip, fetchPolicy) {
  const { reviewTemplateId } = useParams()

  const { data, loading, refetch, networkStatus } = useQuery(GQL_FETCH_REVIEW_TEMPLATE, {
    variables: {
      reviewTemplateId
    },
    skip: !reviewTemplateId || skip,
    fetchPolicy
  })

  const refetchReviewTemplate = useCallback(() => {
    if (data) {
      return refetch()
    }
  }, [refetch, data])

  return {
    reviewTemplate: data?.reviewTemplate || EMPTY_OBJ,
    reviewTemplateLoading: (!data && loading) || networkStatus === NetworkStatus.refetch,
    refetchReviewTemplate
  }
}
