import React, { useState } from 'react'
import { func, object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useAutoSaveSingle from '../../../hooks/useAutoSaveSingle'
import useApproveReview from './hooks/useApproveReview'
import useEditReview from './hooks/useEditReviewData'
import useSendVerificationEmail from './hooks/useSendVerificationEmail'

import ReviewItemForm from '../../reviews/your-reviews/item-form'
import Button from '../../shared/button'
import SitesSelect from '../../shared/sites-select'
import Input from '../../shared/input'
import ModalNotification from '../../modalNotification'

const OurComment = ({ reviewDataId, cachedComment, onEditComment, onChange }) => {
  const { setAutoSaveValue: handleChangeComment, autoSaveValue: ourComment } =
    useAutoSaveSingle(cachedComment, (newComment) => {
      onEditComment(reviewDataId, newComment)
    })

  const handleChagne = (value) => {
    onChange()
    handleChangeComment(value)
  }

  return <Input value={ourComment || ''} onChange={handleChagne} />
}

const ReviewItem = ({ review, isSubItem, getCategoriesStringFn, shownLocale }) => {
  const { t } = useTranslation(['adminPages'])

  const [commentChangesLoading, setCommentChangesLoading] = useState(false)
  const [visibleVerificationModal, setVisibleVerificationModal] = useState(false)

  const { approveReview, approveReviewLoading } = useApproveReview()
  const { editReviewData, editReviewDataLoading } = useEditReview()
  const {
    sendVerificationEmail,
    sendVerificationEmailLoading,
    sendVerificationEmailCalled
  } = useSendVerificationEmail()

  const customerType = review.systemV2.systemSiteData.find(
    (systemSiteData) => systemSiteData.locale === shownLocale
  )?.customerType

  const handleSendVerificationEmail = (reviewId) => {
    sendVerificationEmail(reviewId, () => {
      setVisibleVerificationModal(true)
    })
  }

  const handleChangeApprove = (reviewId, approve) => {
    approveReview(reviewId, approve)
  }

  const handleEditLocale = (reviewId, locale) => {
    editReviewData(reviewId, {
      originalLocale: locale
    })
  }

  const handleEditComment = (reviewId, updatedComment) => {
    editReviewData(reviewId, {
      ourComment: updatedComment
    }).finally(() => setCommentChangesLoading(false))
  }

  return (
    <div key={review.id}>
      <div className="flex gap-6 flex-col">
        {!isSubItem && (
          <div className="text-sm flex gap-8 flex-col md:flex-row">
            <ul className="flex-1 font-medium text-gray-900">
              <li>
                {review.email}
                {review.emailApproved ? (
                  <p className="text-green-600">VERIFIED</p>
                ) : (
                  <p className="text-red-600">UNVERIFIED</p>
                )}
              </li>
              <li>
                {!review.emailApproved && (
                  <Button
                    type="button"
                    size="small"
                    onClick={() => handleSendVerificationEmail(review.id)}
                    disabled={sendVerificationEmailCalled}
                    loading={sendVerificationEmailLoading}
                  >
                    {t('adminApproveReviews.button.sendVerificationEmail')}
                  </Button>
                )}
              </li>
              <li className="text-gray-500">
                <span>
                  {t('content:customerType')}: {customerType}
                </span>
              </li>
            </ul>
            <ul className="flex-1">
              <li className="text-gray-500">
                {getCategoriesStringFn(review.systemV2.categories)}
              </li>
              <li>
                <SitesSelect
                  onChange={(locale) => handleEditLocale(review.id, locale)}
                  value={shownLocale}
                  disableAutoInit
                  loading={editReviewDataLoading}
                  marginless
                />
              </li>
            </ul>
          </div>
        )}

        <ReviewItemForm review={review} locale={shownLocale} />
      </div>
      {!isSubItem && (
        <div className="mt-4 flex gap-3 flex-col sm:flex-row sm:items-end">
          <div className="flex-1">
            <label
              htmlFor="ourComment"
              className="block text-sm font-medium text-gray-700"
            >
              {t('sharedReviewFields.ourComment')}
            </label>
            <div className="mt-1">
              <OurComment
                reviewDataId={review.id}
                cachedComment={review.ourComment}
                onEditComment={handleEditComment}
                onChange={() => setCommentChangesLoading(true)}
              />
            </div>
          </div>
          <div className="flex sm:flex gap-3 justify-end">
            <Button
              mode="error"
              disabled={
                approveReviewLoading || commentChangesLoading || review.approved === false
              }
              onClick={() => handleChangeApprove(review.id, false)}
            >
              {t('adminApproveReviews.button.reject')}
            </Button>

            <Button
              disabled={review.approved === true}
              loading={approveReviewLoading || commentChangesLoading}
              onClick={() => handleChangeApprove(review.id, true)}
            >
              {t('adminApproveReviews.button.approve')}
            </Button>
          </div>
        </div>
      )}

      <ModalNotification
        title={t('adminApproveReviews.sendVerificationEmailSent.title')}
        text={<span>{t('adminApproveReviews.sendVerificationEmailSent.text')} </span>}
        visible={visibleVerificationModal}
        handleVisible={() => {
          setVisibleVerificationModal(false)
        }}
      />
    </div>
  )
}

ReviewItem.propTypes = {
  review: object.isRequired,
  getCategoriesStringFn: func.isRequired
}

ReviewItem.defaultProps = {}

export default ReviewItem
