import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_QUESTION_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const REORDER_REVIEW_QUESTION_ALTERNATIVES_GQL = gql`
  mutation ReorderReviewQuestionAlternatives(
    $questionId: ID!
    $reviewQuestionAnswerIds: [String!]!
  ) {
    reorderReviewQuestionAlternatives(questionId: $questionId, reviewQuestionAnswerIds: $reviewQuestionAnswerIds) {
      ${REVIEW_TEMPLATE_QUESTION_GQL}
    }
  }
`
export default function useReorderReviewQuestionAlternatives() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [reorderReviewQuestionAlternativesMutation, { loading }] = useMutation(
    REORDER_REVIEW_QUESTION_ALTERNATIVES_GQL
  )

  const reorderReviewQuestionAlternatives = useCallback(
    (questionId, reviewQuestionAnswerIds, onCompleted) => {
      return reorderReviewQuestionAlternativesMutation({
        variables: {
          questionId,
          reviewQuestionAnswerIds
        },
        onCompleted: ({ reorderReviewQuestionAlternatives }) => {
          onCompleted && onCompleted(reorderReviewQuestionAlternatives)
          showSuccessNotification()
        },
        onError: showErrorNotification
      })
    },
    [
      reorderReviewQuestionAlternativesMutation,
      showSuccessNotification,
      showErrorNotification
    ]
  )

  return {
    reorderReviewQuestionAlternatives,
    reorderReviewQuestionAlternativesLoading: loading
  }
}
