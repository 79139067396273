import React, { useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { gql } from '@apollo/client'
import useAdminQuery from '../hooks/useAdminQuery'
import useUpdateWatchedBuyingIntent from '../hooks/useUpdateWatchedBuyingIntent'

import useFetchSystems from '../hooks/useFetchSystems'
import useGetSystemName from '../hooks/useGetSystemName'

import {
  CheckCircleIcon,
  ChevronRightIcon,
  CalendarIcon,
  InformationCircleIcon
} from '@heroicons/react/solid'

import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'

import Expandable from '../components/shared/expandable'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import GenerateCsvButton from '../components/shared/generate-csv-btn'
import Filters from '../components/shared/filters'

import AdminField from '../components/shared/admin-field'

import * as ga from '../lib/ga'

import styles from '../styles/page.module.css'
import Flag from '../components/shared/flag'

const QUERY = gql`
  query BuyingIntent($companyId: ID, $userId: ID) {
    appBuyingIntentSignals(companyId: $companyId, userId: $userId) {
      hasAccess
      systemLeads {
        systemId
        name
        locale
        website
        companySize
        employeeMin
        employeeMax
        financialsMin
        financialsMax
        linkedInCategory
        intent
        date
        isNew
        locales
        signaldata
      }
    }
  }
`

const GET_SYSTEMS_NAV = gql`
  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      active
      systemSiteData {
        systemName
        locale
      }
    }
  }
`

function between(x, min, max) {
  return x >= min && x <= max
}

const BuyingIntentSignal = function ({
  signal,
  isNew,
  onUpdateWatchedBuyingIntent,
  systemName
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [hasOpen, setHasOpen] = useState(false)
  const { t } = useTranslation('buyingIntent')

  return (
    <li>
      <div
        className="block hover:bg-gray-50 cursor-pointer"
        type="button"
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          console.log('TODO: implement key', e)
        }}
        onClick={() => {
          const isClosed = !isOpen

          setIsOpen(!isOpen)
          if (isNew && !hasOpen) {
            setHasOpen(true)
            onUpdateWatchedBuyingIntent(signal.id)
          }

          if (isClosed) {
            ga.event({
              action: 'open_lead_buying_intent'
            })
          }
        }}
      >
        {isNew && (
          <span className="absolute inline-flex items-center px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800">
            {t('form.new')}
          </span>
        )}
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={signal.locale} />
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-blue-800 truncate">
                  {signal.name}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <time dateTime={signal.date}>
                    {signal.date.toLocaleString('sv').substr(0, 10)}
                  </time>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="flex text-sm">
                    <p className="font-medium truncate">{systemName}</p>
                  </div>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <CheckCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                    {t('filters.strength')}: {signal.intent}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-10 py-5 bg-gray-50">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('form.information')}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {t('form.companyInformation')}.
            </p>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {signal.name && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:company')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 flex">{signal.name}</dd>
                </div>
              )}
              {signal.website && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:site')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal.website}
                  </dd>
                </div>
              )}
              {signal.linkedInCategory && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:category')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal.linkedInCategory}
                  </dd>
                </div>
              )}
              {signal.employeeMin && signal.employeeMax && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('form.employees')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal.employeeMin}-{signal.employeeMax}
                  </dd>
                </div>
              )}
              {signal.financialsMin && signal.financialsMax && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('filters.revenue')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {`${Number(signal.financialsMin).toLocaleString('sv-SE')} - ${Number(
                      signal.financialsMax
                    ).toLocaleString('sv-SE')}`}
                  </dd>
                </div>
              )}

              {signal?.locales?.length > 1 && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:markets')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal.locales.map(
                      (locale2) =>
                        locale2 !== signal.locale && (
                          <Flag
                            className="inline h-5 w-5 ml-1"
                            key={locale2}
                            locale={locale2}
                            height={`25px`}
                          />
                        )
                    )}
                  </dd>
                </div>
              )}
              <AdminField>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 bg-red-200 rounded-lg">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:signalData')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <pre>{JSON.stringify(signal.signaldata, null, 2)}</pre>
                  </dd>
                </div>
              </AdminField>
            </dl>
          </div>
        </div>
      )}
    </li>
  )
}

function formatSystems(signals) {
  if (!signals) {
    return null
  }

  const systems = signals.reduce((prev, curr) => {
    const prevObj = prev

    // TODO: Add systemId
    const systemId = curr.systemId
    // const systemName = curr.systemName;
    // const category = curr.category;
    if (prevObj[systemId]) {
      prevObj[systemId] = [...prevObj[systemId], curr]
    } else {
      prevObj[systemId] = [curr]
    }

    return prevObj
  }, {})

  return systems
}

const filtersArray = [
  {
    id: 'companySize',
    nameTid: 'buyingIntent:filters.companySize',
    options: [
      { value: 'MICRO', label: '1-10' },
      { value: 'SMALL', label: '11-100' },
      { value: 'MEDIUM', label: '101-500' },
      { value: 'LARGE', label: '500+' }
    ]
  },
  {
    id: 'revenue',
    nameTid: 'buyingIntent:filters.revenue',
    options: []
  },
  {
    id: 'intent',
    nameTid: 'buyingIntent:filters.strength',
    options: [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' }
    ]
  }
]

const BuyingIntentPage = function () {
  const [whatIsOpen, setWhatIsOpen] = useState(false)
  const { t } = useTranslation('buyingIntent')
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)
  const { updateWatchedBuyingIntent } = useUpdateWatchedBuyingIntent()
  const { availableSystems } = useFetchSystems(GET_SYSTEMS_NAV, 'cache-first')
  const { getSystemName } = useGetSystemName()

  const systemsArray = availableSystems?.map((system) => ({
    id: system.id,
    name: getSystemName(system)
  }))

  const [filters, setFilters] = useState(filtersArray)
  const [systems, setSystems] = useState(null)
  const [allSignals, setAllSignals] = useState(null)
  const [hasAccess, setHasAccess] = useState(null)
  const [isNewLeadMap, setIsNewLeadMap] = useState({})

  const { loading, error } = useAdminQuery(QUERY, {
    onCompleted: (data) => {
      if (data?.appBuyingIntentSignals.systemLeads.length) {
        const revenueOptions = []
        data.appBuyingIntentSignals.systemLeads.forEach((dataItem) => {
          if (dataItem.financialsMax && dataItem.financialsMin) {
            revenueOptions.push({
              value: `${dataItem.financialsMin}-${dataItem.financialsMax}`,
              label: `${Number(dataItem.financialsMin).toLocaleString(
                'sv-SE'
              )} - ${Number(dataItem.financialsMax).toLocaleString('sv-SE')}`,
              checked: false
            })
          }
        })

        const result = []
        const map = new Map()
        for (const item of revenueOptions) {
          if (!map.has(item.value)) {
            map.set(item.value, true) // set any value to Map
            result.push({
              value: item.value,
              label: item.label,
              checked: false
            })
          }
        }

        // TODO: Sort
        const sortedRevenueOptions = result.sort((a, b) => {
          return a.value.split('-')[0] - b.value.split('-')[0]
        })

        const copyOfFilters = filters
        copyOfFilters[1].options = sortedRevenueOptions

        const isNewMap = data.appBuyingIntentSignals.systemLeads.reduce((acc, curr) => {
          acc[curr.id] = curr.isNew
          return acc
        }, {})

        setIsNewLeadMap(isNewMap)
        setSystems(formatSystems(data.appBuyingIntentSignals.systemLeads))
        setAllSignals(data.appBuyingIntentSignals.systemLeads)
        setFilters(copyOfFilters)
      }

      setHasAccess(!!data?.appBuyingIntentSignals?.hasAccess)
    }
  })

  const handleFiltersChanged = (newActiveFilters, mappedActiveFilters) => {
    // No Filters
    if (!Object.keys(newActiveFilters).length) {
      setSystems(formatSystems(allSignals))
      return
    }

    // Has filters
    const companySizesInFilter = mappedActiveFilters.companySize || []
    const revenueInFilter = mappedActiveFilters.revenue || []
    const intentInFilter = mappedActiveFilters.intent || []

    const filteredSignals = allSignals?.filter((signal) => {
      let matchCompany = true
      if (companySizesInFilter.length) {
        matchCompany = signal.companySize.some((size) =>
          companySizesInFilter.includes(size)
        )
      }

      let matchIntent = true
      if (intentInFilter.length) {
        matchIntent = intentInFilter.includes(signal.intent.toString())
      }

      let matchRevenue = true

      if (revenueInFilter.length) {
        if (signal.financialsMin.length < 1 || signal.financialsMax.length < 1) {
          matchRevenue = false
        } else {
          matchRevenue = revenueInFilter.some((filter) => {
            const filterSplit = filter.split('-')
            const filterRevMin = Number(filterSplit[0])
            const filterRevMax = Number(filterSplit[1])
            const isMinBetween = between(signal.financialsMin, filterRevMin, filterRevMax)
            const isMaxBetween = between(signal.financialsMax, filterRevMin, filterRevMax)

            return (
              isMinBetween ||
              isMaxBetween ||
              (signal.financialsMin < filterRevMin &&
                signal.financialsMax > filterRevMax) ||
              (signal.financialsMin < filterRevMin && !signal.financialsMax)
            )
          })
        }
      }

      return matchCompany && matchRevenue && matchIntent
    })

    setSystems(formatSystems(filteredSignals))
  }

  const handleUpdateWatchedBuyingIntent = (idOrIds) => {
    updateWatchedBuyingIntent(idOrIds, (updatedLeads) => {
      setIsNewLeadMap((prevState) => {
        updatedLeads.forEach((lead) => {
          prevState[lead.id] = lead.isNew
        })

        return prevState
      })
    })
  }

  const onExportedCsvData = (csvData) => {
    const watchedBuingIntentsIds = csvData.data.map((buyingIntent) => buyingIntent.id)
    handleUpdateWatchedBuyingIntent(watchedBuingIntentsIds)
  }

  const hasData = !!(systems && Object.keys(systems).length)

  return (
    <>
      <PageHeader
        pageName={t('buyingIntent:header.title')}
        headerClassName="flex items-end gap-1"
        headerRightComponent={
          <button
            className="border-none underline text-sm p-1 text-indigo-600 leading-none"
            onClick={() => setWhatIsOpen(!whatIsOpen)}
          >
            {t('buyingIntent:header.what.title_1')}
          </button>
        }
      >
        <p>{t('buyingIntent:header.subtitle')}</p>
        {!loading && hasAdmin ? `Har access till köpsignaler:` : null}
        {!loading && hasAdmin ? <strong>{hasAccess ? ' JA' : ' NEJ'}</strong> : null}

        <Expandable className="mt-2 text-sm" open={whatIsOpen}>
          <div className="p-3 border rounded">
            <p className="font-medium">{t('buyingIntent:header.what.title_1')}</p>
            <Trans i18nKey="buyingIntent:header.what.text_1" components={{}} />
            <p className="font-medium mt-3">{t('buyingIntent:header.what.title_2')}</p>
            <Trans
              i18nKey="buyingIntent:header.what.text_2"
              components={{
                ul: <ul className="list-disc ml-6 mt-2" />,
                li: <li />
              }}
            />
          </div>
        </Expandable>
      </PageHeader>

      <PageBody loading={loading}>
        {error && (
          <div style={{ textAlign: 'center' }} className={styles.errorMsg}>
            {t('content:error.somethingWentWrongTryAgainLater')}
            <br />
            <i>
              <small>({error.message})</small>
            </i>
          </div>
        )}

        {!error && <Filters filters={filters} onChangeFilters={handleFiltersChanged} />}

        <div className="p-2">
          {hasData &&
            Object.values(systems).map((system) => {
              const systemName = systemsArray?.find(
                (systemObj) => systemObj.id === system[0].systemId
              )?.name
              const signals = system.map((signal) => (
                <BuyingIntentSignal
                  key={signal.id}
                  signal={signal}
                  systemName={systemName}
                  isNew={isNewLeadMap[signal.id]}
                  onUpdateWatchedBuyingIntent={handleUpdateWatchedBuyingIntent}
                />
              ))

              const csvData = {
                headers: [
                  { label: 'Category', key: 'category' },
                  { label: 'System Name', key: 'systemName' },
                  { label: 'Company', key: 'name' },
                  { label: 'Website', key: 'website' },
                  { label: 'Intent', key: 'intent' },
                  { label: 'Employees Min', key: 'employeeMin' },
                  { label: 'Employees Max', key: 'employeeMax' },
                  { label: 'Revenue Min', key: 'financialsMin' },
                  { label: 'Revenue Max', key: 'financialsMax' },
                  { label: 'Linked in category', key: 'linkedInCategory' }
                ],
                data: system
              }

              return (
                <div key={system[0].systemId} className="mb-8">
                  <div className="flex items-center px-1 justify-between">
                    <p className="text-xl leading-normal">
                      <span className="font-semibold">{systemName}</span>
                    </p>

                    <div className="flex align-end">
                      <GenerateCsvButton
                        filename="buying-intents.csv"
                        csvData={csvData}
                        onClick={onExportedCsvData}
                        gaActionName="export_buying_intents_csv"
                      />
                    </div>
                  </div>

                  <div className="bg-white shadow overflow-hidden mt-3 sm:rounded-md">
                    <ul className="divide-y divide-gray-200">{signals}</ul>
                  </div>
                </div>
              )
            })}
          {!loading && !error && !hasData && (
            <div className="rounded-md bg-blue-50 p-4 border border-blue-300">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">Tvärr inte någon data än.</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </PageBody>
    </>
  )
}

export default BuyingIntentPage
