import React, { useState, useMemo } from 'react'
import { PageHeader } from '../components/page-header'
import { useTranslation } from 'react-i18next'
import { PageBody } from '../components/page-body'
import BuyingIntentActivitySettings from '../components/buying-intents/BuyingIntentActivitySettings'

import { useUserContext } from '../contexts'
import { isAdmin, isAppAdmin } from '../utils'

const BuyingIntentV2SettingsPage = () => {
  const { t } = useTranslation('buyingIntent')

  const { user } = useUserContext()
  const admin = isAdmin(user?.role)
  const appAdmin = isAppAdmin(user?.appRole)

  if (!(admin || appAdmin)) {
    return
  }

  return (
    <>
      <PageHeader pageName={'Buying Intents 2.0'} headerClassName="flex items-end gap-1">
        <p className={'my-2'}>{t('buyingIntent:header.subtitle')}</p>
      </PageHeader>
      <PageBody>
        <BuyingIntentActivitySettings />
      </PageBody>
    </>
  )
}

export default BuyingIntentV2SettingsPage
