import React, { useMemo } from 'react'

import Fields from '../../form/fields'
import AdminField from '../../shared/admin-field'
import { profileFields } from '../../../constants'

import { useManageSystemContext } from '../context'
import { useUserContext } from '../../../contexts'
import { isAdmin, isBasicCustomer } from '../../../utils'

import Label from '../../shared/label'
import CountryFormProfileLinks from './country-form/profile-links'
import DeleteSiteData from './system-form/delete-site-data'
import AdminGuideOverrides from './country-form/admin-guide-overrides'
import AdminSiteRedirects from '../../admin/admin-site-redirect'
import { useTranslation } from 'react-i18next'
import BuyingIntentConfig from './buying-intents/BuyingIntentConfig'

const CountryFormFields = () => {
  const { t } = useTranslation()
  const { fieldsData, activeTab, handleFieldChange, langFields } =
    useManageSystemContext()
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)
  const locale = activeTab.value

  const selectedSiteDataByCountryMemo = useMemo(() => {
    if (!fieldsData.systemSiteData || !locale) {
      return {}
    }

    return (
      fieldsData.systemSiteData.find(
        (systemSiteData) => systemSiteData.locale === locale
      ) || {}
    )
  }, [fieldsData, locale])

  const countrySpecificTidDataMemo = useMemo(() => {
    const nameTidData = {}
    const domain = selectedSiteDataByCountryMemo.site?.domain
    const systemName = selectedSiteDataByCountryMemo.systemName

    profileFields.adminFieldsCountrySpecific.forEach((field) => {
      nameTidData[field.id] = {
        locale: locale.replace('-', '_')
      }
    })

    return {
      textTidData: {
        systemExternalUrl: {
          domain
        },
        extraBtnLink: {
          domain
        }
      },
      nameTidData: {
        ...nameTidData,
        introDescriptionWhat: {
          systemName
        },
        introDescriptionWho: {
          systemName
        },
        marketPositionDescription: {
          locale
        }
      }
    }
  }, [selectedSiteDataByCountryMemo, locale])
  return (
    <>
      <AdminField>
        <AdminGuideOverrides systemSiteData={selectedSiteDataByCountryMemo} />
      </AdminField>
      <BuyingIntentConfig forLocale={locale} />
      <AdminField>
        <Fields
          inputFields={profileFields.adminFieldsCountrySpecific}
          fieldsData={selectedSiteDataByCountryMemo}
          isAdmin={hasAdmin}
          isBasic={isBasicCustomer(selectedSiteDataByCountryMemo)}
          handleFieldChange={(e) => {
            handleFieldChange(e, locale)
          }}
          tidData={countrySpecificTidDataMemo}
        />
        <AdminSiteRedirects redirects={selectedSiteDataByCountryMemo?.redirects} />
      </AdminField>

      {isBasicCustomer(selectedSiteDataByCountryMemo) && (
        <Label
          labelClassname="text-red-500"
          title={t('content:warning.notACustomerProfile.title', {
            locale: selectedSiteDataByCountryMemo?.locale
          })}
          subTitle={t('content:warning.notACustomerProfile.subTitle', {
            locale: selectedSiteDataByCountryMemo?.locale
          })}
        />
      )}
      <CountryFormProfileLinks
        domain={selectedSiteDataByCountryMemo.site?.domain}
        systemNameUrl={selectedSiteDataByCountryMemo.systemNameUrl}
        customerType={selectedSiteDataByCountryMemo.customerType}
      />

      <Fields
        inputFields={langFields}
        fieldsData={selectedSiteDataByCountryMemo}
        isAdmin={hasAdmin}
        isBasic={isBasicCustomer(selectedSiteDataByCountryMemo)}
        handleFieldChange={(e) => {
          handleFieldChange(e, locale)
        }}
        tidData={countrySpecificTidDataMemo}
      />

      <div className="mt-12">
        <DeleteSiteData siteData={selectedSiteDataByCountryMemo} />
      </div>
    </>
  )
}

CountryFormFields.propTypes = {}

CountryFormFields.defaultProps = {}

export default CountryFormFields
