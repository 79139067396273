import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { TRANSFORMED_REVIEW_FRAGMENT } from '../../../../constants/gql/reviews'

const EDIT_REVIEW_TRANSLATION_GQL = gql`
  mutation AdminEditReviewTranslation($reviewId: ID!, $locale: String!, $data: Json!) {
    appAdminEditReviewTranslation(reviewId: $reviewId, locale: $locale, data: $data) {
      ${TRANSFORMED_REVIEW_FRAGMENT}
    }
  }
`

export default function useEditReviewTranslation() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appAdminEditReviewTranslation, { loading }] = useMutation(
    EDIT_REVIEW_TRANSLATION_GQL
  )

  const editReviewTranslation = useCallback(
    (reviewId, locale, data, onCompleted) => {
      return appAdminEditReviewTranslation({
        variables: {
          reviewId,
          locale,
          data
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appAdminEditReviewTranslation, showSuccessNotification, showErrorNotification]
  )

  return {
    editReviewTranslation,
    editReviewTranslationLoading: loading
  }
}
