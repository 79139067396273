import React, { useMemo, useState } from 'react'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import useFetchCommonReviewTemplate from '../../../../hooks/manage-review-templates/useFetchCommonReviewTemplate'
import useLinkCommonReviewQuestion from '../../../../hooks/manage-review-templates/useLinkCommonReviewQuestion'

import { PlusCircleIcon, LinkIcon } from '@heroicons/react/outline'
import Dialog from '../../../shared/dialog'
import { Select } from '../../../shared/select'

const EMPTY_ARR = []

const QuestionCreatorItem = ({
  reviewTemplateId,
  onRequestCreate,
  selectedLocale,
  hideQuestionLink
}) => {
  const { t } = useTranslation()
  const [linkDialogOpen, setLinkDialogOpen] = useState(false)
  const [commonQuestionId, setCommonQuestionId] = useState(false)
  const { commonReviewTemplate } = useFetchCommonReviewTemplate()

  const { linkCommonReviewQuestion, linkCommonReviewQuestionLoading } =
    useLinkCommonReviewQuestion()

  const reviewQuestions = commonReviewTemplate?.reviewQuestions || EMPTY_ARR

  const commonQuestionsOptions = useMemo(() => {
    return reviewQuestions.map((question, index) => {
      const labelText = question.texts?.[selectedLocale] || question.texts?.en_GB

      return {
        label: `${index + 1}. ${labelText}`,
        value: question.id
      }
    })
  }, [reviewQuestions, selectedLocale])

  const handleOpenLinkDialog = () => {
    setLinkDialogOpen(true)
  }

  const handleLinkQuestion = () => {
    linkCommonReviewQuestion(reviewTemplateId, commonQuestionId, () => {
      handleCloseLinkDialog()
    })
  }

  const handleCloseLinkDialog = () => {
    setLinkDialogOpen(false)

    setTimeout(() => {
      setCommonQuestionId(false)
    }, 300)
  }

  return (
    <div className="flex flex-cols bg-white border border-gray-300 rounded">
      <div
        className={cx(
          'flex flex-1 flex-col gap-1 justify-center items-center p-4 cursor-pointer hover:bg-slate-300',
          {
            'border-r': !hideQuestionLink
          }
        )}
        onClick={onRequestCreate}
      >
        <span className="text-gray-600">{t('content:create')}</span>
        <PlusCircleIcon className="w-6 text-gray-600" />
      </div>

      {!hideQuestionLink && (
        <div
          className="flex flex-1 flex-col gap-1 justify-center items-center p-4 cursor-pointer hover:bg-slate-300"
          onClick={handleOpenLinkDialog}
        >
          <span className="text-gray-600">{t('content:link')}</span>
          <LinkIcon className="w-6 text-gray-600" />
        </div>
      )}

      <Dialog
        title={t('content:link')}
        confirmText={t('content:link')}
        isOpen={linkDialogOpen}
        onConfirm={handleLinkQuestion}
        confirmDisabled={!commonQuestionId}
        onClose={handleCloseLinkDialog}
        loading={linkCommonReviewQuestionLoading}
      >
        <Select
          options={commonQuestionsOptions}
          onChange={setCommonQuestionId}
          value={commonQuestionId}
          rawChangeEvent={false}
        />
      </Dialog>
    </div>
  )
}

QuestionCreatorItem.propTypes = {}

QuestionCreatorItem.defaultProps = {}

export default QuestionCreatorItem
