export const reviewQuestionSpecialNamesOptions = [
  {
    value: 'USER_ROLE',
    label: 'USER_ROLE',
    description: 'Used for: Displaying role on top of review card.',
    recommendedPresent: true
  },
  {
    value: 'COMPANY_SIZE',
    label: 'COMPANY_SIZE',
    description: 'Used for: Displaying company size on top of review card.',
    noOptionWarningText: 'TODO WARNING COMPANY_SIZE',
    recommendedPresent: true
  },
  {
    value: 'LIKE_MOST',
    label: 'LIKE_MOST',
    description: 'Used for: Displaying reviews on system comparision page',
    noOptionWarningText: 'TODO WARNING LIKE_MOST',
    recommendedPresent: true
  },
  {
    value: 'LIKE_LEAST',
    label: 'LIKE_LEAST',
    description: 'Used for: Displaying reviews on system comparision page',
    noOptionWarningText: 'TODO WARNING LIKE_LEAST',
    recommendedPresent: true
  },
  {
    value: 'RATING_PRICE_RELATION',
    label: 'RATING_PRICE_RELATION',
    description: 'Used for: Systemguide results rating column',
    noOptionWarningText: 'TODO WARNING RATING_PRICE_RELATION',
    recommendedPresent: true
  },
  {
    value: 'RATING_MET_EXPECTATIONS',
    label: 'RATING_MET_EXPECTATIONS',
    description: 'Used for: Systemguide results rating column',
    noOptionWarningText: 'TODO WARNING RATING_MET_EXPECTATIONS',
    recommendedPresent: true
  }
]
