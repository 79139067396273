import React from 'react'
import { object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useGenericFormManager from '../../../../hooks/useGenericFormManager'
import useUpdateReviewTemplate from '../../../../hooks/manage-review-templates/useUpdateReviewTemplate'

import Button from '../../../shared/button'
import TogglePublishReviewTemplate from './toggle-published-review-template'
import ArchiveTemplate from './archive-template'
import ReviewTemplateGeneralForm, {
  REVIEW_TEMPLATE_GENERAL_FORM_INSTR
} from '../review-template-general-form'
import QuestionsOverview from './questions-overview'
import TemplateWarnings from './template-warnings'

const ManageReviewTemplate = ({ reviewTemplate }) => {
  const { t } = useTranslation()
  const { updateReviewTemplate, updateReviewTemplateLoading } = useUpdateReviewTemplate()
  const {
    formData,
    onChange,
    validate,
    validationErrors,
    changedFields,
    resetChangedFields
  } = useGenericFormManager(REVIEW_TEMPLATE_GENERAL_FORM_INSTR, reviewTemplate)

  const hasUnsavedData = Object.keys(changedFields).length > 0
  const isCommonTemplate = reviewTemplate.categories.includes('ALL')
  const reviewTemplateId = reviewTemplate.id

  const handleSubmit = () => {
    if (!validate()) {
      return
    }

    updateReviewTemplate(reviewTemplateId, changedFields, resetChangedFields)
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <TemplateWarnings reviewTemplate={reviewTemplate} />
      </div>

      {!isCommonTemplate && (
        <div className="flex items-center justify-between mb-3">
          <TogglePublishReviewTemplate
            reviewTemplateId={reviewTemplateId}
            value={!!formData?.published}
            disabled={isCommonTemplate}
          />

          <ArchiveTemplate
            reviewTemplateId={reviewTemplateId}
            disabled={isCommonTemplate}
          />
        </div>
      )}

      <div className="flex gap-3">
        <div className="flex gap-2 w-full flex-col sm:flex-row sm:items-end">
          <ReviewTemplateGeneralForm
            formData={formData}
            onChange={onChange}
            validationErrors={validationErrors}
            disabled={isCommonTemplate}
          />

          <Button
            onClick={handleSubmit}
            loading={updateReviewTemplateLoading}
            disabled={!hasUnsavedData || isCommonTemplate}
          >
            {t('content:save')}
          </Button>
        </div>
      </div>

      <QuestionsOverview reviewTemplate={reviewTemplate} />
    </div>
  )
}

ManageReviewTemplate.propTypes = {
  reviewTemplate: object
}

ManageReviewTemplate.defaultProps = {}

export default ManageReviewTemplate
