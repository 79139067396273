import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_QUESTION_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const EDIT_REVIEW_QUESTION_ALTERNATIVE_GQL = gql`
  mutation UpdateReviewQuestionAlternative($questionId: ID!, $reviewQuestionAnswerId: ID!, $data: ReviewQuestionAnswerInput!) {
    updateReviewQuestionAlternative(questionId: $questionId, reviewQuestionAnswerId: $reviewQuestionAnswerId, data: $data) {
      ${REVIEW_TEMPLATE_QUESTION_GQL}
    }
  }
`

export default function useEditReviewQuestionAlternative() {
  const { showErrorNotification } = useOverlayNotification()

  const [editReviewQuestionAlternativeMutation, { loading }] = useMutation(
    EDIT_REVIEW_QUESTION_ALTERNATIVE_GQL
  )

  const editReviewQuestionAlternative = useCallback(
    (questionId, reviewQuestionAnswerId, texts, onCompleted) => {
      return editReviewQuestionAlternativeMutation({
        variables: {
          questionId,
          reviewQuestionAnswerId,
          data: {
            texts
          }
        },
        onCompleted: ({ editReviewQuestionAlternative }) => {
          onCompleted?.(editReviewQuestionAlternative)
        },
        onError: showErrorNotification
      })
    },
    [editReviewQuestionAlternativeMutation, showErrorNotification]
  )

  return {
    editReviewQuestionAlternative,
    editReviewQuestionAlternativeLoading: loading
  }
}
