import { useState, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import {
  DELETE_BUYING_INTENT_CATEGORY,
  UPDATE_BUYING_INTENT_DATA
} from '../src/buying-intents/gql/system'
import { useUserContext } from '../../../contexts'
import { isAdmin } from '../../../utils'
import { useManageSystemContext } from '../context'

// TODO: Check if this should be globaly added somewhere
// KE: Should be fetch from BE
const buyingIntentSiteDataDefault = {
  view: 100,
  compare: 100,
  alternative: 100,
  outbound: 100
}

const buyingIntentSiteDataCategoryDefault = {
  view: 0,
  guideResult: 0,
  guideStart: 0
}

const useBuyingIntent = () => {
  const { user } = useUserContext()
  const isUserAdmin = isAdmin(user?.role)

  const {
    systemSiteData,
    selectedCategories,
    biConfigFormState: formState,
    biConfigCategoryState: categoryState,
    biConfigChangedSiteIds: changedSiteIds,
    setBiConfigFormState: setFormState,
    setBiConfigCategoryState: setCategoryState,
    setBiConfigChangedSiteIds: setChangedSiteIds,
    biInitialFormStateRef,
    biInitialCategoryStateRef,
    system
  } = useManageSystemContext()

  const [shouldRefresh, setShouldRefresh] = useState(true)
  const isBuyerIntentActive =
    system?.vendor?.accessRights?.includes('BUYER_INTENT') ||
    system?.vendor?.accessRights?.includes('BUYER_SESSION_INTENT')
  const [appDeleteBuyingIntentSiteDataCategory] = useMutation(
    DELETE_BUYING_INTENT_CATEGORY
  )
  const [updateBuyingIntentData] = useMutation(UPDATE_BUYING_INTENT_DATA)

  useEffect(() => {
    const initialState = {}

    if (!isUserAdmin) {
      return
    }

    systemSiteData.forEach((site) => {
      const buyingIntent =
        site?.buyingIntentSiteData && site?.buyingIntentSiteData?.length > 0
          ? site?.buyingIntentSiteData[0]
          : null

      initialState[site.id] = {
        view: buyingIntent
          ? buyingIntent?.view === 100
          : buyingIntentSiteDataDefault.view === 100,
        compare: buyingIntent
          ? buyingIntent?.compare === 100
          : buyingIntentSiteDataDefault.compare === 100,
        alternative: buyingIntent
          ? buyingIntent?.alternative === 100
          : buyingIntentSiteDataDefault.alternative === 100,
        outbound: buyingIntent
          ? buyingIntent?.outbound === 100
          : buyingIntentSiteDataDefault.outbound === 100
      }
    })
    setFormState(initialState)
    biInitialFormStateRef.current = initialState

    const initialCategoryState = {}
    systemSiteData.forEach((site) => {
      initialCategoryState[site.id] = selectedCategories.reduce((acc, category) => {
        const categoryData = !site.buyingIntentSiteData?.length
          ? null
          : site.buyingIntentSiteData[0]?.buyingIntentSiteDataCategories?.find(
              (c) => c.category === category
            )
        acc[category] = {
          view: categoryData
            ? categoryData.view === 100
            : buyingIntentSiteDataCategoryDefault.view === 100,
          guideResult: categoryData
            ? categoryData.guideResult === 100
            : buyingIntentSiteDataCategoryDefault.guideResult === 100,
          guideStart: categoryData
            ? categoryData.guideStart === 100
            : buyingIntentSiteDataCategoryDefault.guideStart === 100
        }
        return acc
      }, {})
    })
    setCategoryState(initialCategoryState)
    biInitialCategoryStateRef.current = initialCategoryState
  }, [
    selectedCategories,
    shouldRefresh,
    systemSiteData,
    setFormState,
    biInitialFormStateRef,
    setCategoryState,
    biInitialCategoryStateRef
  ])

  /**
   * This will handle checkbox changes
   * @param siteId
   * @param field
   */
  const handleCheckboxChange = (siteId, field) => {
    const newValue = !formState[siteId][field]
    setFormState((prevState) => ({
      ...prevState,
      [siteId]: {
        ...prevState[siteId],
        [field]: newValue
      }
    }))

    if (biInitialFormStateRef.current[siteId][field] !== newValue) {
      setChangedSiteIds((prev) => new Set(prev).add(siteId))
    } else {
      const siteFields = formState[siteId]
      const initialFields = biInitialFormStateRef.current[siteId]
      const otherFieldsChanged = Object.keys(siteFields).some(
        (key) => siteFields[key] !== initialFields[key]
      )
      if (!otherFieldsChanged) {
        setChangedSiteIds((prev) => {
          const updated = new Set(prev)
          updated.delete(siteId)
          return updated
        })
      }
    }
  }

  /**
   * This will handle states of categories changes
   * @param siteId
   * @param category
   * @param field
   */
  const handleCategoryChange = (siteId, category, field) => {
    const newValue = !categoryState[siteId][category][field]
    setCategoryState((prevState) => ({
      ...prevState,
      [siteId]: {
        ...prevState[siteId],
        [category]: {
          ...prevState[siteId][category],
          [field]: newValue
        }
      }
    }))

    if (biInitialCategoryStateRef.current[siteId][category][field] !== newValue) {
      setChangedSiteIds((prev) => new Set(prev).add(siteId))
    } else {
      const categoryFields = categoryState[siteId][category]
      const initialFields = biInitialCategoryStateRef.current[siteId][category]
      const otherFieldsChanged = Object.keys(categoryFields).some(
        (key) => categoryFields[key] !== initialFields[key]
      )
      if (!otherFieldsChanged) {
        setChangedSiteIds((prev) => {
          const updated = new Set(prev)
          updated.delete(siteId)
          return updated
        })
      }
    }
  }

  /**
   * This is main update submit which will collect all data that are changed or
   * so to say where change happened and adjust it for query and update
   * @returns {Promise<void>}
   */
  const collectUpdateData = (changedSiteIds, formState, categoryState) => {
    const siteDataInputs = []
    const categoryInputs = []

    // Prepare siteDataInputs
    changedSiteIds.forEach((siteId) => {
      const fields = formState[siteId]
      const initialFields = biInitialFormStateRef.current[siteId]
      let siteDataChanged = false

      for (let field in fields) {
        if (fields[field] !== initialFields[field]) {
          siteDataChanged = true
          break
        }
      }

      if (siteDataChanged) {
        siteDataInputs.push({
          systemSiteDataId: siteId,
          view: fields.view,
          compare: fields.compare,
          alternative: fields.alternative,
          outbound: fields.outbound
        })
      }
    })

    // Prepare categoryInputs
    changedSiteIds.forEach((siteId) => {
      const categories = categoryState[siteId]
      const initialCategories = biInitialCategoryStateRef.current[siteId]
      const changedCategories = []

      for (let category in categories) {
        const fields = categories[category]
        const initialFields = initialCategories[category]
        let categoryChanged = false

        for (let field in fields) {
          if (fields[field] !== initialFields[field]) {
            categoryChanged = true
            break
          }
        }

        if (categoryChanged) {
          changedCategories.push({
            category,
            view: fields.view,
            guideResult: fields.guideResult,
            guideStart: fields.guideStart
          })
        }
      }

      if (changedCategories.length > 0) {
        categoryInputs.push({
          systemSiteDataId: siteId,
          categories: changedCategories
        })

        if (!siteDataInputs.some((input) => input.systemSiteDataId === siteId)) {
          const fields = formState[siteId]
          siteDataInputs.push({
            systemSiteDataId: siteId,
            view: fields.view,
            compare: fields.compare,
            alternative: fields.alternative,
            outbound: fields.outbound
          })
        }
      }
    })
    return {
      siteDataInputs,
      categoryInputs
    }
  }

  /**
   * This will handle use default valuse for buying intent system site itself
   * @param siteId
   */
  const handleUseDefaultBuyingIntent = (siteId) => {
    updateBuyingIntentData({
      variables: {
        siteDataInputs: [
          {
            systemSiteDataId: siteId,
            view: true,
            compare: true,
            alternative: true,
            outbound: true
          }
        ]
      }
    })
      .then(() => {
        setFormState((prevState) => ({
          ...prevState,
          [siteId]: {
            view: true,
            compare: true,
            alternative: true,
            outbound: true
          }
        }))
        biInitialFormStateRef.current[siteId] = {
          view: true,
          compare: true,
          alternative: true,
          outbound: true
        }

        // Remove siteId from changedSiteIds
        setChangedSiteIds((prev) => {
          const updated = new Set(prev)
          updated.delete(siteId)
          return updated
        })
        setShouldRefresh(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * This will handle use default values for categories
   * @param categoryId
   * @param siteId
   * @param category
   */
  const handleUseDefaultCategory = (categoryId, siteId, category) => {
    if (categoryId) {
      appDeleteBuyingIntentSiteDataCategory({
        variables: {
          buyingIntentSiteDataCategoryId: categoryId
        },
        onError: (error) => {
          console.error(error)
        }
      })
        .then(() => {
          resetCategoryState(siteId, category)
          setShouldRefresh(true)
        })
        .catch((err) => {
          // reset category state if error happens
          setShouldRefresh(true)
          resetCategoryState(siteId, category)
        })
    } else {
      setShouldRefresh(true)
      resetCategoryState(siteId, category)
    }
  }

  const resetCategoryState = (siteId, category) => {
    setCategoryState((prevState) => {
      const newState = { ...prevState }
      if (newState[siteId]) {
        const siteCategories = { ...newState[siteId] }
        siteCategories[category] = {
          view: false,
          guideResult: false,
          guideStart: false
        }
        newState[siteId] = siteCategories
      }
      return newState
    })

    if (biInitialCategoryStateRef.current[siteId]) {
      const siteCategories = { ...biInitialCategoryStateRef.current[siteId] }
      siteCategories[category] = {
        view: false,
        guideResult: false,
        guideStart: false
      }
      biInitialCategoryStateRef.current[siteId] = siteCategories
    }

    setChangedSiteIds((prev) => {
      const updated = new Set(prev)
      const categoriesChanged = Object.values(categoryState[siteId] || {}).some(
        (values) => values.view || values.guideResult || values.guideStart
      )
      if (!categoriesChanged) {
        updated.delete(siteId)
      }
      return updated
    })
  }

  return {
    formState,
    categoryState,
    handleCheckboxChange,
    handleCategoryChange,
    collectUpdateData,
    handleUseDefaultBuyingIntent,
    handleUseDefaultCategory,
    selectedCategories,
    systemSiteData,
    isBuyerIntentActive
  }
}

export default useBuyingIntent
