import React, { useState, useMemo } from 'react'
import { object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import EditCreateCommentDialog from './dialogs/edit-create-comment.dialog'
import DeleteReviewCommentDialog from './dialogs/delete-review-comment.dialog'

import Flag from '../../shared/flag'
import Button from '../../shared/button'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'

const Comments = ({ review }) => {
  const { t } = useTranslation()
  const [localeToModify, setLocaleToModify] = useState(null)
  const [localeToDelete, setLocaleToDelete] = useState(null)

  const reviewLocales = useMemo(() => {
    return [...new Set([review.originalLocale, ...review.availableLocales])]
  }, [review.originalLocale, review.availableLocales])

  return (
    <div>
      <h3 className="text-md font-medium text-gray-500 mb-2">
        {t('reviews.comments.ourComments')}
      </h3>

      <ul role="list" className="divide-y divide-gray-200 last:border-b">
        {reviewLocales.map((reviewLocale) => {
          const commentText = review.commentText[reviewLocale]
          const commentAuthor = review.commentAuthor[reviewLocale]
          const hasComment = !!commentText && !!commentAuthor

          return (
            <li className="py-2 flex items-center" key={reviewLocale}>
              <div className="flex flex-1 items-center">
                <Flag locale={reviewLocale} height="20px" />

                <div className="px-2 text-sm text-gray-500">
                  {hasComment ? (
                    <>
                      <span>{commentText}</span>
                      <span className="italic"> - {commentAuthor}</span>
                    </>
                  ) : (
                    <span className="italic">{t('reviews.comments.noComment')}</span>
                  )}
                </div>
              </div>

              <div className="flex gap-2">
                <Button
                  Icon={PencilAltIcon}
                  mode="outlined"
                  onClick={() => setLocaleToModify(reviewLocale)}
                />

                {hasComment && (
                  <Button
                    Icon={TrashIcon}
                    mode="error"
                    onClick={() => setLocaleToDelete(reviewLocale)}
                  />
                )}
              </div>
            </li>
          )
        })}
      </ul>

      {!!localeToModify && (
        <EditCreateCommentDialog
          reviewId={review.id}
          onClose={setLocaleToModify}
          commentLocale={localeToModify}
          commentText={review.commentText}
          commentAuthor={review.commentAuthor}
        />
      )}

      <DeleteReviewCommentDialog
        reviewId={review.id}
        localeToDelete={localeToDelete}
        onClose={setLocaleToDelete}
      />
    </div>
  )
}

Comments.propTypes = {
  review: object
}

Comments.defaultProps = {}

export default Comments
