import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { TRANSFORMED_REVIEW_FRAGMENT } from '../../../../constants/gql/reviews'

const CREATE_REVIEW_TRANSLATION_GQL = gql`
  mutation AdminCreateReviewTranslation(
    $reviewId: ID!
    $locale: String!
    $data: Json!
  ) {
    appAdminCreateReviewTranslation(reviewId: $reviewId, locale: $locale, data: $data) {
      ${TRANSFORMED_REVIEW_FRAGMENT}
    }
  }
`

export default function useCreateReviewTranslation() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appAdminCreateReviewTranslation, { loading }] = useMutation(
    CREATE_REVIEW_TRANSLATION_GQL
  )

  const createReviewTranslation = useCallback(
    (reviewId, locale, formData, onCompleted) => {
      return appAdminCreateReviewTranslation({
        variables: {
          reviewId,
          locale,
          data: formData
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appAdminCreateReviewTranslation, showSuccessNotification, showErrorNotification]
  )

  return {
    createReviewTranslation,
    createReviewTranslationLoading: loading
  }
}
