import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const PUBLISH_REVIEW_TEMPLATE_GQL = gql`
  mutation PublishReviewTemplate($reviewTemplateId: ID!) {
    publishReviewTemplate(reviewTemplateId: $reviewTemplateId) {
      id
      published
    }
  }
`

export default function usePublishReviewTemplate() {
  const { showErrorNotification } = useOverlayNotification()

  const [publishReviewTemplateMutation, { loading }] = useMutation(
    PUBLISH_REVIEW_TEMPLATE_GQL
  )

  const publishReviewTemplate = useCallback(
    (reviewTemplateId, onCompleted) => {
      return publishReviewTemplateMutation({
        variables: {
          reviewTemplateId
        },
        onCompleted: ({ publishReviewTemplate }) => {
          onCompleted?.(publishReviewTemplate)
        },
        onError: showErrorNotification
      })
    },
    [publishReviewTemplateMutation, showErrorNotification]
  )

  return {
    publishReviewTemplate,
    publishReviewTemplateLoading: loading
  }
}
