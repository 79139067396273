import React, { useMemo } from 'react'
import { func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useFetchAvailableSites from '../../../../hooks/useFetchAvailableSites'

import Label from '../../../shared/label'
import { Select } from '../../../shared/select'

const LocaleSelector = ({ onChange, value }) => {
  const { t } = useTranslation()
  const { sitesOptions } = useFetchAvailableSites(false)

  const tweakedOptionsMemo = useMemo(() => {
    return [{ labelTid: 'content:showAll', value: 'ALL' }, ...sitesOptions]
  }, [sitesOptions])

  return (
    <Label title={t('reviewTemplates:template.overview.displayLanguage')}>
      <Select
        options={tweakedOptionsMemo}
        value={value}
        onChange={onChange}
        rawChangeEvent={false}
      />
    </Label>
  )
}

LocaleSelector.propTypes = {
  onChange: func,
  value: string
}

LocaleSelector.defaultProps = {}

export default LocaleSelector
