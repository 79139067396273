import React, { useMemo } from 'react'
import { func, object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useCategoriesOptions from '../../../hooks/useCategoriesOptions'

import Label from '../../shared/label'
import { MultiSelect } from '../../shared/select'
import Input from '../../shared/input'

import { hasItemsInArray } from '../../../validators/general'
import useFetchReviewTemplates from '../../../hooks/manage-review-templates/useFetchReviewTemplates'

const FIELD_CATEGORIES = 'categories'
const FIELD_NAME = 'name'
const FIELD_PUBLISHED = 'published'

export const REVIEW_TEMPLATE_GENERAL_FORM_INSTR = [
  { name: FIELD_PUBLISHED },
  { name: FIELD_NAME },
  {
    name: FIELD_CATEGORIES,
    validators: [
      { validator: hasItemsInArray, error: 'forms:validation.general.arrayRequired' }
    ]
  }
]

export const REVIEW_TEMPLATE_CREATE_FORM_INSTR = [...REVIEW_TEMPLATE_GENERAL_FORM_INSTR]

export const REVIEW_TEMPLATE_EDIT_FORM_INSTR = [
  ...REVIEW_TEMPLATE_GENERAL_FORM_INSTR,
  {
    name: FIELD_PUBLISHED
  }
]

const ReviewTemplateGeneralForm = ({
  formData,
  onChange,
  validationErrors,
  disabled
}) => {
  const { t } = useTranslation()
  const { categoriesOptions } = useCategoriesOptions()
  const { reviewTemplates, reviewTemplatesLoading } =
    useFetchReviewTemplates('cache-first')
  const currentGuideId = formData?.id

  const filteredCategoriesOptionsMemo = useMemo(() => {
    if (!reviewTemplates?.length && reviewTemplatesLoading) {
      return []
    }

    const usedCategories = []

    reviewTemplates.forEach((guide) => {
      if (currentGuideId === guide.id) {
        return
      }

      guide.categories.forEach((category) => {
        usedCategories.push(category)
      })
    })

    return categoriesOptions.filter((categoryOption) => {
      return !usedCategories.includes(categoryOption.value)
    })
  }, [reviewTemplates, reviewTemplatesLoading, categoriesOptions, currentGuideId])

  const getCommonProps = (fieldName) => {
    return {
      id: fieldName,
      value: formData[fieldName],
      errorText: t(validationErrors[fieldName]),
      onChange,
      disabled
    }
  }

  const handleChangeCategory = (fieldName, updatedCategory) => {
    onChange(fieldName, updatedCategory)
  }

  return (
    <>
      <Label title={t('content:name')} marginless>
        <Input {...getCommonProps(FIELD_NAME)} />
      </Label>

      <Label className="flex-1" title={t('content:category')} marginless>
        <MultiSelect
          {...getCommonProps(FIELD_CATEGORIES)}
          onChange={handleChangeCategory}
          value={formData[FIELD_CATEGORIES]}
          options={filteredCategoriesOptionsMemo}
          rawChangeEvent={false}
        />
      </Label>
    </>
  )
}

ReviewTemplateGeneralForm.propTypes = {
  formData: object.isRequired,
  onChange: func.isRequired,
  validationErrors: object.isRequired
}

ReviewTemplateGeneralForm.defaultProps = {}

export default ReviewTemplateGeneralForm
