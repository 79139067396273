import React, { useState } from 'react'
import AdminField from '../../../shared/admin-field'
import BuyingIntentForm from './partials/BuyingIntentForm'
import CategoriesDialog from './partials/CategoriesDialog'
import { useTranslation } from 'react-i18next'
import useBuyingIntent from '../../hooks/useBuyingIntent'

const BuyingIntentConfig = ({ forLocale }) => {
  const { t } = useTranslation('forms')
  const { systemSiteData, isBuyerIntentActive } = useBuyingIntent()
  const [categoriesOpen, setCategoriesOpen] = useState(false)
  const [selectedSystemSiteId, setSelectedSystemSiteId] = useState('')

  const siteConfig = systemSiteData?.filter((site) => site.locale === forLocale)

  if (!isBuyerIntentActive) {
    return
  }

  return (
    <div>
      <AdminField className="text-xl font-semibold mb-4">
        <BuyingIntentForm
          systemSiteData={siteConfig}
          setCategoriesOpen={setCategoriesOpen}
          setSelectedSystemSiteId={setSelectedSystemSiteId}
          t={t}
        />
        <CategoriesDialog
          systemSiteData={siteConfig}
          isOpen={categoriesOpen}
          onClose={() => setCategoriesOpen(false)}
          selectedSystemSiteId={selectedSystemSiteId}
          t={t}
        />
      </AdminField>
    </div>
  )
}

export default BuyingIntentConfig
