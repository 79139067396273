import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { TRANSFORMED_REVIEW_FRAGMENT } from '../../../../constants/gql/reviews'

const EDIT_REVIEW_COMMENT_GQL = gql`
  mutation CreateEditReviewComment($reviewId: ID!, $locale: String!, $data: ReviewCommentInput!) {
    appCreateEditReviewComment(reviewId: $reviewId, locale: $locale, data: $data) {
      ${TRANSFORMED_REVIEW_FRAGMENT}
    }
  }
`

export default function useCreateEditReviewComment() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appCreateEditReviewComment, { loading }] = useMutation(EDIT_REVIEW_COMMENT_GQL)

  const createEditReviewComment = useCallback(
    (reviewId, locale, formData, onCompleted) => {
      return appCreateEditReviewComment({
        variables: {
          reviewId,
          locale,
          data: formData
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appCreateEditReviewComment, showSuccessNotification, showErrorNotification]
  )

  return {
    createEditReviewComment,
    createEditReviewCommentLoading: loading
  }
}
