import React from 'react'
import Filters from '../shared/filters'
import PropTypes from 'prop-types'
import { useBuyingIntentFilters } from './hooks/useBuyingIntentFilters'
import {
  filterByActivity,
  filterByBranch,
  filterByCompanySize,
  filterByCustomerProfileMatch,
  filterByRevenueMax,
  filterByRevenueMin,
  filterByBuyersJourneyStage
} from './utils/buying-intent-filter.utils'

const BuyingIntentFilters = ({ buyingIntents, updateBuyingIntents }) => {
  const { filters, originalBuyingIntentsRef } = useBuyingIntentFilters(buyingIntents)

  const handleFiltersChanged = (newActiveFilters) => {
    let filteredBuyingIntents = originalBuyingIntentsRef.current

    if (newActiveFilters.length === 0) {
      updateBuyingIntents(filteredBuyingIntents)
      return
    }

    const filtersById = newActiveFilters.reduce((acc, { filter: filterId, value }) => {
      acc[filterId] = acc[filterId] ? [...acc[filterId], value] : [value]
      return acc
    }, {})

    Object.entries(filtersById).forEach(([filterId, values]) => {
      switch (filterId) {
        case 'companySize':
          filteredBuyingIntents = filterByCompanySize(filteredBuyingIntents, values)
          break

        case 'branch':
          filteredBuyingIntents = filterByBranch(filteredBuyingIntents, values)
          break

        case 'revenueMin':
          filteredBuyingIntents = filterByRevenueMin(filteredBuyingIntents, values)
          break

        case 'revenueMax':
          filteredBuyingIntents = filterByRevenueMax(filteredBuyingIntents, values)
          break

        case 'activity':
          filteredBuyingIntents = filterByActivity(filteredBuyingIntents, values)
          break

        case 'buyersJourneyStage':
          filteredBuyingIntents = filterByBuyersJourneyStage(
            filteredBuyingIntents,
            values
          )
          break

        case 'customerProfileMatch':
          filteredBuyingIntents = filterByCustomerProfileMatch(
            filteredBuyingIntents,
            values
          )
          break

        default:
          break
      }
    })

    updateBuyingIntents(filteredBuyingIntents)
  }

  return (
    <Filters filters={filters} onChangeFilters={handleFiltersChanged} isFlex={false} />
  )
}

BuyingIntentFilters.propTypes = {
  buyingIntents: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string,
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      systemId: PropTypes.string,
      activity: PropTypes.number,
      bwBranch: PropTypes.string,
      category: PropTypes.string,
      companySize: PropTypes.string,
      locale: PropTypes.string,
      site: PropTypes.string,
      journey: PropTypes.shape({
        __typename: PropTypes.string,
        stage: PropTypes.string,
        stageCompletion: PropTypes.shape({
          Awareness: PropTypes.string,
          Consideration: PropTypes.string,
          Decision: PropTypes.string
        }),
        percentage: PropTypes.string
      }),
      revenue: PropTypes.object,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string
    })
  ).isRequired,
  updateBuyingIntents: PropTypes.func.isRequired
}

export default BuyingIntentFilters
