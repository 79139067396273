import React, { useState, memo } from 'react'
import cx from 'classnames'
import {
  ChevronRightIcon,
  ChevronDownIcon,
  CalendarIcon,
  LinkIcon
} from '@heroicons/react/solid'
import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'

import Flag from '../../shared/flag'
import ReviewItem from './item'
import ReviewTranslationControls from '../../shared/reviews/translation-controls'

const ReviewListItem = ({
  review,
  shownLocale,
  isSubItem,
  getSystemNameFn,
  getCategoryNameFn,
  getCategoriesStringFn,
  onDelete
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen((prev) => !prev)

  return (
    <li>
      <div
        className={cx('block cursor-pointer hover:bg-gray-50 border-l-8', {
          'border-transparent': isSubItem,
          'border-green-600': review.approved === true && !isSubItem,
          'border-red-600': review.approved === false && !isSubItem
        })}
        type="button"
        onClick={toggleOpen}
      >
        <div
          className={cx('flex items-center', {
            'p-4 sm:px-6': !isSubItem,
            'py-3 px-6': isSubItem
          })}
        >
          <div className="min-w-0 flex-1 flex items-center">
            {isSubItem && (
              <LinkIcon
                className={cx('w-5 h-5 mr-3', {
                  'text-red-600': !review.approved,
                  'text-green-600': review.approved
                })}
              />
            )}

            <div className="flex-shrink-0">
              <div className="flex h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={shownLocale} />
              </div>
            </div>

            {isSubItem && (
              <div className="px-3 flex items-center text-sm text-gray-500">
                <p>Översättning för {t(`shared:localeToCountry.${shownLocale}`)}</p>
              </div>
            )}

            {!isSubItem && (
              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p
                    className={cx('text-sm font-medium text-blue-600 truncate', {
                      'text-red-600': !review.emailApproved
                    })}
                  >
                    {review.email}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />

                    {DateTime.fromISO(review.createdAt).toFormat('yyyy-MM-dd HH:mm:ss')}
                  </p>
                </div>
                <div className="hidden md:block">
                  <div>
                    <div className="flex text-sm">
                      <p className="font-medium truncate">
                        {getSystemNameFn(review.systemV2)}
                      </p>
                    </div>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      {getCategoryNameFn(review.category)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="mx-5 my-5 sm:mx-10">
          <ReviewItem
            review={review}
            getCategoriesStringFn={getCategoriesStringFn}
            shownLocale={shownLocale}
            isSubItem={isSubItem}
          />

          <ReviewTranslationControls
            review={review}
            onDelete={onDelete}
            locale={shownLocale}
            isSubItem={isSubItem}
            alwaysVisible
          />
        </div>
      )}
    </li>
  )
}

export default memo(ReviewListItem)
