import React, { useState, useEffect, useRef } from 'react'
import { bool, func, object, oneOfType, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useFetchAvailableSites from '../../../../hooks/useFetchAvailableSites'
import useCreateReviewQuestionAlternative from '../../../../hooks/manage-review-templates/useCreateReviewQuestionAlternative'
import useEditReviewQuestionAlternative from '../../../../hooks/manage-review-templates/useEditReviewQuestionAlternative'

import Dialog from '../../../shared/dialog'
import MultipleTextareaI18nInputs from '../../../shared/multiple-textarea-i18n-inputs'

const ManageQuestionAnswerDialog = ({ question, answerId, onClose }) => {
  const { t } = useTranslation()
  const { createReviewQuestionAlternative, createReviewQuestionAlternativeLoading } =
    useCreateReviewQuestionAlternative()
  const { editReviewQuestionAlternative, editReviewQuestionAlternativeLoading } =
    useEditReviewQuestionAlternative()

  const formLoading =
    createReviewQuestionAlternativeLoading || editReviewQuestionAlternativeLoading

  const { locales } = useFetchAvailableSites(false, true)
  const textAreasI18nRef = useRef(null)
  const [initTexts, setInitTexts] = useState({})
  const [updatedTexts, setUpdatedTexts] = useState({})

  const open = !!answerId
  const creatorMode = typeof answerId === 'boolean'

  useEffect(() => {
    if (!question?.reviewAnswers || !answerId) {
      return
    }

    const foundAnswer = question.reviewAnswers.find((answer) => answer.id === answerId)

    if (foundAnswer) {
      setInitTexts(foundAnswer.texts || {})
    }
  }, [question?.reviewAnswers, answerId])

  const handleSubmit = () => {
    if (!textAreasI18nRef?.current?.validate()) {
      return
    }

    if (creatorMode) {
      createReviewQuestionAlternative(question.id, updatedTexts, onClose)
    } else {
      editReviewQuestionAlternative(question.id, answerId, updatedTexts, onClose)
    }
  }

  const handleClose = () => {
    onClose(false)

    setTimeout(() => {
      setInitTexts({})
    }, 300)
  }

  return (
    <Dialog
      className="!max-w-4xl w-fit"
      isOpen={open}
      title={creatorMode ? t('content:create') : t('content:edit')}
      confirmText={t('content:save')}
      onClose={handleClose}
      onConfirm={handleSubmit}
      loading={formLoading}
    >
      <MultipleTextareaI18nInputs
        locales={locales}
        texts={initTexts}
        onChange={setUpdatedTexts}
        ref={textAreasI18nRef}
      />
    </Dialog>
  )
}

ManageQuestionAnswerDialog.propTypes = {
  question: object,
  answerId: oneOfType([string, bool]),
  onClose: func
}

ManageQuestionAnswerDialog.defaultProps = {}

export default ManageQuestionAnswerDialog
