import React from 'react'
import { bool, func } from 'prop-types'

import { useTranslation } from 'react-i18next'
import usePublishReviewTemplate from '../../../../hooks/manage-review-templates/usePublishReviewTemplate'
import useUnpublishReviewTemplate from '../../../../hooks/manage-review-templates/useUnpublishReviewTemplate'

import Label from '../../../shared/label'
import ToggleSwitch from '../../../shared/toggle-switch'

const TogglePublishReviewTemplate = ({ reviewTemplateId, value, disabled }) => {
  const { t } = useTranslation()

  const { publishReviewTemplate, publishReviewTemplateLoading } =
    usePublishReviewTemplate()
  const { unpublishReviewTemplate, unpublishReviewTemplateLoading } =
    useUnpublishReviewTemplate()

  const toggleDisabled =
    disabled || publishReviewTemplateLoading || unpublishReviewTemplateLoading

  const handleChange = (value) => {
    if (value) {
      publishReviewTemplate(reviewTemplateId)
    } else {
      unpublishReviewTemplate(reviewTemplateId)
    }
  }

  return (
    <Label title={t('content:publish')} marginless>
      <ToggleSwitch onChange={handleChange} checked={value} disabled={toggleDisabled} />
    </Label>
  )
}

TogglePublishReviewTemplate.propTypes = {
  onChange: func,
  value: bool
}

TogglePublishReviewTemplate.defaultProps = {}

export default TogglePublishReviewTemplate
