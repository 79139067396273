import React, { useMemo } from 'react'
import { bool } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useGetCategoryName from '../../../hooks/useGetCategoryName'
import useFetchReviewTemplate from '../../../hooks/manage-review-templates/useFetchReviewTemplate'

import Loader from '../../loader'
import Breadcrumbs from '../../shared/breadcrumbs'

const BASEPATH = '/admin-manage-review-templates'

const Header = ({ loading }) => {
  const { t } = useTranslation('adminPages')
  const { reviewTemplateId } = useParams()

  const { reviewTemplate } = useFetchReviewTemplate(false, 'cache-only')
  const { getCategoriesString } = useGetCategoryName()

  const breadcrumbsMemo = useMemo(() => {
    const items = [{ text: t('reviewTemplates:breadcrumbs.title'), href: BASEPATH }]

    if (!reviewTemplate || !reviewTemplateId) {
      return items
    }

    const { categories } = reviewTemplate
    let { name } = reviewTemplate

    if (categories && categories.includes('ALL')) {
      name = t('reviewTemplates:templates.commonTemplate')
    }

    items.push({
      text: name || getCategoriesString(categories),
      href: `${BASEPATH}/${reviewTemplateId}`
    })

    return items
  }, [t, reviewTemplate, getCategoriesString, reviewTemplateId])

  return (
    <div className="flex flex-col max-w-7xl mx-auto px-4 mb-10 sm:px-6 md:px-8 ">
      <Breadcrumbs breadcrumbs={breadcrumbsMemo} />

      {loading && (
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  loading: bool
}

Header.defaultProps = {}

export default Header
