import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import useGetSystemName from '../../../hooks/useGetSystemName'
import useGetCategoryName from '../../../hooks/useGetCategoryName'

import ReviewListItem from './list-item'
import DeleteReviewTranslationDialog from '../../reviews/your-reviews/dialogs/delete-review-translation.dialog'

export default function ReviewsList({ reviews }) {
  const { t } = useTranslation()
  const [deleteReviewData, setDeleteReviewData] = useState(false)
  const { getSystemName } = useGetSystemName()
  const { getCategoryName, getCategoriesString } = useGetCategoryName()

  if (!reviews?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {reviews.map((review) => {
          return (
            <React.Fragment key={review.id}>
              <ReviewListItem
                review={review}
                shownLocale={review.originalLocale}
                getSystemNameFn={getSystemName}
                getCategoryNameFn={getCategoryName}
                getCategoriesStringFn={getCategoriesString}
                onDelete={setDeleteReviewData}
              />

              {review.availableLocales.map((locale) => {
                return (
                  <ReviewListItem
                    key={`${review.id}-${locale}`}
                    review={review}
                    shownLocale={locale}
                    getSystemNameFn={getSystemName}
                    getCategoryNameFn={getCategoryName}
                    getCategoriesStringFn={getCategoriesString}
                    isSubItem={true}
                    onDelete={setDeleteReviewData}
                  />
                )
              })}
            </React.Fragment>
          )
        })}
      </ul>

      <DeleteReviewTranslationDialog
        reviewId={deleteReviewData?.reviewId}
        locale={deleteReviewData?.locale}
        onClose={setDeleteReviewData}
      />
    </div>
  )
}
