import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const CREATE_REVIEW_TEMPLATE_GQL = gql`
  mutation CreateReviewTemplate($data: ReviewTemplateInput!) {
    createReviewTemplate(data: $data) {
      id
    }
  }
`
export default function useCreateReviewTemplate() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [createReviewTemplateMutation, { loading }] = useMutation(
    CREATE_REVIEW_TEMPLATE_GQL
  )

  const createReviewTemplate = useCallback(
    (data, onCompleted) => {
      return createReviewTemplateMutation({
        variables: {
          data
        },
        onCompleted: ({ createReviewTemplate }) => {
          onCompleted && onCompleted(createReviewTemplate)
          showSuccessNotification()
        },
        onError: showErrorNotification
      })
    },
    [createReviewTemplateMutation, showSuccessNotification, showErrorNotification]
  )

  return {
    createReviewTemplate,
    createReviewTemplateLoading: loading
  }
}
