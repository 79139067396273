import React from 'react'
import { PageHeader } from '../components/page-header'
import { useTranslation } from 'react-i18next'
import { PageBody } from '../components/page-body'
import BuyingIntentActivities from '../components/buying-intents/BuyingIntentActivities'
import { useParams } from 'react-router-dom'

const BuyingIntentV2Page = () => {
  const { t } = useTranslation('buyingIntent')

  const { systemId } = useParams()

  return (
    <>
      <PageHeader pageName={'Buying Intents 2.0'} headerClassName="flex items-end gap-1">
        <p className={'my-2'}>{t('buyingIntent:header.subtitle')}</p>
      </PageHeader>
      <PageBody>
        <BuyingIntentActivities systemId={systemId} />
      </PageBody>
    </>
  )
}

export default BuyingIntentV2Page
