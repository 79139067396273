import React, { useState } from 'react'
import { bool, func, oneOfType, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useArchiveReviewQuestion from '../../../../hooks/manage-review-templates/useArchiveReviewQuestion'

import Button from '../../../shared/button'
import Dialog from '../../../shared/dialog'

const ArchiveQuestion = ({ reviewTemplateId, reviewQuestionId, onArchive }) => {
  const { t } = useTranslation()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const { archiveReviewQuestion, archiveReviewQuestionLoading } =
    useArchiveReviewQuestion()

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }

  const handleCancel = () => {
    setConfirmOpen(false)
  }

  const handleArchive = () => {
    archiveReviewQuestion(reviewTemplateId, reviewQuestionId, onArchive)
  }

  return (
    <>
      <Button
        onClick={handleOpenConfirm}
        loading={archiveReviewQuestionLoading}
        mode="error"
      >
        {t('content:archive')}
      </Button>

      <Dialog
        title={t('content:confirmDelete')}
        isOpen={confirmOpen}
        onClose={handleCancel}
        onConfirm={handleArchive}
        cancelText={t('content:cancel')}
        confirmText={t('content:archive')}
        loading={archiveReviewQuestionLoading}
      />
    </>
  )
}

ArchiveQuestion.propTypes = {
  reviewTemplateId: string.isRequired,
  reviewQuestionId: oneOfType([string, bool]),
  onArchive: func.isRequired
}

ArchiveQuestion.defaultProps = {}

export default ArchiveQuestion
