import React from 'react'
import Checkbox from '../../../../shared/checkbox'
import Flag from '../../../../shared/flag'
import Button from '../../../../shared/button'
import useBuyingIntent from '../../../hooks/useBuyingIntent'

const BuyingIntentForm = ({
  setCategoriesOpen,
  setSelectedSystemSiteId,
  systemSiteData,
  t
}) => {
  const { formState, handleCheckboxChange, handleUseDefaultBuyingIntent } =
    useBuyingIntent()
  return (
    <div className=" rounded-md">
      <p className="text-sm my-3 border-gray-300 pb-2">
        {t('system.buyingIntent.subTitle')}
      </p>
      <div className="grid grid-cols-1 gap-6">
        {systemSiteData.map((site) => {
          // Condition to show the "Use default" button
          const shouldShowUseDefaultButton = !(
            formState[site.id]?.view &&
            formState[site.id]?.compare &&
            formState[site.id]?.alternative &&
            formState[site.id]?.outbound
          )

          return (
            <div key={site.id} className="bg-white shadow-md rounded-md p-5 mb-6 w-full">
              <div className="flex justify-between">
                <h3 className="text-lg font-medium mb-2 flex gap-2">
                  {site.systemName} <Flag height="25px" locale={site.locale} />
                </h3>
                <div className="flex gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setCategoriesOpen(true)
                      setSelectedSystemSiteId(site.id)
                    }}
                    className="text-sm text-blue-600 border-none !bg-white underline"
                  >
                    {t('system.buyingIntent.categories.title')}
                  </button>
                </div>
              </div>
              <p className="text-sm text-gray-600 mb-4">{site?.site?.domain}</p>
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <Checkbox
                    label={t('system.buyingIntent.triggers.view')}
                    checked={formState[site.id]?.view}
                    onChange={() => handleCheckboxChange(site.id, 'view')}
                  />
                  <Checkbox
                    label={t('system.buyingIntent.triggers.compare')}
                    checked={formState[site.id]?.compare}
                    onChange={() => handleCheckboxChange(site.id, 'compare')}
                  />
                  <Checkbox
                    label={t('system.buyingIntent.triggers.alternative')}
                    checked={formState[site.id]?.alternative}
                    onChange={() => handleCheckboxChange(site.id, 'alternative')}
                  />
                  <Checkbox
                    label={t('system.buyingIntent.triggers.outbound')}
                    checked={formState[site.id]?.outbound}
                    onChange={() => handleCheckboxChange(site.id, 'outbound')}
                  />
                </div>
                <div>
                  {shouldShowUseDefaultButton && (
                    <Button
                      size="small"
                      mode="outlined"
                      type="button"
                      onClick={() => handleUseDefaultBuyingIntent(site.id)}
                    >
                      TODO: Use default
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BuyingIntentForm
