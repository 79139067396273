const REVIEW_TEMPLATE_COMMON = `
    id
    name
    categories
    published
`

export const REVIEW_TEMPLATE_QUESTION_GQL = `
    id
    type
    required
    texts
    isCommonQuestion
    specialName
    reviewAnswers {
        id
        texts
        order
    }
`

export const REVIEW_TEMPLATE_LIST_GQL = `
  ${REVIEW_TEMPLATE_COMMON}
`

export const REVIEW_TEMPLATE_GQL = `
    ${REVIEW_TEMPLATE_COMMON}
    reviewQuestions {
        ${REVIEW_TEMPLATE_QUESTION_GQL}
    }
`
