import React from 'react'

import { useManageSystemContext } from '../context'

import SystemFields from './system-form'
import MediaForm from './media-form'
import CountryForm from './country-form'
import CategoryForm from './category-form'
import BuyingIntentConfig from './buying-intents/BuyingIntentConfig'

const SystemTabsOutput = () => {
  const { activeTab } = useManageSystemContext()

  if (activeTab.value === 'SYSTEM') {
    return <SystemFields />
  }

  if (activeTab.value === 'MEDIA') {
    return <MediaForm />
  }

  if (activeTab.type === 'CATEGORY') {
    return <CategoryForm />
  }

  if (activeTab.type === 'COUNTRY') {
    return <CountryForm />
  }

  if (activeTab.value === 'BUYING_INTENT') {
    return <BuyingIntentConfig />
  }
}

SystemTabsOutput.propTypes = {}

SystemTabsOutput.defaultProps = {}

export default SystemTabsOutput
