import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const ARCHIVE_REVIEW_QUESTION_GQL = gql`
  mutation ArchiveReviewTemplateQuestion($reviewTemplateId: ID!, $reviewQuestionId: ID!) {
    archiveReviewTemplateQuestion(reviewTemplateId: $reviewTemplateId, reviewQuestionId: $reviewQuestionId) {
      ${REVIEW_TEMPLATE_GQL}
    }
  }
`

export default function useArchiveReviewQuestion() {
  const { showErrorNotification } = useOverlayNotification()

  const [archiveReviewQuestionMutation, { loading }] = useMutation(
    ARCHIVE_REVIEW_QUESTION_GQL
  )

  const archiveReviewQuestion = useCallback(
    (reviewTemplateId, reviewQuestionId, onCompleted) => {
      return archiveReviewQuestionMutation({
        variables: {
          reviewTemplateId,
          reviewQuestionId
        },
        onCompleted: ({ archiveReviewTemplateQuestion }) => {
          onCompleted?.(archiveReviewTemplateQuestion)
        },
        onError: showErrorNotification
      })
    },
    [archiveReviewQuestionMutation, showErrorNotification]
  )

  return {
    archiveReviewQuestion,
    archiveReviewQuestionLoading: loading
  }
}
