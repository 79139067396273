import React, { memo } from 'react'
import { object, func, string, bool, array } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useCreateWebhook from '../hooks/useCreateWebhook'
import useEditWebhook from '../hooks/useEditWebhook'
import useDeleteWebhook from '../hooks/useDeleteWebhook'
import useTestWebhook from '../hooks/useTestWebhook'

import AdminField from '../../shared/admin-field'

import Button from '../../shared/button'
import { Menu } from '@headlessui/react'

import {
  hasRequiredString,
  isValidUrl,
  hasItemsInArray,
  isValidJsonStringOrEmpty
} from '../validators/general'
import useGenericFormManager from '../../../hooks/useGenericFormManager'
import ListItem from './list-item'
import Label from '../../shared/label'
import Input from '../../shared/input'
import { MultiSelect } from '../../shared/select'
import MenuTransition from '../../shared/dropdown/menu-transition'
import MenuItem from '../../shared/dropdown/menu-item'
import TextArea from '../../shared/textarea'

// TODO: remove after buying intent v2 is released
import { useUserContext } from '../../../contexts'
import { isAdmin } from '../../../utils'
// TODO: remove after buying intent v2 is released

const FIELD_NAME = 'name'
const FIELD_URL = 'url'
const FIELD_EVENTS = 'events'
const FIELD_TRANSFORMER = 'transformer'

const VALIDATORS_CONFIG = [
  {
    name: FIELD_NAME,
    validators: [
      { validator: hasRequiredString, error: 'webhooks.formError.nameRequired' }
    ]
  },
  {
    name: FIELD_URL,
    validators: [
      {
        validator: isValidUrl,
        error: 'webhooks.formError.urlIncorrect'
      }
    ]
  },
  {
    name: FIELD_EVENTS,
    validators: [
      {
        validator: hasItemsInArray,
        error: 'webhooks.formError.triggerRequired'
      }
    ]
  },
  {
    name: FIELD_TRANSFORMER,
    validators: [
      {
        validator: isValidJsonStringOrEmpty,
        error: 'webhooks.formError.invalidTransformer'
      }
    ]
  }
]

const WebhookListItem = (props) => {
  const {
    webhook,
    vendorId,
    showFormForId,
    setShowFormForId,
    creatorMode,
    eventEnumsOptions
  } = props
  const id = creatorMode ? null : webhook.id
  const isEditing = creatorMode || id === showFormForId
  const {
    formData: webhookFormData,
    onChange,
    validate,
    validationErrors
  } = useGenericFormManager(VALIDATORS_CONFIG, isEditing ? webhook : null, {
    enableLiveValidation: true,
    liveValidationWaitForFormData: !creatorMode
  })
  const { t } = useTranslation(['vendorSettings'])
  const { createWebhook } = useCreateWebhook(vendorId)
  const { editWebhook } = useEditWebhook(vendorId)
  const { deleteWebhook } = useDeleteWebhook(vendorId)
  const { testWebhook } = useTestWebhook(vendorId)

  const formData = isEditing ? webhookFormData : webhook

  const stopEditing = () => {
    setShowFormForId(null)
  }

  // TODO: remove after buying intent v2 is released
  const { user } = useUserContext()

  const doValidateAndSave = () => {
    const isValid = validate()

    if (!isValid) {
      // TODO snackbar?
      return
    }

    if (creatorMode) {
      createWebhook(formData)
    } else {
      editWebhook(formData)
    }

    stopEditing()
  }

  const getCommonFormProps = (fieldName) => {
    return {
      id: fieldName,
      value: formData[fieldName],
      errorText: t(validationErrors[fieldName])
    }
  }

  const eventsTexts = formData[FIELD_EVENTS]?.map((eventEnum) => {
    return {
      eventsText: t(`webhooks.triggers.${eventEnum}`),
      key: eventEnum
    }
  })

  const labelClassname = 'text-gray-500'

  return (
    <div
      style={{
        gridTemplateColumns: isEditing || isEditing === undefined ? '1fr' : '1fr auto'
      }}
      className="gap-4 px-8 py-4 grid"
    >
      <ListItem>
        <div className="grid gap-4 md:grid-cols-2">
          <Label labelClassname={labelClassname} title={t('webhooks.label.name')}>
            {isEditing ? (
              <Input {...getCommonFormProps(FIELD_NAME)} onChange={onChange} />
            ) : (
              <p className=" text-gray-900 truncate">{formData[FIELD_NAME]}</p>
            )}
          </Label>

          <Label labelClassname={labelClassname} title={t('webhooks.label.url')}>
            {isEditing ? (
              <Input {...getCommonFormProps(FIELD_URL)} onChange={onChange} />
            ) : (
              <p className=" truncate">{formData[FIELD_URL]}</p>
            )}
          </Label>

          {isEditing && (
            <AdminField className="col-span-2">
              <Label
                labelClassname={labelClassname}
                title={t('webhooks.label.transformer')}
              >
                <TextArea
                  {...getCommonFormProps(FIELD_TRANSFORMER)}
                  onChange={onChange}
                  rows={10}
                />
              </Label>
            </AdminField>
          )}

          <Label
            labelClassname={labelClassname}
            className={'col-span-2'}
            title={t('webhooks.label.triggers')}
          >
            {isEditing ? (
              <MultiSelect
                {...getCommonFormProps(FIELD_EVENTS)}
                options={eventEnumsOptions
                  .map((event) => {
                    if (
                      // buying intents v2.0
                      !isAdmin(user?.role) &&
                      event.value === 'EVENT_NEW_BUYING_INTENT_ACTIVITY'
                    ) {
                      return []
                    }
                    return event
                  })
                  .flat()}
                onChange={onChange}
                placeholder="placeholders.select.select"
                rawChangeEvent={false}
              />
            ) : (
              <>
                <div className="flex items-center gap-3">
                  {eventsTexts.map(({ eventsText, key }, index) => {
                    return (
                      <div className="flex items-center gap" key={index}>
                        <p className=" truncate">{eventsText}</p>
                        <Menu as="div" className="relative self-center">
                          <div>
                            <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm border-none rounded-full">
                              <span className="sr-only">Open user menu</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                />
                              </svg>
                            </Menu.Button>
                          </div>
                          <MenuTransition>
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <MenuItem
                                key={`Menu.Item-Profile-test`}
                                onClick={() => {
                                  testWebhook(webhook.id, key)
                                }}
                              >
                                <a>{t('webhooks.button.test')}</a>
                              </MenuItem>
                            </Menu.Items>
                          </MenuTransition>
                        </Menu>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </Label>

          {isEditing && (
            <div className="grid items-end justify-end gap-4 grid-flow-col mt-8 md:col-span-2">
              <Button onClick={stopEditing}>{t('webhooks.button.cancel')}</Button>
              <Button color="primary" variant="contained" onClick={doValidateAndSave}>
                {creatorMode
                  ? t('webhooks.button.createPlain')
                  : t('webhooks.button.save')}
              </Button>
            </div>
          )}
        </div>
      </ListItem>

      {!isEditing && (
        <Menu as="div" className="relative self-center">
          <div>
            <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <span className="sr-only">Open user menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
              </svg>
            </Menu.Button>
          </div>
          <MenuTransition>
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem
                key={`Menu.Item-Profile-edit`}
                onClick={() => {
                  setShowFormForId(webhook.id)
                }}
              >
                <a>{t('webhooks.button.edit')}</a>
              </MenuItem>
              <MenuItem
                key={`Menu.Item-Profile-delete`}
                onClick={() => {
                  deleteWebhook(webhook.id, webhook.name)
                }}
              >
                <a>{t('webhooks.button.delete')}</a>
              </MenuItem>
            </Menu.Items>
          </MenuTransition>
        </Menu>
      )}
    </div>
  )
}

WebhookListItem.propTypes = {
  webhook: object,
  showFormForId: string,
  vendorId: string,
  setShowFormForId: func.isRequired,
  creatorMode: bool,
  eventEnumsOptions: array
}

export default memo(WebhookListItem)
