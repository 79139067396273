import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react'
import { array, bool, func, object, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import TextArea from './textarea'

/**
 * texts: {
 *  [locale]: <string>
 * }
 */
const MultipleTextareaI18nInputs = forwardRef(
  ({ id, locales, texts, onChange, disabled }, ref) => {
    const { t } = useTranslation()
    const [updatedTexts, setUpdatedTexts] = useState({})
    const [validationErrors, setValidationErrors] = useState({})

    // Validate source texts
    const validate = useCallback(() => {
      let vErrors = {}

      if (!updatedTexts.en_GB && !texts.en_GB) {
        vErrors.en_GB = 'forms:validation.general.required'
      }

      setValidationErrors(vErrors)

      return !Object.keys(vErrors).length
    }, [updatedTexts.en_GB, texts.en_GB])

    useImperativeHandle(ref, () => {
      return {
        validate
      }
    })

    const localesMemo = useMemo(() => {
      if (!locales) {
        return []
      }

      const localesWithoutSource = locales.filter((locale) => locale !== 'en_GB')

      return ['en_GB', ...localesWithoutSource]
    }, [locales])

    const handleChange = (locale, value) => {
      const newTexts = { ...updatedTexts }
      newTexts[locale] = value || ''

      if (typeof newTexts.en_GB === 'undefined') {
        newTexts.en_GB = texts.en_GB
      }

      setUpdatedTexts(newTexts)

      if (validationErrors[locale]) {
        setValidationErrors((prev) => {
          const prevState = { ...prev }
          delete prevState[locale]
          return prevState
        })
      }

      if (id) {
        onChange(id, newTexts)
      } else {
        onChange(newTexts)
      }
    }

    const getTextValue = (locale) => {
      if (typeof updatedTexts[locale] !== 'undefined') {
        return updatedTexts[locale]
      }

      return texts?.[locale] || ''
    }

    return (
      <div className="grid gap-2 sm:grid-cols-2">
        {localesMemo.map((locale) => {
          return (
            <TextArea
              key={locale}
              id={locale}
              className="flex-1"
              value={getTextValue(locale)}
              onChange={handleChange}
              disabled={disabled}
              /*placeholder={t('content:noTextAdded', {
                lng: locale
              })}*/
              rows={2}
              helperText={t(`content:localeToLanguage.${locale}`)}
              errorText={t(validationErrors[locale])}
            />
          )
        })}
      </div>
    )
  }
)

MultipleTextareaI18nInputs.propTypes = {
  id: string,
  locales: array,
  texts: object,
  onChange: func,
  disabled: bool
}

MultipleTextareaI18nInputs.defaultProps = {}

MultipleTextareaI18nInputs.displayName = 'MultipleTextareaI18nInputs'

export default MultipleTextareaI18nInputs
