import { gql } from '@apollo/client'

export const BULK_UPDATE_BUYING_INTENT_DATA = gql`
  mutation BulkUpdateBuyingIntentData(
    $siteDataInputs: [BuyingIntentSiteDataInput!]!
    $categoryInputs: [BuyingIntentSiteDataCategoryInput!]!
  ) {
    appBulkUpdateBuyingIntentSystemSiteData(
      siteDataInputs: $siteDataInputs
      categoryInputs: $categoryInputs
    )
  }
`

export const DELETE_BUYING_INTENT_CATEGORY = gql`
  mutation DeleteBuyingIntentCategory($buyingIntentSiteDataCategoryId: ID!) {
    appDeleteBuyingIntentSiteDataCategory(
      buyingIntentSiteDataCategoryId: $buyingIntentSiteDataCategoryId
    )
  }
`

export const UPDATE_BUYING_INTENT_DATA = gql`
  mutation UpdateBuyingIntentData($siteDataInputs: [BuyingIntentSiteDataInput!]!) {
    appUpdateBuyingIntentSystemSiteData(siteDataInputs: $siteDataInputs)
  }
`
