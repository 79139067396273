import React from 'react'
import Checkbox from '../../../../shared/checkbox'
import Button from '../../../../shared/button'

const BuyingIntentCategory = ({
  category,
  categoryData,
  siteId,
  categoryState,
  handleCategoryChange,
  handleUseDefaultCategory,
  shouldShowUseDefaultCategoryButton,
  getCategoryName,
  t
}) => {
  return (
    <div key={category} className="border p-2 rounded flex items-center justify-between">
      <h5 className="font-bold text-sm text-gray-500">{getCategoryName(category)}</h5>
      <div className="flex gap-3 items-center">
        <Checkbox
          label={t('system.buyingIntent.categories.view')}
          checked={categoryState[siteId]?.[category]?.view || false}
          onChange={() => handleCategoryChange(siteId, category, 'view')}
        />
        <Checkbox
          label={t('system.buyingIntent.categories.guideResult')}
          checked={categoryState[siteId]?.[category]?.guideResult || false}
          onChange={() => handleCategoryChange(siteId, category, 'guideResult')}
        />
        <Checkbox
          label={t('system.buyingIntent.categories.guideStart')}
          checked={categoryState[siteId]?.[category]?.guideStart || false}
          onChange={() => handleCategoryChange(siteId, category, 'guideStart')}
        />
        {shouldShowUseDefaultCategoryButton && (
          <Button
            className={'text-sm'}
            size={'small'}
            mode={'outlined'}
            type="button"
            onClick={() => handleUseDefaultCategory(categoryData.id, siteId, category)}
          >
            {t('common:useDefault')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default BuyingIntentCategory
