import React, { useMemo } from 'react'
import { bool, func, object, oneOfType, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Flag from '../../shared/flag'
import SitesSelect from '../../shared/sites-select'
import Button from '../../shared/button'
import TextArea from '../../shared/textarea'
import Input from '../../shared/input'
import ReviewStars from '../../shared/review-stars'

const ReviewItemForm = ({
  review,
  locale,
  onChange,
  isEditing,
  formData,
  validationErrors,
  disableSitesSelect,
  onPretranslate,
  pretranslateLoading
}) => {
  const { t } = useTranslation()
  const selectedLocale = formData?.locale

  const groupedReviewQuestions = useMemo(() => {
    const standardQuestions = []
    const ratingQuestions = []

    if (review.questions) {
      review.questions.forEach((question) => {
        if (question.questionType === 'RATING') {
          ratingQuestions.push(question)
        } else {
          standardQuestions.push(question)
        }
      })
    }

    return {
      standardQuestions,
      ratingQuestions
    }
  }, [review.questions])

  const localesToSkipMemo = useMemo(() => {
    const locales = [...new Set([review.originalLocale, ...review.availableLocales])]

    if (!isEditing || !locale) {
      return locales
    }

    return locales.filter((currLocale) => currLocale === locale)
  }, [review.originalLocale, review.availableLocales, locale, isEditing])

  const handlePretranslateText = () => {
    const fakeTitleObject = {
      answerInstanceId: 'title',
      answerText: review.title
    }

    const translatorInputData = [
      fakeTitleObject,
      ...groupedReviewQuestions.standardQuestions
    ].reduce((acc, question) => {
      if (!question.answerText[locale]?.trim()) {
        return acc
      }

      acc.push({
        key: question.answerInstanceId,
        text: question.answerText[locale].trim(),
        fromLocale: locale,
        toLocale: selectedLocale
      })

      return acc
    }, [])

    onPretranslate(translatorInputData)
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          {isEditing && (
            <div className="space-y-6 text-sm text-gray-500">
              <div className="flex flex-row items-center gap-2 mb-3">
                <Flag className="h-12 w-12" locale={selectedLocale || 'AQ'} />
                <SitesSelect
                  label={t('content:site')}
                  fieldName="locale"
                  value={selectedLocale}
                  onChange={onChange}
                  errorText={t(validationErrors?.locale)}
                  skipLocales={localesToSkipMemo}
                  disabled={disableSitesSelect}
                  disableAutoInit
                  marginless
                />

                <Button
                  onClick={handlePretranslateText}
                  disabled={!selectedLocale}
                  loading={pretranslateLoading}
                >
                  Pre-translate
                </Button>
              </div>
            </div>
          )}

          {review.title[locale] && !isEditing && (
            <h4 className="mb-3 text-sm font-medium text-gray-500 italic">
              &quot;{review.title[locale]}&quot;
            </h4>
          )}

          {isEditing && (
            <TextArea
              helperText={t('content:title')}
              fieldName="title"
              onChange={onChange}
              value={formData?.title}
              rows={2}
              errorText={t(validationErrors?.title)}
              disabled={pretranslateLoading}
            />
          )}

          {groupedReviewQuestions.standardQuestions.map((question) => {
            return (
              <div key={question.answerInstanceId}>
                <h4 className="mt-2 text-sm font-medium text-gray-600">
                  {question.questionText[review.originalLocale]}
                </h4>
                <div className="space-y-6 text-sm text-gray-600">
                  {isEditing && question.questionType === 'INPUT' ? (
                    <Input
                      id={question.answerInstanceId}
                      onChange={onChange}
                      disabled={pretranslateLoading}
                      value={formData?.[question.answerInstanceId]}
                      errorText={t(validationErrors?.[question.answerInstanceId])}
                    />
                  ) : isEditing && question.questionType === 'TEXTAREA' ? (
                    <TextArea
                      fieldName={question.answerInstanceId}
                      onChange={onChange}
                      value={formData?.[question.answerInstanceId]}
                      errorText={t(validationErrors?.[question.answerInstanceId])}
                      rows={4}
                      disabled={pretranslateLoading}
                    />
                  ) : (
                    question.answerText[locale] || '-'
                  )}
                </div>
              </div>
            )
          })}
        </div>

        <div className="center flex border-t mt-4 pt-4 justify-center items-center lg:ml-4 lg:pl-4 lg:pt-0 lg:w-max lg:mt-0 lg:border-l lg:border-t-0">
          <ReviewStars
            ratingQuestions={groupedReviewQuestions.ratingQuestions}
            locale={locale}
          />
        </div>
      </div>
    </div>
  )
}

ReviewItemForm.propTypes = {
  review: object.isRequired,
  locale: string.isRequired,
  onChange: func,
  isEditing: bool,
  formData: oneOfType([object]),
  validationErrors: object,
  disableSitesSelect: bool
}

ReviewItemForm.defaultProps = {}

export default ReviewItemForm
