import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_QUESTION_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const CREATE_REVIEW_QUESTION_ALTERNATIVE_GQL = gql`
  mutation CreateRevieweQuestionAlternative($questionId: ID!, $data: ReviewQuestionAnswerInput!) {
    createReviewQuestionAlternative(questionId: $questionId, data: $data) {
      ${REVIEW_TEMPLATE_QUESTION_GQL}
    }
  }
`

export default function useCreateReviewQuestionAlternative() {
  const { showErrorNotification } = useOverlayNotification()

  const [createReviewQuestionAlternativeMutation, { loading }] = useMutation(
    CREATE_REVIEW_QUESTION_ALTERNATIVE_GQL
  )

  const createReviewQuestionAlternative = useCallback(
    (questionId, texts, onCompleted) => {
      return createReviewQuestionAlternativeMutation({
        variables: {
          questionId,
          data: {
            texts
          }
        },
        onCompleted: ({ createRevieweQuestionAlternative }) => {
          onCompleted?.(createRevieweQuestionAlternative)
        },
        onError: showErrorNotification
      })
    },
    [createReviewQuestionAlternativeMutation, showErrorNotification]
  )

  return {
    createReviewQuestionAlternative,
    createReviewQuestionAlternativeLoading: loading
  }
}
