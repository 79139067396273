import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const UNPUBLISH_REVIEW_TEMPLATE_GQL = gql`
  mutation UnpublishReviewTemplate($reviewTemplateId: ID!) {
    unpublishReviewTemplate(reviewTemplateId: $reviewTemplateId) {
      id
      published
    }
  }
`

export default function useUnpublishReviewTemplate() {
  const { showErrorNotification } = useOverlayNotification()

  const [unpublishReviewTemplateMutation, { loading }] = useMutation(
    UNPUBLISH_REVIEW_TEMPLATE_GQL
  )

  const unpublishReviewTemplate = useCallback(
    (reviewTemplateId, onCompleted) => {
      return unpublishReviewTemplateMutation({
        variables: {
          reviewTemplateId
        },
        onCompleted: ({ unpublishReviewTemplate }) => {
          onCompleted?.(unpublishReviewTemplate)
        },
        onError: showErrorNotification
      })
    },
    [unpublishReviewTemplateMutation, showErrorNotification]
  )

  return {
    unpublishReviewTemplate,
    unpublishReviewTemplateLoading: loading
  }
}
