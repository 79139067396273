import React from 'react'
import { useTranslation } from 'react-i18next'
import { array, object } from 'prop-types'
import cx from 'classnames'

import { useNavigate } from 'react-router-dom'
import useGetCategoryName from '../../../../hooks/useGetCategoryName'

import CreateReviewTemplateDialog from '../create-review-template-dialog'

const ReviewTemplateRow = ({ reviewTemplate, onClick, getCategoriesString }) => {
  const { t } = useTranslation()
  const { id, published, name, categories } = reviewTemplate

  return (
    <div
      key={id}
      className={cx(
        'p-3 border border-gray-300 shadow-sm bg-white cursor-pointer rounded flex flex-col border-l-8 gap-1',
        {
          'border-l-red-600': !published,
          'border-l-green-600': published
        }
      )}
      onClick={() => onClick(reviewTemplate)}
    >
      <span className="font-medium">
        {!categories || categories.includes('ALL')
          ? t('reviewTemplates:templates.commonTemplate')
          : `${name || `-`}  - ${getCategoriesString(categories)}`}
      </span>
    </div>
  )
}

const ManageReviewTemplates = ({ commonReviewTemplate, reviewTemplates }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { getCategoriesString } = useGetCategoryName()

  const navigateToReviewTemplate = (reviewTemplate) => {
    navigate(`/admin-manage-review-templates/${reviewTemplate.id}`)
  }

  return (
    <div className="grid gap-4">
      <div className="flex mb-4 justify-between">
        <CreateReviewTemplateDialog onReviewTemplateCreated={navigateToReviewTemplate} />
      </div>

      {commonReviewTemplate && Object.keys(commonReviewTemplate).length > 0 && (
        <div className="grid gap-3">
          <h2 className="text-lg font-medium text-gray-700">
            {t('reviewTemplates:templates.commonTemplate')}
          </h2>
          <ReviewTemplateRow
            reviewTemplate={commonReviewTemplate}
            onClick={navigateToReviewTemplate}
            getCategoriesString={getCategoriesString}
          />
        </div>
      )}

      <div className="grid gap-3">
        <h2 className="text-lg font-medium text-gray-700">
          {t('reviewTemplates:templates.categorySpecificTemplates')}
        </h2>

        {reviewTemplates.map((reviewTemplate) => {
          return (
            <ReviewTemplateRow
              key={reviewTemplate.id}
              reviewTemplate={reviewTemplate}
              onClick={navigateToReviewTemplate}
              getCategoriesString={getCategoriesString}
            />
          )
        })}
      </div>
    </div>
  )
}

ManageReviewTemplates.propTypes = {
  reviewTemplates: array.isRequired,
  commonReviewTemplate: object.isRequired
}

ManageReviewTemplates.defaultProps = {}

export default ManageReviewTemplates
