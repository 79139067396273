import React, { useState } from 'react'
import { func } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useGenericFormManager from '../../../hooks/useGenericFormManager'
import useCreateReviewTemplate from '../../../hooks/manage-review-templates/useCreateReviewTemplate'

import Dialog from '../../shared/dialog'
import Button from '../../shared/button'
import ReviewTemplateGeneralForm, {
  REVIEW_TEMPLATE_GENERAL_FORM_INSTR
} from './review-template-general-form'

const CreateReviewTemplateDialog = ({ onReviewTemplateCreated }) => {
  const { t } = useTranslation('adminPages')
  const [open, setOpen] = useState(false)

  const { createReviewTemplate, createReviewTemplateLoading } = useCreateReviewTemplate()

  const { formData, onChange, validate, changedFields, resetForm, validationErrors } =
    useGenericFormManager(REVIEW_TEMPLATE_GENERAL_FORM_INSTR)

  const handleSubmit = () => {
    if (!validate()) {
      return
    }

    createReviewTemplate(changedFields, onReviewTemplateCreated)
  }

  const handleCancel = () => {
    resetForm()
    setOpen(false)
  }

  const renderCustomFooter = () => {
    return (
      <div className="flex justify-end mt-5 gap-3 sm:mt-6">
        <Button
          mode="default"
          onClick={handleCancel}
          disabled={createReviewTemplateLoading}
        >
          {t('content:cancel')}
        </Button>

        <Button onClick={handleSubmit} loading={createReviewTemplateLoading}>
          {t('content:save')}
        </Button>
      </div>
    )
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('content:create')}</Button>

      <Dialog
        isOpen={open}
        title={t('reviewTemplates:templates.createDialog.title')}
        renderFooter={renderCustomFooter}
      >
        <div className="grid grid-flow-row gap-4">
          <ReviewTemplateGeneralForm
            formData={formData}
            onChange={onChange}
            validationErrors={validationErrors}
          />
        </div>
      </Dialog>
    </>
  )
}

CreateReviewTemplateDialog.propTypes = {
  onReviewTemplateCreated: func
}

CreateReviewTemplateDialog.defaultProps = {}

export default CreateReviewTemplateDialog
