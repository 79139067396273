// Field configuration for Buying Intent Notification
export const BUYING_INTENT_NOTIFICATION = {
  id: 'activityNotifications',
  textTid: 'send',
  type: 'select',
  values: [
    {
      id: 'ENABLED',
      titleTid: 'content:yes'
    },
    {
      id: 'DISABLED',
      titleTid: 'content:no'
    }
  ]
}

// Field configuration for Activity Above
export const FIELD_ACTIVITY_ABOVE = {
  id: 'activityAbove',
  textTid: 'buyingIntent:notifications.activityAbove',
  type: 'select',
  values: [
    {
      id: 'LOW',
      titleTid: 'buyingIntent:activity.low'
    },
    {
      id: 'MEDIUM',
      titleTid: 'buyingIntent:activity.medium'
    },
    {
      id: 'HIGH',
      titleTid: 'buyingIntent:activity.high'
    }
  ]
}

// Field configuration for Journey as Reached
export const FIELD_JOURNEY_REACHED = {
  id: 'journeyReached',
  textTid: 'buyingIntent:notifications.buyersJourneyStageReached',
  type: 'select',
  values: [
    {
      id: 'AWARENESS',
      titleTid: 'buyingIntent:buyersJourneyStage.awareness'
    },
    {
      id: 'CONSIDERATION',
      titleTid: 'buyingIntent:buyersJourneyStage.consideration'
    },
    {
      id: 'DECISION',
      titleTid: 'buyingIntent:buyersJourneyStage.decision'
    }
  ]
}

// Field configuration for Customer Profile Matches
export const FIELD_CUSTOMER_PROFILE_MATCH = {
  id: 'customerProfileMatch',
  textTid: 'buyingIntent:notifications.customerProfileMatch',
  type: 'select',
  values: [
    {
      id: 'NA',
      titleTid: 'ignore'
    },
    {
      id: 'HALF_MATCH',
      titleTid: 'buyingIntent:filters.customerProfileMatch50'
    },
    {
      id: 'FULL_MATCH',
      titleTid: 'buyingIntent:filters.customerProfileMatch100'
    }
  ]
}
