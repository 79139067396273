import React from 'react'
import Dialog from '../../../../shared/dialog'
import BuyingIntentCategory from './BuyingIntentCategory'
import useBuyingIntent from '../../../hooks/useBuyingIntent'
import useGetCategoryName from '../../../../../hooks/useGetCategoryName'

const CategoriesDialog = ({
  isOpen,
  onClose,
  selectedSystemSiteId,
  systemSiteData,
  t
}) => {
  const {
    categoryState,
    selectedCategories,
    handleCategoryChange,
    handleUseDefaultCategory
  } = useBuyingIntent()
  const { getCategoryName } = useGetCategoryName()

  return (
    <Dialog
      className={'!max-w-[700px]'}
      onClose={onClose}
      title={t('system.buyingIntent.categories.title')}
      isOpen={isOpen}
    >
      <form>
        <div className="p-4">
          {systemSiteData.map((site) => (
            <div
              key={site.id}
              className={`mb-6 ${selectedSystemSiteId !== site.id && 'hidden'}`}
            >
              <div className="grid grid-cols-1 gap-4">
                {selectedCategories.map((category) => {
                  const categoryData = !site?.buyingIntentSiteData?.length
                    ? null
                    : site.buyingIntentSiteData[0]?.buyingIntentSiteDataCategories?.find(
                        (c) => c.category === category
                      )
                  let shouldShowUseDefaultCategoryButton = false
                  if (categoryData && categoryData.id) {
                    const categoryValues = categoryState[site?.id]?.[category]
                    shouldShowUseDefaultCategoryButton =
                      categoryValues &&
                      (categoryValues.view ||
                        categoryValues.guideResult ||
                        categoryValues.guideStart)
                  }
                  return (
                    <BuyingIntentCategory
                      key={category}
                      category={category}
                      categoryData={categoryData}
                      siteId={site.id}
                      categoryState={categoryState}
                      handleCategoryChange={handleCategoryChange}
                      handleUseDefaultCategory={handleUseDefaultCategory}
                      shouldShowUseDefaultCategoryButton={
                        shouldShowUseDefaultCategoryButton
                      }
                      getCategoryName={getCategoryName}
                      t={t}
                    />
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </form>
    </Dialog>
  )
}

export default CategoriesDialog
