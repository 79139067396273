import React, { useMemo, useState } from 'react'
import { gql } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import useAdminQuery from '../hooks/useAdminQuery'
import useFetchSystemVendorReviews from '../hooks/useFetchVendorSystemReviews'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import SystemsReviewsFilter from '../components/reviews/your-reviews/filter'
import ReviewList from '../components/reviews/your-reviews/list'
import ReviewerList from '../components/reviews/your-reviews/reviewer/list'
import Tabs from '../components/shared/tabs'

const GET_SYSTEMS = gql`
  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      systemSiteData {
        id
        locale
        systemName
      }
    }
  }
`

function SystemsReviewsPage() {
  const { t } = useTranslation(['content'])
  const [filteredSystemsReviews, setFilteredSystemsReviews] = useState(null)
  const [activeTab, setActiveTab] = useState('REVIEWS')

  const { vendorSystemReviews } = useFetchSystemVendorReviews()

  const { data: availableSystemsData, loading: availableSystemsLoading } =
    useAdminQuery(GET_SYSTEMS)
  const availableSystems = availableSystemsData?.appSystemsOnVendor

  const allSystemsReviews = useMemo(() => {
    if (!vendorSystemReviews || !availableSystems) {
      return []
    }

    const systemGrouppedReviewsMap = vendorSystemReviews.reduce((acc, review) => {
      const systemId = review.systemV2Id

      if (!acc[systemId]) {
        acc[systemId] = []
      }

      acc[systemId].push(review)

      return acc
    }, {})

    return availableSystems.map((system) => {
      return {
        ...system,
        reviews: systemGrouppedReviewsMap[system.id] || []
      }
    })
  }, [availableSystems, vendorSystemReviews])

  const reviewerData = useMemo(() => {
    if (!vendorSystemReviews) {
      return []
    }

    let emails = []

    vendorSystemReviews.forEach((review) => {
      emails.push(review.email)
    })

    // Randomize the emails
    for (var i = emails.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = emails[i]
      emails[i] = emails[j]
      emails[j] = temp
    }

    return emails.map((email) => ({ email }))
  }, [vendorSystemReviews])

  const handleTabChange = ({ value }) => {
    setActiveTab(value)
  }

  const tabs = useMemo(() => {
    return reviewerData.length > 0
      ? [
          { label: t('reviews:tabs:reviews'), value: 'REVIEWS' },
          { label: t('reviews:tabs.reviewer'), value: 'REVIEWER' }
        ]
      : [{ label: t('reviews:tabs:reviews'), value: 'REVIEWS' }]
  }, [reviewerData, t])

  return (
    <>
      <PageHeader pageName={t('reviews:header.title')} />

      <PageBody loading={availableSystemsLoading}>
        {tabs?.length > 1 && (
          <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
        )}

        {activeTab === 'REVIEWS' && (
          <>
            {!!allSystemsReviews?.length && (
              <SystemsReviewsFilter
                allSystemsWithReviews={allSystemsReviews}
                onReviewsFiltered={setFilteredSystemsReviews}
              />
            )}

            <ReviewList
              systemsWithReviews={filteredSystemsReviews}
              loading={availableSystemsLoading || !filteredSystemsReviews}
            />
          </>
        )}
        {activeTab == 'REVIEWER' && (
          <ReviewerList reviewers={reviewerData} loading={availableSystemsLoading} />
        )}
      </PageBody>
    </>
  )
}

export default SystemsReviewsPage
