import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from './useOverlayNotification'

const DEEPL_TRANSLATE_ARRAY = gql`
  mutation DeeplTranslateTextArray($data: [TranslatorInputArray!]!) {
    translateTextArray(data: $data) {
      key
      text
    }
  }
`
export default function useDeeplTranslator() {
  const { showErrorNotification, showSuccessNotification } = useOverlayNotification()

  const [deeplTranslateArrayMutation, { loading }] = useMutation(DEEPL_TRANSLATE_ARRAY)

  const translateTextArray = useCallback(
    (translatorInputArray, onCompleted) => {
      return deeplTranslateArrayMutation({
        variables: {
          data: translatorInputArray
        },
        onCompleted: ({ translateTextArray }) => {
          showSuccessNotification({ title: 'Text pretranslated' })
          onCompleted && onCompleted(translateTextArray)
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [deeplTranslateArrayMutation, showSuccessNotification, showErrorNotification]
  )

  return {
    translateTextArray,
    translateTextArrayLoading: loading
  }
}
