import React from 'react'

import useFetchReviewTemplates from '../hooks/manage-review-templates/useFetchReviewTemplates'
import useFetchCommonReviewTemplate from '../hooks/manage-review-templates/useFetchCommonReviewTemplate'

import Header from '../components/admin/manage-review-templates/header'
import { PageBody } from '../components/page-body'

import ManageReviewTemplates from '../components/admin/manage-review-templates/review-templates/manage-review-templates'

const AdminManageReviewTemplatesPage = () => {
  const { reviewTemplates, reviewTemplatesLoading } =
    useFetchReviewTemplates('cache-and-network')

  const { commonReviewTemplate, commonReviewTemplateLoading } =
    useFetchCommonReviewTemplate('cache-first')

  return (
    <>
      <Header />

      <PageBody loading={reviewTemplatesLoading || commonReviewTemplateLoading}>
        <ManageReviewTemplates
          reviewTemplates={reviewTemplates}
          commonReviewTemplate={commonReviewTemplate}
        />
      </PageBody>
    </>
  )
}

AdminManageReviewTemplatesPage.propTypes = {}

AdminManageReviewTemplatesPage.defaultProps = {}

export default AdminManageReviewTemplatesPage
