import React from 'react'
import { bool, func, number, object } from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { MenuIcon } from '@heroicons/react/outline'
import Chip from '../../../shared/chip'
import Flag from '../../../shared/flag'
import LoadingSpinner from '../../../shared/loading-spinner'

import QuestionTypePreview from '../review-question/question-type-preview'

const QuestionItem = ({
  reviewQuestion,
  onEdit,
  index,
  dragLoading,
  provided,
  snapshot,
  selectedLocale,
  commonReviewTemplateMode
}) => {
  const { t } = useTranslation()

  const handleEdit = () => {
    onEdit(reviewQuestion.id)
  }

  return (
    <div
      className="border border-gray-300 shadow-sm bg-white rounded flex flex-rows"
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      <div
        className={cx('flex items-center border-r px-2', {
          'cursor-move': !dragLoading
        })}
        {...provided?.dragHandleProps}
      >
        {dragLoading ? (
          <LoadingSpinner className="w-4 h-4" />
        ) : (
          <MenuIcon className="w-4 h-4 text-gray-600" />
        )}
      </div>

      <div className="p-3 w-full cursor-pointer" onClick={handleEdit}>
        <div className="flex mb-1">
          {reviewQuestion.specialName && (
            <div className="flex">
              <Chip text={reviewQuestion.specialName} type="success" />
            </div>
          )}

          {reviewQuestion.isCommonQuestion && !commonReviewTemplateMode && (
            <div className="flex">
              <Chip text={t('content:linked')} type="primary" />
            </div>
          )}

          {reviewQuestion.required && (
            <div className="flex">
              <Chip text={t('content:required')} type="warning" />
            </div>
          )}
        </div>

        {Object.entries(reviewQuestion.texts || {}).map(([locale, text], qIndex) => {
          if (
            selectedLocale !== 'ALL' &&
            selectedLocale !== locale &&
            locale !== 'en_GB'
          ) {
            return null
          }

          return (
            <div className="flex items-center" key={qIndex}>
              <Flag className="mr-2" locale={locale} height="20px" />
              <span>{text}</span>
            </div>
          )
        })}

        <div className="mt-3">
          <QuestionTypePreview
            question={reviewQuestion}
            index={index}
            previewLocale={selectedLocale}
          />
        </div>
      </div>
    </div>
  )
}

QuestionItem.propTypes = {
  reviewQuestion: object,
  onEdit: func,
  index: number,
  dragLoading: bool,
  provided: object,
  snapshot: object
}

QuestionItem.defaultProps = {}

export default QuestionItem
