import { useCallback } from 'react'

import { useManageSystemContext } from '../context'
import useCreateSystem from '../../../hooks/useCreateSystem'
import useUpdateSystem from '../../../hooks/useUpdateSystem'

export default function useSubmitSystem() {
  const {
    fieldsData,
    mediaAssetsData,
    operationMode,
    resetSystemForm,
    changedFields,
    resetChangedFields,
    biConfigChangedSiteIds,
    biConfigFormState,
    biConfigCategoryState,
    biInitialFormStateRef,
    biInitialCategoryStateRef
  } = useManageSystemContext()

  const { createSystem, loading: createSystemLoading } = useCreateSystem()
  const { updateSystem, loading: updateSystemLoading } = useUpdateSystem()

  const submitSystem = useCallback(() => {
    if (operationMode === 'CREATE') {
      return createSystem(fieldsData, mediaAssetsData, () => {
        resetSystemForm()
      })
    }
    if (operationMode === 'UPDATE') {
      return updateSystem(
        fieldsData.id,
        changedFields,
        mediaAssetsData,
        { biConfigChangedSiteIds, biConfigFormState, biConfigCategoryState },
        () => {
          resetChangedFields()
        }
      )
    }
  }, [
    fieldsData,
    changedFields,
    mediaAssetsData,
    operationMode,
    updateSystem,
    createSystem,
    resetSystemForm,
    resetChangedFields,
    biConfigChangedSiteIds,
    biConfigCategoryState,
    biConfigFormState,
    biInitialFormStateRef,
    biInitialCategoryStateRef
  ])

  return {
    submitSystem,
    loading: createSystemLoading || updateSystemLoading
  }
}
