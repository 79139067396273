import React, { useMemo, useState } from 'react'
import { array } from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

import useUpdateReviewTemplateQuestionsOrder from '../../../../hooks/manage-review-templates/useUpdateReviewTemplateQuestionsOrder'
import useFetchCommonReviewTemplate from '../../../../hooks/manage-review-templates/useFetchCommonReviewTemplate'

import LocaleSelector from './locale-selector'
import ManageReviewQuestionDialog from '../manage-review-question-dialog'
import QuestionItem from './item'
import QuestionCreatorItem from './question-creator-item'

const QuestionsOverview = ({ reviewTemplate }) => {
  const [manageQuestionId, setManageQuestionId] = useState(false) // true if creator, questionId if edit
  const [reorderedQuestions, setReorderedQuestions] = useState([])
  const [selectedLocale, setSelectedLocale] = useState('sv_SE')

  const { commonReviewTemplate } = useFetchCommonReviewTemplate()

  const {
    updateReviewTemplateQuestionsOrder,
    updateReviewTemplateQuestionsOrderLoading
  } = useUpdateReviewTemplateQuestionsOrder()

  const reviewQuestions = reviewTemplate?.reviewQuestions
  const isCommonReviewTemplate = reviewTemplate?.id === commonReviewTemplate?.id

  const reviewQuestionsMemo = useMemo(() => {
    return reorderedQuestions.length ? reorderedQuestions : reviewQuestions
  }, [reviewQuestions, reorderedQuestions])

  const handleEditQuestion = (questionId) => {
    setManageQuestionId(questionId)
  }

  const handleOpenCreator = () => {
    setManageQuestionId(true)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleDragEnd = (result) => {
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    // Dropped at same spot
    if (sourceIndex === destIndex) {
      return
    }

    const updatedItems = reorder(reviewQuestionsMemo, sourceIndex, destIndex)

    setReorderedQuestions(updatedItems)

    const questionsOrder = updatedItems.map((question) => question.id)

    updateReviewTemplateQuestionsOrder(reviewTemplate.id, questionsOrder, () => {
      setReorderedQuestions(false)
    })
  }

  return (
    <div>
      <div className="flex gap-3 flex-col">
        {reviewQuestionsMemo.length > 0 && (
          <div className="flex items-start">
            <LocaleSelector onChange={setSelectedLocale} value={selectedLocale} />
          </div>
        )}

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className="flex flex-col gap-2"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {reviewQuestionsMemo.map((reviewQuestion, index) => (
                  <Draggable
                    key={reviewQuestion.id}
                    draggableId={reviewQuestion.id}
                    index={index}
                    isDragDisabled={updateReviewTemplateQuestionsOrderLoading}
                  >
                    {(provided, snapshot) => (
                      <QuestionItem
                        key={reviewQuestion.id}
                        reviewQuestion={reviewQuestion}
                        onEdit={handleEditQuestion}
                        index={index}
                        dragLoading={updateReviewTemplateQuestionsOrderLoading}
                        selectedLocale={selectedLocale}
                        provided={provided}
                        snapshot={snapshot}
                        commonReviewTemplateMode={isCommonReviewTemplate}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <QuestionCreatorItem
          reviewTemplateId={reviewTemplate.id}
          onRequestCreate={handleOpenCreator}
          selectedLocale={selectedLocale}
          hideQuestionLink={isCommonReviewTemplate}
        />
      </div>

      <ManageReviewQuestionDialog
        reviewTemplate={reviewTemplate}
        reviewQuestionId={manageQuestionId}
        onClose={setManageQuestionId}
        allowEditLinkedQuestion={isCommonReviewTemplate}
      />
    </div>
  )
}

QuestionsOverview.propTypes = {
  reviewQuestions: array,
  guideLocales: array
}

QuestionsOverview.defaultProps = {
  reviewQuestions: []
}

export default QuestionsOverview
