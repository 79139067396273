import { getCompanySizeCategory } from './company-size.utils'
export const buyingIntentFilters = [
  {
    id: 'companySize',
    nameTid: 'buyingIntent:filters.companySize',
    sort: 10,
    options: [
      { value: 'MICRO', label: '1-10' },
      { value: 'SMALL', label: '11-100' },
      { value: 'MEDIUM', label: '101-500' },
      { value: 'LARGE', label: '500+' }
    ]
  },
  {
    id: 'branch',
    nameTid: 'content:industry',
    sort: 14,
    options: []
  },
  {
    id: 'revenueMin',
    nameTid: 'buyingIntent:filters.revenueMin',
    sort: 15,
    options: [],
    singleValue: true
  },
  {
    id: 'revenueMax',
    nameTid: 'buyingIntent:filters.revenueMax',
    sort: 16,
    options: [],
    singleValue: true
  },
  {
    id: 'activity',
    nameTid: 'buyingIntent:filters.activity',
    sort: 20,
    options: [
      { value: 'LOW', labelTid: 'buyingIntent:activity.low' },
      { value: 'MEDIUM', labelTid: 'buyingIntent:activity.medium' },
      { value: 'HIGH', labelTid: 'buyingIntent:activity.high' }
    ]
  },
  {
    id: 'buyersJourneyStage',
    nameTid: 'buyingIntent:filters.buyersJourneyStage',
    sort: 25,
    options: [
      { value: 'AWARENESS', labelTid: 'buyingIntent:buyersJourneyStage.awareness' },
      {
        value: 'CONSIDERATION',
        labelTid: 'buyingIntent:buyersJourneyStage.consideration'
      },
      { value: 'DECISION', labelTid: 'buyingIntent:buyersJourneyStage.decision' }
    ]
  },
  {
    id: 'customerProfileMatch',
    nameTid: 'buyingIntent:filters.customerProfileMatch',
    sort: 30,
    singleValue: true,
    options: [
      {
        value: '25',
        labelTid: 'buyingIntent:filters.customerProfileMatch25'
      },
      {
        value: '50',
        labelTid: 'buyingIntent:filters.customerProfileMatch50'
      },
      {
        value: '75',
        labelTid: 'buyingIntent:filters.customerProfileMatch75'
      },
      {
        value: '100',
        labelTid: 'buyingIntent:filters.customerProfileMatch100'
      }
    ]
  }
]

export const filterByBranch = (buyingIntents, values) => {
  return buyingIntents.filter((bi) => values.includes(bi.bwBranch))
}

export const filterByBuyersJourneyStage = (buyingIntents, values) => {
  return buyingIntents.filter((bi) => values.includes(bi.stage))
}

export const filterByCompanySize = (buyingIntents, values) => {
  return buyingIntents.filter((bi) =>
    values.includes(getCompanySizeCategory(bi.companySize))
  )
}

export const filterByRevenueMin = (buyingIntents, values) => {
  const minValue = parseFloat(values[0])
  return buyingIntents.filter(
    (bi) => bi?.revenue?.min && parseFloat(bi.revenue.min) >= minValue
  )
}

export const filterByRevenueMax = (buyingIntents, values) => {
  const maxValue = parseFloat(values[0])
  return buyingIntents.filter(
    (bi) => bi?.revenue?.max && parseFloat(bi.revenue.max) <= maxValue
  )
}

export const filterByActivity = (buyingIntents, values) => {
  return buyingIntents.filter((bi) =>
    values.some((value) => {
      const activity = bi.activity
      if (value === 'LOW') return activity >= 0 && activity <= 25
      if (value === 'MEDIUM') return activity > 25 && activity <= 75
      if (value === 'HIGH') return activity > 75
      return false
    })
  )
}

export const filterByCustomerProfileMatch = (buyingIntents, values) => {
  return buyingIntents.filter((bi) =>
    values.some(
      (value) =>
        parseFloat(bi.buyingIntentActivityToSystem.customerProfileMatch) >=
        parseFloat(value)
    )
  )
}
