import React, { useState, memo } from 'react'
import { bool, func, object, string } from 'prop-types'
import cx from 'classnames'

import { ChevronRightIcon, ChevronDownIcon, CalendarIcon } from '@heroicons/react/solid'
import { LinkIcon } from '@heroicons/react/outline'

import { useTranslation } from 'react-i18next'

import ReviewItemForm from './item-form'
import Comments from './comments'

import Flag from '../../shared/flag'
import ReviewTranslationControls from '../../shared/reviews/translation-controls'

const ReviewItem = ({ review, locale, isSubItem, isCreator, onDelete }) => {
  const [isOpen, setIsOpen] = useState(isCreator ? true : false)
  const { t } = useTranslation()

  const title = review.title?.[locale]

  return (
    <li>
      <div
        className="block hover:bg-gray-50 cursor-pointer"
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div
          className={cx('flex items-center', {
            'p-4 sm:px-6': !isSubItem,
            'py-3 px-6': isSubItem
          })}
        >
          {isSubItem && (
            <LinkIcon
              className={cx('w-5 h-5 mr-3', {
                'text-red-600': !review.approved,
                'text-green-600': review.approved
              })}
            />
          )}

          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={locale} />
              </div>
            </div>

            {isSubItem && (
              <div className="px-3 flex items-center text-sm text-gray-500">
                <p>Översättning för {t(`shared:localeToCountry.${locale}`)}</p>
              </div>
            )}

            {!isSubItem && (
              <div className="flex-1 px-4 grid sm:grid-cols-2 gap-2 sm:gap-4">
                <div className="min-w-0">
                  <p
                    className={cx('text-sm font-medium text-blue-600 truncate', {
                      'italic text-gray-500': !title
                    })}
                  >
                    {title || t('reviews:missingTitle')}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <time dateTime={review.createdAt}>
                      {review.createdAt.toLocaleString('sv').substr(0, 10)}
                    </time>
                  </p>
                </div>

                <div className="flex gap-1 items-center flex-wrap">
                  {!isSubItem &&
                    review.availableLocales.map((locale) => {
                      return (
                        <Flag
                          className="h-4 w-4 sm:w-5 sm:h-5"
                          locale={locale}
                          key={locale}
                        />
                      )
                    })}
                </div>
              </div>
            )}
          </div>

          <div>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="px-10 py-6 bg-gray-50">
          <div className="space-y-4">
            <ReviewItemForm review={review} locale={locale} />
            <div className="border-t" />
            {!isSubItem && <Comments review={review} />}
          </div>

          <ReviewTranslationControls
            review={review}
            onDelete={onDelete}
            locale={locale}
            isSubItem={isSubItem}
          />
        </div>
      )}
    </li>
  )
}

ReviewItem.propTypes = {
  review: object.isRequired,
  locale: string.isRequired,
  isSubItem: bool,
  isCreator: bool,
  onDelete: func
}

ReviewItem.defaultProps = {}

export default memo(ReviewItem)
