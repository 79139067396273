import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_QUESTION_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const ARCHIVE_REVIEW_QUESTION_ALTERNATIVE_GQL = gql`
  mutation ArchiveReviewQuestionAlternative($questionId: ID!, $reviewQuestionAnswerId: ID!) {
    archiveReviewQuestionAlternative(questionId: $questionId, reviewQuestionAnswerId: $reviewQuestionAnswerId) {
      ${REVIEW_TEMPLATE_QUESTION_GQL}
    }
  }
`

export default function useArchiveReviewQuestionAlternative() {
  const { showErrorNotification } = useOverlayNotification()

  const [archiveReviewQuestionAlternativeMutation, { loading }] = useMutation(
    ARCHIVE_REVIEW_QUESTION_ALTERNATIVE_GQL
  )

  const archiveReviewQuestionAlternative = useCallback(
    (questionId, reviewQuestionAnswerId, onCompleted) => {
      return archiveReviewQuestionAlternativeMutation({
        variables: {
          questionId,
          reviewQuestionAnswerId
        },
        onCompleted: ({ archiveReviewQuestionAlternative }) => {
          onCompleted?.(archiveReviewQuestionAlternative)
        },
        onError: showErrorNotification
      })
    },
    [archiveReviewQuestionAlternativeMutation, showErrorNotification]
  )

  return {
    archiveReviewQuestionAlternative,
    archiveReviewQuestionAlternativeLoading: loading
  }
}
