import useAdminQuery from './useAdminQuery'
import { gql } from '@apollo/client'

import { TRANSFORMED_REVIEW_FRAGMENT } from '../constants/gql/reviews'

const GET_SYSTEM_VENDOR_REVIEWS = gql`
  query AppVendorSystemReviews($companyId: ID, $userId: ID, $systemId: ID) {
    appVendorSystemReviews(companyId: $companyId, userId: $userId, systemId: $systemId) {
      ${TRANSFORMED_REVIEW_FRAGMENT}
    }
  }
`

export default function useFetchSystemVendorReviews(fetchPolicy = 'cache-and-network') {
  const { data, loading } = useAdminQuery(GET_SYSTEM_VENDOR_REVIEWS, {
    fetchPolicy
  })

  return {
    vendorSystemReviews: data?.appVendorSystemReviews,
    vendorSystemReviewsLoading: loading
  }
}
