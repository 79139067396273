import React from 'react'
import { StarIcon } from '@heroicons/react/solid'
import cx from 'classnames'

import { array, bool, string } from 'prop-types'

const ReviewStars = ({ ratingQuestions, locale, showNumbers }) => {
  return (
    <div className="flex items-center xl:col-span-1">
      <div>
        {ratingQuestions.map((question) => {
          const { questionId, answerText } = question

          return (
            <div className="flex items-center" key={questionId}>
              {[0, 1, 2, 3, 4].map((rating) => (
                <StarIcon
                  key={rating}
                  className={cx(
                    answerText > rating ? 'text-yellow-400' : 'text-gray-200',
                    'h-5 w-5 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
              ))}

              <p className="ml-3 text-sm text-gray-700">
                {question.questionText[locale]}
                {showNumbers ? `: ${answerText}` : ''}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ReviewStars.propTypes = {
  ratingQuestions: array.isRequired,
  locale: string.isRequired,
  showNumbers: bool
}

ReviewStars.defaultProps = {
  showNumbers: false
}

export default ReviewStars
