export const sizeRanges = {
  MICRO: ['1-10'],
  SMALL: ['11-50', '11-100'],
  MEDIUM: ['101-500'],
  LARGE: ['500+', '500-1000', '1000+']
}

export const getCompanySizeCategory = (companySizeValue) => {
  const found = Object.keys(sizeRanges).find((category) => {
    sizeRanges[category].includes(companySizeValue.trim())
  })

  if (found) {
    return found
  }

  const companySizeRange = companySizeValue.split('-')
  const companySizeFrom = parseInt(companySizeRange[0])
  const companySizeTo = parseInt(companySizeRange[1])

  const found2 = Object.keys(sizeRanges).find((category) => {
    const r = sizeRanges[category].find((range) => {
      if (range.includes('+')) {
        const rangeFrom = parseInt(range.replace('+', ''))
        return companySizeFrom >= rangeFrom
      }
      const rangeValues = range.split('-')
      const rangeFrom = parseInt(rangeValues[0])
      const rangeTo = parseInt(rangeValues[1])
      return companySizeFrom >= rangeFrom && companySizeTo <= rangeTo
    })

    return r
  })

  return found2
}
