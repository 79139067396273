import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { TRANSFORMED_REVIEW_FRAGMENT } from '../../../../constants/gql/reviews'

const DELETE_REVIEW_TRANSLATION_GQL = gql`
  mutation AdminDeleteReviewTranslation(
    $reviewId: ID!
    $locale: String!
  ) {
    appAdminDeleteReviewTranslation(reviewId: $reviewId, locale: $locale) {
      ${TRANSFORMED_REVIEW_FRAGMENT}
    }
  }
`

export default function useDeleteReviewTranslation() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appAdminDeleteReviewTranslation, { loading }] = useMutation(
    DELETE_REVIEW_TRANSLATION_GQL
  )

  const deleteReviewTranslation = useCallback(
    (reviewId, locale, onCompleted) => {
      return appAdminDeleteReviewTranslation({
        variables: {
          reviewId,
          locale
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appAdminDeleteReviewTranslation, showSuccessNotification, showErrorNotification]
  )

  return {
    deleteReviewTranslation,
    deleteReviewTranslationLoading: loading
  }
}
