import React, { useState } from 'react'
import { array, bool } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useGetSystemName from '../../../hooks/useGetSystemName'
import { useAdminFieldsContext } from '../../../contexts'

import DeleteReviewTranslationDialog from './dialogs/delete-review-translation.dialog'
import Alert from '../../shared/alert'
import ReviewItem from './item'

const ReviewList = ({ systemsWithReviews, loading }) => {
  const { t } = useTranslation()
  const { adminFieldsVisible } = useAdminFieldsContext()
  const { getSystemName } = useGetSystemName()
  const [deleteReviewData, setDeleteReviewData] = useState(false)

  if (!systemsWithReviews?.length && !loading) {
    return <Alert>{t('content:warning.noData')}</Alert>
  }

  return systemsWithReviews?.map((system) => {
    return (
      <div className="mb-10" key={system.id}>
        <h2 className="text-lg font-medium text-gray-700 mb-3">
          {getSystemName(system)}
        </h2>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {!system.reviews.length && <Alert>{t('content:warning.noData')}</Alert>}

            {system.reviews.map((review) => (
              <React.Fragment key={review.id}>
                <ReviewItem review={review} locale={review.originalLocale} />

                {adminFieldsVisible &&
                  review.availableLocales?.map((locale) => {
                    return (
                      <ReviewItem
                        key={locale}
                        review={review}
                        locale={locale}
                        isSubItem={true}
                        onDelete={setDeleteReviewData}
                      />
                    )
                  })}
              </React.Fragment>
            ))}

            <DeleteReviewTranslationDialog
              reviewId={deleteReviewData?.reviewId}
              locale={deleteReviewData?.locale}
              onClose={setDeleteReviewData}
            />
          </ul>
        </div>
      </div>
    )
  })
}

ReviewList.propTypes = {
  systemsWithReviews: array,
  loading: bool
}

ReviewList.defaultProps = {}

export default ReviewList
