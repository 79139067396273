import React from 'react'

const { useTranslation } = require('react-i18next')

const BuyingIntentCustomerProfile = ({ intent }) => {
  const { customerProfileMatch } = intent?.buyingIntentActivityToSystem

  const { t } = useTranslation('buyingIntent')
  const percentageValue = parseInt(customerProfileMatch, 10)
  const radius = 90
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (percentageValue / 100) * circumference

  return (
    <div className="flex gap-3 items-center">
      {/* Circular Progress Bar */}
      <div className="relative w-12 h-12">
        <svg width="100%" height="100%" viewBox="0 0 200 200">
          {/* Background Circle */}
          <circle
            cx="100"
            cy="100"
            r={radius}
            fill="none"
            stroke="#e5e7eb" /* gray-300 */
            strokeWidth="20"
          />
          {/* Progress Circle */}
          <defs>
            <linearGradient id="meterGradient" x1="1" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3b82f6" />
              <stop offset="100%" stopColor="#3b82f6" />
            </linearGradient>
          </defs>
          <circle
            cx="100"
            cy="100"
            r={radius}
            fill="none"
            stroke="url(#meterGradient)"
            strokeWidth="20"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
            transform="rotate(-90 100 100)" // Start from top
          />
        </svg>
        {/* Percentage in the middle */}
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="text-sm font-medium">{customerProfileMatch}%</div>
        </div>
      </div>
      {/* Stage */}
      {!parseInt(customerProfileMatch) ? (
        <div className="text-xs font-medium">No Data</div>
      ) : (
        <div className="text-xs font-medium">
          {t('filters.customerProfileMatchShort')}
        </div>
      )}
    </div>
  )
}

export default BuyingIntentCustomerProfile
