import React, { useState } from 'react'
import { Radiobuttons } from '../form/inputs'
import { useTranslation } from 'react-i18next'
import {
  BUYING_INTENT_NOTIFICATION,
  FIELD_ACTIVITY_ABOVE,
  FIELD_JOURNEY_REACHED,
  FIELD_CUSTOMER_PROFILE_MATCH
} from '../../constants/buying-intent-activities-settings-fields'
import { gql, useMutation } from '@apollo/client'
import useAdminQuery from '../../hooks/useAdminQuery'
import Loader from '../loader'
import { AdjustmentsIcon } from '@heroicons/react/outline'
import { useUserContext } from '../../contexts'
import { isAdmin, isAppAdmin } from '../../utils'

const GET_BUYING_INTENT_ACTIVITY_SETTINGS = gql`
  query GetBuyingIntentSettings($companyId: ID, $userid: ID) {
    appBuyingIntentActivitySettings(companyId: $companyId, userId: $userid) {
      sendNotifications
      activityAbove
      journeyReached
      customerProfileMatch
    }
  }
`

const UPSERT_BUYING_INTENT_ACTIVITY_SETTINGS = gql`
  mutation UpsertBuyingIntentActivitySettings($data: BuyingIntentActivitySettingsData!) {
    appUpsertBuyingIntentActivitySettings(data: $data)
  }
`

const BuyingIntentActivitySettings = () => {
  const { t } = useTranslation('buyingIntent')
  const { user } = useUserContext()
  const admin = isAdmin(user?.role)
  const appAdmin = isAppAdmin(user?.appRole)

  const [activityNotification, setActivityNotification] = useState('DISABLED')
  const [activityAbove, setActivityAbove] = useState('LOW')
  const [journeyReached, setJourneyReached] = useState('AWARENESS')
  const [customerProfileMatch, setCustomerProfileMatch] = useState('NA') // Assuming 0 is a valid initial value

  const { loading, error } = useAdminQuery(GET_BUYING_INTENT_ACTIVITY_SETTINGS, {
    variables: {
      companyId: user.companyId,
      userid: user.userId
    },
    onCompleted: ({ appBuyingIntentActivitySettings }) => {
      console.log(appBuyingIntentActivitySettings)
      setActivityNotification(
        appBuyingIntentActivitySettings.sendNotifications ? 'ENABLED' : 'DISABLED'
      )
      setActivityAbove(appBuyingIntentActivitySettings.activityAbove || 'LOW')
      setJourneyReached(appBuyingIntentActivitySettings.journeyReached || 'AWARENESS')
      setCustomerProfileMatch(
        appBuyingIntentActivitySettings.customerProfileMatch === 100
          ? 'FULL_MATCH'
          : appBuyingIntentActivitySettings.customerProfileMatch === 50
          ? 'HALF_MATCH'
          : 'NA'
      )
    }
  })

  const [appUpsertBuyingIntentActivitySettings] = useMutation(
    UPSERT_BUYING_INTENT_ACTIVITY_SETTINGS
  )

  const upsertBuyingIntentActivitySettings = async (config) => {
    try {
      await appUpsertBuyingIntentActivitySettings({
        variables: {
          data: {
            settings: {
              ...config
            },
            userid: user.userId,
            companyId: user.companyId
          }
        }
      })
    } catch (error) {
      console.error('Mutation error:', error)
    }
  }

  const handleActivityChange = async ({ target: { value } }) => {
    setActivityNotification(value)
    await upsertBuyingIntentActivitySettings({
      sendNotifications: value === 'ENABLED'
    })
  }

  const handleActivityAboveChange = async ({ target: { value } }) => {
    setActivityAbove(value)
    await upsertBuyingIntentActivitySettings({
      activityAbove: value
    })
  }

  const handleJourneyReachedChange = async ({ target: { value } }) => {
    setJourneyReached(value)
    await upsertBuyingIntentActivitySettings({
      journeyReached: value
    })
  }

  const handleCustomerProfileMatchChange = async ({ target: { value } }) => {
    setCustomerProfileMatch(value)
    await upsertBuyingIntentActivitySettings({
      customerProfileMatch: value === 'FULL_MATCH' ? 100 : value === 'HALF_MATCH' ? 50 : 0
    })
  }

  if (!(admin || appAdmin)) {
    return
  }
  if (loading) return <Loader />
  if (error) return <p>Error: {error.message}</p>

  return (
    <div className={'bg-white shadow overflow-hidden sm:rounded-md my-10'}>
      <ul>
        {/* Existing Field: Activity Notification */}
        <li>
          <div className="relative block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="flex items-center gap-5 w-full">
                <div className={'flex items-center gap-5 basis-6/12'}>
                  <div className="h-10 w-10 rounded-full bg-cover bg-center">
                    <AdjustmentsIcon className={'text-gray-300'} />
                  </div>
                  <div className="flex justify-start items-center text-sm">
                    <div>{t('buyingIntentNotification')}</div>
                  </div>
                </div>
                <div className="basis-6/12">
                  <div className="flex items-center justify-start [&_div]:mt-0">
                    <Radiobuttons
                      data={activityNotification}
                      field={BUYING_INTENT_NOTIFICATION}
                      handleFieldChange={handleActivityChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        {/* New Field: Activity Above */}
        <li>
          <div className="relative block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="flex items-center gap-5 w-full">
                <div className={'flex items-center gap-5 basis-6/12'}>
                  <div className="h-10 w-10 rounded-full bg-cover bg-center">
                    <AdjustmentsIcon className={'text-gray-300'} />
                  </div>
                  <div className="flex justify-start items-center text-sm">
                    <div>{t('filters.activity')}</div>
                  </div>
                </div>
                <div className="basis-6/12">
                  <div className="flex items-center justify-start [&_div]:mt-0">
                    <Radiobuttons
                      data={activityAbove}
                      field={FIELD_ACTIVITY_ABOVE}
                      handleFieldChange={handleActivityAboveChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        {/* New Field: Journey Reached */}
        <li>
          <div className="relative block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="flex items-center gap-5 w-full">
                <div className={'flex items-center gap-5 basis-6/12'}>
                  <div className="h-10 w-10 rounded-full bg-cover bg-center">
                    <AdjustmentsIcon className={'text-gray-300'} />
                  </div>
                  <div className="flex justify-start items-center text-sm">
                    <div>{t('filters.buyersJourneyStage')}</div>
                  </div>
                </div>
                <div className="basis-6/12">
                  <div className="[&_div]:mt-0">
                    <Radiobuttons
                      data={journeyReached}
                      field={FIELD_JOURNEY_REACHED}
                      handleFieldChange={handleJourneyReachedChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        {/* New Field: Customer Profile Match */}
        <li>
          <div className="relative block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="flex items-center gap-5 w-full">
                <div className={'flex items-center gap-5 basis-6/12'}>
                  <div className="h-10 w-10 rounded-full bg-cover bg-center">
                    <AdjustmentsIcon className={'text-gray-300'} />
                  </div>
                  <div className="flex justify-start items-center text-sm">
                    <div>{t('filters.customerProfileMatch')}</div>
                  </div>
                </div>
                <div className="basis-6/12">
                  <div className="[&_div]:mt-0">
                    <Radiobuttons
                      data={customerProfileMatch.toString()}
                      field={FIELD_CUSTOMER_PROFILE_MATCH}
                      handleFieldChange={handleCustomerProfileMatchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default BuyingIntentActivitySettings
