import React from 'react'
import cx from 'classnames'

import { InformationCircleIcon } from '@heroicons/react/solid'
import { oneOf } from 'prop-types'

const Alert = ({ type, children }) => {
  const isInfo = type === 'info'
  const isWarning = type === 'warning'

  return (
    <div
      className={cx('rounded-md p-4 border', {
        'bg-blue-50 border-blue-300': isInfo,
        'bg-orange-50 border-orange-300': isWarning
      })}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className={cx('h-5 w-5', {
              'text-blue-400': isInfo,
              'text-orange-400': isWarning
            })}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div
            className={cx('text-sm', {
              'text-blue-700': isInfo,
              'text-orange-700': isWarning
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

Alert.propTypes = {
  type: oneOf(['info', 'warning'])
}

Alert.defaultProps = {
  type: 'info'
}

export default Alert
