import React, { useEffect } from 'react'

import useFetchReviewTemplate from '../hooks/manage-review-templates/useFetchReviewTemplate'

import Header from '../components/admin/manage-review-templates/header'
import { PageBody } from '../components/page-body'

import ManageReviewTemplate from '../components/admin/manage-review-templates/review-template/manage-review-template'

const AdminManageGuidePage = () => {
  const { reviewTemplate, refetchReviewTemplate, reviewTemplateLoading } =
    useFetchReviewTemplate()

  useEffect(() => {
    refetchReviewTemplate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />

      <PageBody loading={reviewTemplateLoading}>
        <ManageReviewTemplate reviewTemplate={reviewTemplate} />
      </PageBody>
    </>
  )
}

AdminManageGuidePage.propTypes = {}

AdminManageGuidePage.defaultProps = {}

export default AdminManageGuidePage
