import React, { useMemo } from 'react'
import { object } from 'prop-types'

import Alert from '../../../shared/alert'

import { reviewQuestionSpecialNamesOptions } from '../constants/review-question.constants'

const TemplateWarnings = ({ reviewTemplate }) => {
  console.log('reviewTemplate.reviewQuestions', reviewTemplate.reviewQuestions)

  const missingSpecialNamesArr = useMemo(() => {
    if (!reviewTemplate.reviewQuestions) {
      return []
    }

    return reviewQuestionSpecialNamesOptions.reduce((acc, specialNameOption) => {
      if (!specialNameOption.recommendedPresent) {
        return acc
      }

      const questionFound = reviewTemplate.reviewQuestions.some(
        (question) => question.specialName === specialNameOption.value
      )

      if (!questionFound) {
        acc.push(specialNameOption)
      }

      return acc
    }, [])
  }, [reviewTemplate.reviewQuestions])

  if (!missingSpecialNamesArr.length) {
    return null
  }

  return (
    <Alert type="warning">
      <div className="flex flex-col">
        {missingSpecialNamesArr.map((specialNameOption) => {
          return (
            <div className="mt-2" key={specialNameOption.value}>
              <p>Missing {specialNameOption.label}</p>
              <p className="ml-2">{specialNameOption.description}</p>
            </div>
          )
        })}
      </div>
    </Alert>
  )
}

TemplateWarnings.propTypes = {
  reviewTemplate: object.isRequired
}

TemplateWarnings.defaultProps = {}

export default TemplateWarnings
