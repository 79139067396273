import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { array, func } from 'prop-types'

import useGetSystemName from '../../../hooks/useGetSystemName'

import Filters from '../../shared/filters'

const SystemsReviewsFilter = ({ allSystemsWithReviews, onReviewsFiltered }) => {
  const [mappedActiveFilters, setMappedActiveFilters] = useState({})
  const { getSystemName } = useGetSystemName()

  const handleFilterReviews = useCallback(() => {
    // No Filters
    if (!Object.keys(mappedActiveFilters).length) {
      onReviewsFiltered(allSystemsWithReviews)
      return
    }

    const reviewsToSystemId = {}

    allSystemsWithReviews.forEach((system) => {
      const sitesFilter = mappedActiveFilters.locale || []
      const systemsFilter = mappedActiveFilters.system || []

      reviewsToSystemId[system.id] = system.reviews.filter((review) => {
        let matchSites = true
        if (sitesFilter.length) {
          matchSites =
            sitesFilter.includes(review.originalLocale) ||
            sitesFilter.some((site) => review.availableLocales.includes(site))
        }

        let matchSystems = true
        if (systemsFilter.length) {
          matchSystems = allSystemsWithReviews.some((system) =>
            systemsFilter.includes(system.id)
          )
        }

        return matchSites && matchSystems
      })
    })

    const filterResults = allSystemsWithReviews
      .map((system) => ({
        ...system,
        reviews: reviewsToSystemId[system.id]
      }))
      .filter((system) => system.reviews.length > 0)

    onReviewsFiltered(filterResults)
  }, [allSystemsWithReviews, mappedActiveFilters, onReviewsFiltered])

  useEffect(() => {
    handleFilterReviews()
  }, [handleFilterReviews])

  const handleChangeFilter = (updatedActiveFilters, updatedMappedActiveFilters) => {
    setMappedActiveFilters(updatedMappedActiveFilters)
  }

  const systemOptionsMemo = useMemo(() => {
    if (!allSystemsWithReviews?.length) {
      return []
    }
    return allSystemsWithReviews.map((system) => {
      return {
        label: getSystemName(system),
        value: system.id
      }
    })
  }, [allSystemsWithReviews, getSystemName])

  const sitesOptionsMemo = useMemo(() => {
    if (!allSystemsWithReviews?.length) {
      return []
    }

    const localeSet = new Set()
    allSystemsWithReviews.forEach((system) => {
      system.systemSiteData?.forEach((siteData) => {
        localeSet.add(siteData.locale)
      })
    })

    return [...localeSet].map((locale) => {
      return {
        labelTid: `shared:localeToCountry.${locale}`,
        value: locale
      }
    })
  }, [allSystemsWithReviews])

  const filtersMemo = useMemo(() => {
    return [
      {
        id: 'system',
        nameTid: 'content:system',
        options: systemOptionsMemo
      },
      {
        id: 'locale',
        nameTid: 'content:sites',
        options: sitesOptionsMemo
      }
    ]
  }, [systemOptionsMemo, sitesOptionsMemo])

  return <Filters filters={filtersMemo} onChangeFilters={handleChangeFilter} />
}

SystemsReviewsFilter.propTypes = {
  allSystemsWithReviews: array,
  onReviewsFiltered: func
}

SystemsReviewsFilter.defaultProps = {}

export default SystemsReviewsFilter
