import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const CREATE_REVIEW_QUESTION_GQL = gql`
  mutation CreateReviewTemplateQuestion($reviewTemplateId: ID!, $data: ReviewTemplateQuestionInput!) {
    createReviewTemplateQuestion(reviewTemplateId: $reviewTemplateId, data: $data) {
      ${REVIEW_TEMPLATE_GQL}
    }
  }
`

export default function useCreateReviewQuestion() {
  const { showErrorNotification } = useOverlayNotification()

  const [createReviewQuestionMutation, { loading }] = useMutation(
    CREATE_REVIEW_QUESTION_GQL
  )

  const createReviewQuestion = useCallback(
    (reviewTemplateId, data, onCompleted) => {
      return createReviewQuestionMutation({
        variables: {
          reviewTemplateId,
          data
        },
        onCompleted: ({ createReviewTemplateQuestion }) => {
          onCompleted?.(createReviewTemplateQuestion)
        },
        onError: showErrorNotification
      })
    },
    [createReviewQuestionMutation, showErrorNotification]
  )

  return {
    createReviewQuestion,
    createReviewQuestionLoading: loading
  }
}
