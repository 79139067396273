import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react'
import {
  DesktopComputerIcon,
  TagIcon,
  PhotographIcon,
  LightningBoltIcon
} from '@heroicons/react/outline'
import Flag from '../shared/flag'

import { useTranslation } from 'react-i18next'
import { useFormFields } from '../../hooks'
import useFetchAvailableSites from '../../hooks/useFetchAvailableSites'
import useGetCategoryName from '../../hooks/useGetCategoryName'
import { useUserContext } from '../../contexts'

import { profileFields } from '../../constants'
import { isAdmin } from '../../utils'

const ManageSystemContext = React.createContext({})

export const ManageSystemContextProvider = ({
  children,
  operationMode,
  systemData,
  setLangFields
}) => {
  const { t } = useTranslation()
  const { user } = useUserContext()
  const [activeTab, setActiveTab] = useState({})
  const [previewImageUrl, setPreviewImageUrl] = useState('')
  const hasAdmin = isAdmin(user?.role)

  /*
    [{
      action: UPLOAD / DELETE / EDIT
      type: "LOGO" || "SCREENSHOT" || "DOCUMENT" || "VIDEO"
      files: [{ <filedata>}, {..n}]
      order: <Int>
      locales: [<locale>]
      url: ""
    }]
  */
  const [mediaAssetsData, setMediaAssetsData] = useState([])
  const { sitesOptions } = useFetchAvailableSites()
  const { allCategoriesMap, getCategoryName } = useGetCategoryName()

  const isUpdateMode = operationMode === 'UPDATE'

  const [
    fieldsData,
    handleFieldChange,
    setInitForm,
    setFieldsDataRaw,
    changedFields,
    setChangedFields
  ] = useFormFields({}, activeTab.type === 'CATEGORY' ? activeTab.value : '')

  useEffect(() => {
    if (isUpdateMode) {
      if (!systemData) {
        return
      }

      const systemDataClone = JSON.parse(JSON.stringify(systemData))
      systemDataClone.systemSiteData?.forEach((siteData) => {
        siteData.revenueMin = siteData.revenue?.min
        siteData.revenueMax = siteData.revenue?.max
        siteData.licensesMin = siteData.licenses?.min
        siteData.licensesMax = siteData.licenses?.max
        return siteData
      })

      // Why will it not save?!?!
      systemDataClone.buyingIntentRevenueMin = systemData.buyingIntentRevenue?.min
      systemDataClone.buyingIntentRevenueMax = systemData.buyingIntentRevenue?.max

      setInitForm(systemDataClone)

      setMediaAssetsData({
        LOGO: systemDataClone.mediaAssets?.filter((asset) => asset.type === 'LOGO') || [],
        SCREENSHOT:
          systemDataClone.mediaAssets
            ?.filter((asset) => asset.type === 'SCREENSHOT')
            .sort((a, b) => a.order - b.order) || [],
        VIDEO:
          systemDataClone.mediaAssets
            ?.filter((asset) => asset.type === 'VIDEO')
            .sort((a, b) => a.order - b.order) || [],
        PITCH:
          systemDataClone.mediaAssets
            ?.filter((asset) => asset.type === 'PITCH')
            .sort((a, b) => a.order - b.order) || [],
        DOCUMENT:
          systemDataClone.mediaAssets
            ?.filter((asset) => asset.type === 'DOCUMENT')
            .sort((a, b) => a.order - b.order) || []
      })
    }
  }, [isUpdateMode, setInitForm, systemData])

  const selectedCategories = useMemo(() => {
    return fieldsData.categories || []
  }, [fieldsData.categories])

  const selectedCountries = useMemo(() => {
    return fieldsData.systemSiteData?.map((siteData) => siteData.locale) || []
  }, [fieldsData.systemSiteData])

  const allTabs = useMemo(() => {
    if (!Object.keys(allCategoriesMap).length) {
      return []
    }

    if (!sitesOptions || !sitesOptions.length) {
      return []
    }

    const INIT_TAB = {
      label: t('content:system'),
      value: 'SYSTEM',
      icon: DesktopComputerIcon
    }

    const MEDIA_TAB = { label: t('content:media'), value: 'MEDIA', icon: PhotographIcon }

    const categories = selectedCategories
      .map((cat) => {
        const catName = getCategoryName(cat)

        return {
          label: catName || cat,
          value: cat,
          type: 'CATEGORY',
          icon: TagIcon,
          categoryMissing: !catName,
          className: !catName ? 'border-orange-400' : ''
        }
      })
      .filter((category) => {
        return hasAdmin ? true : !category.categoryMissing
      })

    const countries =
      selectedCountries.map((locale) => {
        const countryName = sitesOptions.find(
          (countryConst) => countryConst.value === locale
        ).labelTid

        return {
          label: t(countryName),
          value: locale,
          type: 'COUNTRY',
          customIcon: <Flag locale={locale} height="16px" />
        }
      }) || []

    const tabsArray = [INIT_TAB, MEDIA_TAB, ...categories, ...countries].filter(Boolean) // filter faulty values in array like null, undefined

    return tabsArray
  }, [
    selectedCategories,
    selectedCountries,
    allCategoriesMap,
    sitesOptions,
    t,
    getCategoryName,
    hasAdmin
  ])

  const langFieldsMemo = useMemo(() => {
    if (activeTab.type !== 'COUNTRY') {
      return []
    }

    const propsLangFields = setLangFields && setLangFields(activeTab)
    const langFieldsData = propsLangFields || profileFields.langFields

    return langFieldsData.map((langField) => {
      langField.key = activeTab.value
      return langField
    })
  }, [activeTab, setLangFields])

  const handleChangeCallback = useCallback(
    (fieldName, value) => {
      setFieldsDataRaw((prevFormData) => ({ ...prevFormData, [fieldName]: value }))
      setChangedFields({
        ...changedFields,
        [fieldName]: value
      })
    },
    [changedFields, setFieldsDataRaw, setChangedFields]
  )

  const handleSetSelectedCategories = useCallback(
    (updatedCategories) => {
      const updatedvalues = {
        categories: updatedCategories
      }

      // If default category is removed from categories array
      // remove it from 'defaultCategory' key as well
      if (
        fieldsData.defaultCategory &&
        !updatedvalues.categories.includes(fieldsData.defaultCategory)
      ) {
        updatedvalues.defaultCategory = null
      }

      setFieldsDataRaw((prevFieldsData) => ({ ...prevFieldsData, ...updatedvalues }))
      setChangedFields((prevChangedFields) => ({
        ...prevChangedFields,
        ...updatedvalues
      }))
    },
    [fieldsData.defaultCategory, setFieldsDataRaw, setChangedFields]
  )

  const handleSetSelectedCountries = useCallback(
    (newCountries, action, affectedLocale) => {
      const newFieldsData = {
        ...fieldsData,
        systemSiteData: [...(fieldsData.systemSiteData || [])] || []
      }

      if (action === 'ADD') {
        newFieldsData.systemSiteData.push({
          locale: affectedLocale
        })

        setChangedFields((prevChangedFields) => {
          if (!prevChangedFields.systemSiteData) {
            prevChangedFields.systemSiteData = []
          }

          const addedFieldsSiteDataIndex = prevChangedFields.systemSiteData.findIndex(
            (siteData) => siteData.locale === affectedLocale
          )

          if (addedFieldsSiteDataIndex !== -1) {
            const prevAction =
              prevChangedFields.systemSiteData[addedFieldsSiteDataIndex].action

            if (prevAction === 'DELETE') {
              prevChangedFields.systemSiteData.splice(addedFieldsSiteDataIndex, 1)
            }
          } else {
            prevChangedFields.systemSiteData.push({
              action: 'CREATE',
              locale: affectedLocale
            })
          }

          return prevChangedFields
        })
      }

      if (action === 'DELETE') {
        const removedSiteDataIndex = newFieldsData.systemSiteData.findIndex(
          (siteData) => siteData.locale === affectedLocale
        )

        newFieldsData.systemSiteData.splice(removedSiteDataIndex, 1)

        setChangedFields((prevChangedFields) => {
          if (prevChangedFields.systemSiteData) {
            const changedFieldsSiteDataIndex = prevChangedFields.systemSiteData.findIndex(
              (siteData) => siteData.locale === affectedLocale
            )

            if (changedFieldsSiteDataIndex !== -1) {
              const prevAction =
                prevChangedFields.systemSiteData[changedFieldsSiteDataIndex].action

              if (prevAction === 'CREATE') {
                prevChangedFields.systemSiteData.splice(changedFieldsSiteDataIndex, 1)
              } else {
                prevChangedFields.systemSiteData[changedFieldsSiteDataIndex].action =
                  'DELETE'
                prevChangedFields.systemSiteData[changedFieldsSiteDataIndex].locale =
                  affectedLocale
              }
            } else {
              prevChangedFields.systemSiteData.push({
                action: 'DELETE',
                locale: affectedLocale
              })
            }

            return prevChangedFields
          }

          // SiteData not previously changed, just create new entry
          prevChangedFields.systemSiteData = [
            {
              action: 'DELETE',
              locale: affectedLocale
            }
          ]

          return prevChangedFields
        })
      }

      setFieldsDataRaw({
        ...newFieldsData,
        systemSiteData: [...newFieldsData.systemSiteData]
      })
    },
    [setFieldsDataRaw, setChangedFields, fieldsData]
  )

  const resetSystemForm = useCallback(() => {
    setFieldsDataRaw({})
    setMediaAssetsData([])
    setActiveTab({
      label: t('content:system'),
      value: 'SYSTEM'
    })
    setChangedFields({})
  }, [setFieldsDataRaw, setChangedFields, t])

  const resetChangedFields = useCallback(() => {
    setChangedFields({})
  }, [setChangedFields])

  const [biConfigChangedSiteIds, setBiConfigChangedSiteIds] = useState(new Set())
  const [biConfigFormState, setBiConfigFormState] = useState({})
  const [biConfigCategoryState, setBiConfigCategoryState] = useState({})
  const biInitialFormStateRef = useRef({})
  const biInitialCategoryStateRef = useRef({})

  const contextValues = useMemo(() => {
    return {
      allTabs,
      activeTab,
      setActiveTab,
      selectedCategories,
      setSelectedCategories: handleSetSelectedCategories,
      selectedCountries,
      setSelectedCountries: handleSetSelectedCountries,
      langFields: langFieldsMemo,
      fieldsData,
      changedFields,
      handleFieldChange,
      handleChange: handleChangeCallback,
      operationMode,
      mediaAssetsData,
      setMediaAssetsData,
      previewImageUrl,
      setPreviewImageUrl,
      resetSystemForm,
      resetChangedFields,
      biConfigFormState,
      biConfigCategoryState,
      biConfigChangedSiteIds,
      setBiConfigFormState,
      setBiConfigCategoryState,
      setBiConfigChangedSiteIds,
      biInitialFormStateRef,
      biInitialCategoryStateRef,
      systemSiteData: fieldsData?.systemSiteData || [],
      system: systemData
    }
  }, [
    allTabs,
    activeTab,
    selectedCategories,
    selectedCountries,
    handleSetSelectedCategories,
    langFieldsMemo,
    handleSetSelectedCountries,
    fieldsData,
    changedFields,
    handleFieldChange,
    handleChangeCallback,
    operationMode,
    mediaAssetsData,
    previewImageUrl,
    resetSystemForm,
    resetChangedFields,
    biConfigChangedSiteIds,
    biConfigFormState,
    biConfigCategoryState,
    biInitialFormStateRef,
    biInitialCategoryStateRef,
    systemData
  ])

  return (
    <ManageSystemContext.Provider value={contextValues}>
      {children}
    </ManageSystemContext.Provider>
  )
}

export const useManageSystemContext = () => React.useContext(ManageSystemContext)
