import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { TRANSFORMED_REVIEW_FRAGMENT } from '../../../../constants/gql/reviews'

const DELETE_REVIEW_COMMENT_GQL = gql`
  mutation DeleteReviewComment($reviewId: ID!, $locale: String!) {
    appDeleteReviewComment(reviewId: $reviewId, locale: $locale) {
      ${TRANSFORMED_REVIEW_FRAGMENT}
    }
  }
`

export default function useDeleteReviewComment() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appDeleteReviewComment, { loading }] = useMutation(DELETE_REVIEW_COMMENT_GQL)

  const deleteReviewComment = useCallback(
    (reviewId, locale, onCompleted) => {
      return appDeleteReviewComment({
        variables: {
          reviewId,
          locale
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appDeleteReviewComment, showSuccessNotification, showErrorNotification]
  )

  return {
    deleteReviewComment,
    deleteReviewCommentLoading: loading
  }
}
