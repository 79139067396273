import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { TRANSFORMED_REVIEW_FRAGMENT } from '../../../../constants/gql/reviews'

const EDIT_REVIEW_DATA = gql`
  mutation EditReviewData($id: ID!, $data: EditReviewInput!) {
    appEditReview(id: $id, data: $data) {
      ${TRANSFORMED_REVIEW_FRAGMENT}
    }
  }
`

export default function useEditReview() {
  const { showErrorNotification } = useOverlayNotification()

  const [editReviewDataMutation, { loading: editReviewDataLoading }] = useMutation(
    EDIT_REVIEW_DATA,
    {
      onError: (err) => {
        const error = `${err}`.split(':').reverse()[0]
        showErrorNotification({ text: error })
      }
    }
  )

  const editReviewDataCallback = useCallback(
    (reviewDataId, data) => {
      return editReviewDataMutation({
        variables: {
          id: reviewDataId,
          data
        }
      })
    },
    [editReviewDataMutation]
  )

  return {
    editReviewData: editReviewDataCallback,
    editReviewDataLoading
  }
}
