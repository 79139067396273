import React, { useState } from 'react'
import * as ga from '../../lib/ga'
import Flag from '../shared/flag'
import { useTranslation } from 'react-i18next'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CalendarIcon,
  EyeIcon
} from '@heroicons/react/solid'
import BuyingIntentIndicator from './BuyingIntentIndicator'
import BuyingIntentJourney from './BuyingIntentJourney'
import BuyingIntentCustomerProfile from './BuyingIntentCustomerProfile'
import { useMutation, gql, useApolloClient } from '@apollo/client'
import { useUserContext } from '../../contexts'
import { isAdmin } from '../../utils'
import useGetSystemName from '../../hooks/useGetSystemName'

const UPDATE_BUYING_INTENT_STATUS = gql`
  mutation UpdateBuyingIntentStatus($id: ID!) {
    appUpdateBuyingIntentActivitySystemStatus(id: $id)
  }
`
// We need to seperate the buying intents by system
const BuyingIntent = function ({ signal }) {
  const { t } = useTranslation('buyingIntent')
  const { user } = useUserContext()
  const { getSystemName } = useGetSystemName()

  const client = useApolloClient()
  const [isOpen, setIsOpen] = useState(false)
  const [biStatus, setBiStatus] = useState(signal?.status)

  const [updateBuyingIntentStatus, { loading, error }] = useMutation(
    UPDATE_BUYING_INTENT_STATUS,
    {
      variables: { id: signal.originalId },
      skip: !signal.buyingIntentActivityToSystem.id,
      update() {
        const resultData = client.readQuery({
          query: gql`
            query ReadBuyingIntentNumbers {
              appBuyingIntentNumbers {
                id
                result
              }
            }
          `
        })

        // This will probably not work as expected now
        const appBuyingIntentNumbers = resultData?.appBuyingIntentNumbers.map((bi) => {
          if (bi.id === signal.buyingIntentActivityToSystem.systemId) {
            return {
              ...bi,
              result: String(parseInt(bi.result, 10) - 1)
            }
          }
          return bi
        })

        client.writeQuery({
          // ($id: Int!) -- (id: $id)
          query: gql`
            query WriteBuyingIntentNumbers {
              appBuyingIntentNumbers {
                id
                result
              }
            }
          `,
          data: {
            appBuyingIntentNumbers
          }
        })
      }
    }
  )

  const handleBuyingIntentView = async () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      ga.event({
        action: 'open_lead_buying_intent'
      })
    }
    if (biStatus !== 'READ' && !isAdmin(user?.role)) {
      try {
        await updateBuyingIntentStatus()
        setBiStatus('READ')
      } catch (mutationError) {
        console.error('Error updating buying intent status:', mutationError)
      }
    }
  }

  return (
    <li>
      <div
        className="block hover:bg-gray-50 cursor-pointer"
        type="button"
        role="button"
        tabIndex="0"
        onClick={handleBuyingIntentView}
      >
        <div className="flex items-center px-4 py-4 sm:px-6 relative">
          {biStatus === 'NEW' && (
            <div
              className={
                'absolute text-xs top-1 left-1 p-1 rounded-sm font-semibold bg-indigo-600 text-white'
              }
            >
              {t('form.new')}
            </div>
          )}
          {biStatus === 'NEWTOUSER' && (
            <div
              className={
                'absolute text-xs top-1 left-1 p-1 rounded-sm font-semibold bg-gray-300 text-white'
              }
            >
              {t('form.new')}
            </div>
          )}
          <div className="min-w-0 flex-1 flex items-center ">
            <div className="flex items-center">
              {signal?.locales?.length > 0 && (
                <div className="py-4 sm:py-5">
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                    <div className="flex items-center group">
                      {signal.locales.map((locale, index) => {
                        const isFirst = index === 0
                        const flagSize = '45px'

                        return (
                          <div
                            key={locale}
                            className={`relative inline-block transition-all duration-300 ease-in-out ${
                              isFirst ? '' : '-ml-6 group-hover:ml-0'
                            }`}
                          >
                            <Flag
                              locale={locale}
                              className="rounded-full border-2 border-white"
                              height={flagSize}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </dd>
                </div>
              )}
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-2 items-center">
              <div>
                <p className="text-sm font-medium text-blue-800 truncate">
                  {signal.name}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <EyeIcon
                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <time title={t('content:lastSeen')} dateTime={signal.lastSeen}>
                    {new Date(signal.lastSeen)
                      ?.toISOString()
                      .split('T')
                      .join(' | ')
                      .slice(0, 18)}
                  </time>
                </p>
              </div>
              <div className="justify-items-end px-4">
                <BuyingIntentIndicator activity={signal.activity} />
              </div>
              <BuyingIntentJourney intent={signal} />
              <BuyingIntentCustomerProfile intent={signal} />
            </div>
          </div>
          <div>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-10 py-5 bg-gray-50">
          <div>
            <h4 className="text-lg leading-6 font-medium text-gray-900">
              {t('filters.buyersJourneyStage')}
            </h4>
            <div className="mt-1 max-w-2xl text-sm text-gray-500 flex gap-3 [&_p]:font-medium">
              <p>
                {t('buyersJourneyStage.awareness')}: {signal.awareness} %
              </p>
              <p>
                {t('buyersJourneyStage.consideration')}: {signal.consideration} %
              </p>
              <p>
                {t('buyersJourneyStage.decision')}: {signal.decision} %
              </p>
            </div>
          </div>
          <hr className={'my-5'} />

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('form.information')}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {t('form.companyInformation')}.
            </p>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {signal.name && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:company')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 flex">{signal.name}</dd>
                </div>
              )}
              {signal.site && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:site')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal.site}
                  </dd>
                </div>
              )}
              {signal.category && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:category')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal.bwBranch &&
                      t(`forms:system.branch.${signal.bwBranch}`) != signal.category && (
                        <span className="mr-2">
                          {t(`forms:system.branch.${signal.bwBranch}`)} -
                        </span>
                      )}
                    {signal.category}
                  </dd>
                </div>
              )}
              {signal.companySize && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('form.employees')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal.companySize}
                  </dd>
                </div>
              )}
              {signal?.revenue && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('filters.revenue')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {signal?.revenue?.max && <>{signal.revenue?.min} </>}
                    {signal?.revenue?.max && signal.revenue?.min && <>- </>}
                    {signal?.revenue?.max && <>{signal.revenue?.max}</>}
                  </dd>
                </div>
              )}
              {signal.linkedInUrl && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('form.linkedIn')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <a
                      className={'hover:text-blue-900 text-blue-600'}
                      target={'_blank'}
                      href={signal.linkedInUrl}
                      rel="noreferrer"
                    >
                      {signal.linkedInUrl}
                    </a>
                  </dd>
                </div>
              )}
              {signal?.buyingIntentActivityToSystems?.length > 1 && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    TODO: Also show interest in
                  </dt>
                  <dd></dd>
                </div>
              )}
              {signal?.buyingIntentActivityToSystems?.length > 1 &&
                signal.buyingIntentActivityToSystems
                  .filter(
                    (biaToSystem) =>
                      biaToSystem.systemId !==
                      signal.buyingIntentActivityToSystem.systemId
                  )
                  .map((biaToSystem) => {
                    return (
                      <div
                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                        key={biaToSystem.systemId}
                      >
                        <dt className="text-sm font-medium text-gray-500">
                          {getSystemName(biaToSystem)}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {t('content:customerProfileMatch')}:{' '}
                          {biaToSystem.customerProfileMatch}
                        </dd>
                      </div>
                    )
                  })}
            </dl>
          </div>
        </div>
      )}
    </li>
  )
}

export default BuyingIntent
