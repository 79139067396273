import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

const GQL_AVAILABLE_SITES = gql`
  query FetchAvailableSites {
    appGetAvailableSites {
      defaultLocale
    }
  }
`

const EMPTY_ARR = []

export default function useFetchAvailableSites(skip, includeEnglish) {
  const { data, loading } = useQuery(GQL_AVAILABLE_SITES, {
    skip
  })

  const sites = data?.appGetAvailableSites

  const localesMemo = useMemo(() => {
    if (!sites) {
      return []
    }

    const locales = sites.map((site) => site.defaultLocale)

    if (includeEnglish) {
      return ['en_GB', ...locales]
    }

    return locales
  }, [sites, includeEnglish])

  const sitesOptions = useMemo(() => {
    if (!localesMemo || !localesMemo.length) {
      return []
    }

    return localesMemo.map((locale) => {
      return {
        labelTid: `shared:localeToCountry.${locale}`,
        value: locale
      }
    })
  }, [localesMemo])

  return {
    sites: sites || EMPTY_ARR,
    locales: localesMemo,
    sitesLoading: loading,
    sitesOptions
  }
}
