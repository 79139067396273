import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const ARCHIVE_REVIEW_TEMPLATE_GQL = gql`
  mutation ArchiveReviewTemplate($reviewTemplateId: ID!) {
    archiveReviewTemplate(reviewTemplateId: $reviewTemplateId) {
      ${REVIEW_TEMPLATE_GQL}
    }
  }
`

export default function useArchiveReviewTemplate() {
  const { showErrorNotification } = useOverlayNotification()

  const [archiveReviewTemplateMutation, { loading }] = useMutation(
    ARCHIVE_REVIEW_TEMPLATE_GQL
  )

  const archiveReviewTemplate = useCallback(
    (reviewTemplateId, onCompleted) => {
      return archiveReviewTemplateMutation({
        variables: {
          reviewTemplateId
        },
        onCompleted: ({ archiveReviewTemplate }) => {
          onCompleted?.(archiveReviewTemplate)
        },
        onError: showErrorNotification
      })
    },
    [archiveReviewTemplateMutation, showErrorNotification]
  )

  return {
    archiveReviewTemplate,
    archiveReviewTemplateLoading: loading
  }
}
