import React from 'react'
import { func, string, bool, oneOfType } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useDeleteReviewComment from '../hooks/useDeleteReviewComment'

import Dialog from '../../../shared/dialog'

const DeleteReviewCommentDialog = ({ reviewId, localeToDelete, onClose }) => {
  const { t } = useTranslation()
  const { deleteReviewComment, deleteReviewCommentLoading } = useDeleteReviewComment()

  const handleDeleteTranslation = () => {
    deleteReviewComment(reviewId, localeToDelete, () => {
      onClose(false)
    })
  }

  return (
    <Dialog
      isOpen={!!localeToDelete}
      title={t('content:delete')}
      confirmText={t('content:delete')}
      onConfirm={handleDeleteTranslation}
      onClose={onClose}
      loading={deleteReviewCommentLoading}
    />
  )
}

DeleteReviewCommentDialog.propTypes = {
  reviewId: string.isRequired,
  localeToDelete: oneOfType([string, bool]),
  onClose: func.isRequired
}

DeleteReviewCommentDialog.defaultProps = {}

export default DeleteReviewCommentDialog
