import { useQuery, gql } from '@apollo/client'

import { REVIEW_TEMPLATE_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

export const GQL_FETCH_COMMON_REVIEW_TEMPLATE = gql`
  query FetchCommonReviewTemplate {
    commonReviewTemplate {
      ${REVIEW_TEMPLATE_GQL}
    }
  }
`

const EMPTY_OBJ = {}

export default function useFetchCommonReviewTemplate(fetchPolicy = 'cache-and-network') {
  const { data, loading } = useQuery(GQL_FETCH_COMMON_REVIEW_TEMPLATE, {
    fetchPolicy
  })

  return {
    commonReviewTemplate: data?.commonReviewTemplate || EMPTY_OBJ,
    commonReviewTemplateLoading: loading
  }
}
