import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const UPDATE_REVIEW_TEMPLATE_QUESTIONS_ORDER_GQL = gql`
  mutation UpdateReviewTemplateQuestionsOrder(
    $reviewTemplateId: ID!
    $questionsOrder: [String!]!
  ) {
    updateReviewTemplateQuestionsOrder(reviewTemplateId: $reviewTemplateId, questionsOrder: $questionsOrder) {
      ${REVIEW_TEMPLATE_GQL}
    }
  }
`
export default function useUpdateReviewTemplateQuestionsOrder() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateReviewTemplateQuestionsOrderMutation, { loading }] = useMutation(
    UPDATE_REVIEW_TEMPLATE_QUESTIONS_ORDER_GQL
  )

  const updateReviewTemplateQuestionsOrder = useCallback(
    (reviewTemplateId, questionsOrder, onCompleted) => {
      return updateReviewTemplateQuestionsOrderMutation({
        variables: {
          reviewTemplateId,
          questionsOrder
        },
        onCompleted: ({ updateReviewTemplateQuestionsOrder }) => {
          onCompleted && onCompleted(updateReviewTemplateQuestionsOrder)
          showSuccessNotification()
        },
        onError: showErrorNotification
      })
    },
    [
      updateReviewTemplateQuestionsOrderMutation,
      showSuccessNotification,
      showErrorNotification
    ]
  )

  return {
    updateReviewTemplateQuestionsOrder,
    updateReviewTemplateQuestionsOrderLoading: loading
  }
}
