import React from 'react'

const { useTranslation } = require('react-i18next')

const BuyingIntentIndicator = ({ activity }) => {
  const { t } = useTranslation('buyingIntent')

  let config = {
    label: 'activity.low',
    bgColor: 'bg-gray-100 border-2 border-gray-300',
    textColor: 'text-black'
  }
  // TODO: add translation for labels
  if (activity > 25 && activity < 75) {
    config = {
      label: 'activity.medium',
      bgColor: 'bg-gray-100 border-2 border-gray-300',
      textColor: 'text-blue-500'
    }
  } else if (activity >= 75) {
    config = {
      label: 'activity.high',
      bgColor: 'bg-blue-500 border-2 border-blue-500',
      textColor: 'text-white'
    }
  }

  return (
    <div
      className={`p-1 w-20 text-xs uppercase font-bold rounded text-center ${config.bgColor} ${config.textColor}`}
    >
      {t(config.label)}
    </div>
  )
}

export default BuyingIntentIndicator
