import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const UPDATE_REVIEW_TEMPLATE_GQL = gql`
  mutation UpdateReviewTemplate($reviewTemplateId: ID!, $data: ReviewTemplateInput!) {
    updateReviewTemplate(reviewTemplateId: $reviewTemplateId, data: $data) {
      id
      name
      categories
    }
  }
`
export default function useUpdateReviewTemplate() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateReviewTemplateMutation, { loading }] = useMutation(
    UPDATE_REVIEW_TEMPLATE_GQL
  )

  const updateReviewTemplate = useCallback(
    (reviewTemplateId, data, onCompleted) => {
      return updateReviewTemplateMutation({
        variables: {
          reviewTemplateId,
          data
        },
        onCompleted: ({ updateReviewTemplate }) => {
          onCompleted && onCompleted(updateReviewTemplate)
          showSuccessNotification()
        },
        onError: showErrorNotification
      })
    },
    [updateReviewTemplateMutation, showSuccessNotification, showErrorNotification]
  )

  return {
    updateReviewTemplate,
    updateReviewTemplateLoading: loading
  }
}
