import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_QUESTION_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const UPDATE_REVIEW_QUESTION_GQL = gql`
  mutation UpdateReviewTemplateQuestion($reviewQuestionId: ID!, $data: ReviewTemplateQuestionInput!) {
    updateReviewTemplateQuestion(reviewQuestionId: $reviewQuestionId, data: $data) {
      ${REVIEW_TEMPLATE_QUESTION_GQL}
    }
  }
`

export default function useUpdateReviewQuestion() {
  const { showErrorNotification } = useOverlayNotification()

  const [updateReviewQuestionMutation, { loading }] = useMutation(
    UPDATE_REVIEW_QUESTION_GQL
  )

  const updateReviewQuestion = useCallback(
    (reviewQuestionId, data, onCompleted) => {
      return updateReviewQuestionMutation({
        variables: {
          reviewQuestionId,
          data
        },
        onCompleted: ({ updateReviewTemplateQuestion }) => {
          onCompleted?.(updateReviewTemplateQuestion)
        },
        onError: showErrorNotification
      })
    },
    [updateReviewQuestionMutation, showErrorNotification]
  )

  return {
    updateReviewQuestion,
    updateReviewQuestionLoading: loading
  }
}
