import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { MenuIcon, PlusCircleIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

import useArchiveReviewQuestionAlternative from '../../../../hooks/manage-review-templates/useArchiveReviewQuestionAlternative'
import useReorderReviewQuestionAlternatives from '../../../../hooks/manage-review-templates/useReorderReviewQuestionAlternatives'

import ManageQuestionAnswerDialog from './manage-question-answer-dialog'
import LoadingSpinner from '../../../shared/loading-spinner'
import Alert from '../../../shared/alert'
import Button from '../../../shared/button'
import Dialog from '../../../shared/dialog'

const portal = document.createElement('div')
document.body.appendChild(portal)

const AnswerAlternativeItem = ({
  readOnly,
  disableDrag,
  provided,
  snapshot,
  dragLoading,
  item,
  isLast,
  onEdit,
  onDelete,
  index
}) => {
  const { t } = useTranslation()

  const usePortal = snapshot?.isDragging

  const component = (
    <div
      className={cx('flex py-2 pr-2 gap-2 border even:border-y-0', {
        'bg-slate-200': snapshot?.isDragging,
        'px-2': readOnly,
        '!border-b': isLast
      })}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      {!disableDrag && !readOnly && (
        <div
          className={cx('flex items-center border-r px-2', {
            'cursor-move': !dragLoading
          })}
          {...provided?.dragHandleProps}
        >
          {dragLoading ? (
            <LoadingSpinner className="w-4 h-4" />
          ) : (
            <MenuIcon className="w-4 h-4 text-gray-600" />
          )}
        </div>
      )}

      <div className="flex w-full flex-row gap-1 flex-col justify-center">
        {index + 1}. {item?.texts?.en_GB}
      </div>

      {!readOnly && (
        <div className="flex items-center border-l pl-2 gap-2">
          <Button Icon={PencilIcon} mode="default" onClick={() => onEdit(item.id)} />
          <Button Icon={TrashIcon} mode="error" onClick={() => onDelete(item.id)} />
        </div>
      )}
    </div>
  )

  if (!usePortal) {
    return component
  }

  // if dragging - put the item in a portal
  return createPortal(component, portal)
}

const RadioQuestionAlternatives = ({ question, readOnly }) => {
  const { t } = useTranslation()
  const [manageAnswerId, setManageAnswerId] = useState(false)
  const [deleteAnswerId, setDeleteAnswerId] = useState(false)
  const [tempSortedAnswers, setTempSortedAnswers] = useState(null)

  const { archiveReviewQuestionAlternative, archiveReviewQuestionAlternativeLoading } =
    useArchiveReviewQuestionAlternative()

  const { reorderReviewQuestionAlternatives, reorderReviewQuestionAlternativesLoading } =
    useReorderReviewQuestionAlternatives()

  const questionId = question.id
  const questionSaved = !!questionId

  const questionAnswersMemo = useMemo(() => {
    return tempSortedAnswers ? tempSortedAnswers : question?.reviewAnswers || []
  }, [question?.reviewAnswers, tempSortedAnswers])

  const handleCloseCreator = () => {
    setManageAnswerId(false)
  }

  const handleOpenCreator = () => {
    setManageAnswerId(true)
  }

  const handleCloseDelete = () => {
    setDeleteAnswerId(false)
  }

  const handleConfirmDelete = () => {
    archiveReviewQuestionAlternative(questionId, deleteAnswerId, handleCloseDelete)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleDragEnd = (opt) => {
    // dropped outside the list
    if (!opt.destination) {
      return
    }

    const updatedItems = reorder(
      questionAnswersMemo,
      opt.source.index,
      opt.destination.index
    )

    setTempSortedAnswers(updatedItems)

    const answerIds = updatedItems.map((answer) => answer.id)

    reorderReviewQuestionAlternatives(questionId, answerIds, () => {
      setTempSortedAnswers(null)
    })
  }

  return (
    <div className="flex flex-col">
      <p className="text-gray-700 font-semibold mb-4">
        {t('reviewTemplates:template.manage.answerAlternatives')}
      </p>

      <div className="flex flex-col">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questionAnswersMemo.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={reorderReviewQuestionAlternativesLoading}
                  >
                    {(provided, snapshot) => (
                      <AnswerAlternativeItem
                        item={item}
                        provided={provided}
                        snapshot={snapshot}
                        onEdit={setManageAnswerId}
                        onDelete={setDeleteAnswerId}
                        isLast={questionAnswersMemo.length - 1 === index}
                        readOnly={readOnly}
                        index={index}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {!readOnly && (
          <>
            {questionSaved ? (
              <>
                <div
                  className="flex flex-col bg-slate-100 border rounded p-2 items-center cursor-pointer mt-4 hover:bg-slate-200"
                  onClick={handleOpenCreator}
                >
                  <span className="text-gray-600">
                    {t('reviewTemplates:template.manage.createAnswerAlternative')}
                  </span>
                  <PlusCircleIcon className="w-6 text-gray-600" />
                </div>

                <ManageQuestionAnswerDialog
                  question={question}
                  answerId={manageAnswerId}
                  onClose={handleCloseCreator}
                />

                <Dialog
                  isOpen={!!deleteAnswerId}
                  title={t('content:confirmDelete')}
                  confirmText={t('content:archive')}
                  cancelText={t('content:cancel')}
                  onConfirm={handleConfirmDelete}
                  onClose={handleCloseDelete}
                  loading={archiveReviewQuestionAlternativeLoading}
                />
              </>
            ) : (
              <Alert>{t('reviewTemplates:template.manage.saveBeforeAdding')}</Alert>
            )}
          </>
        )}
      </div>
    </div>
  )
}

RadioQuestionAlternatives.propTypes = {}

RadioQuestionAlternatives.defaultProps = {}

export default RadioQuestionAlternatives
