import { useQuery, gql } from '@apollo/client'

import { REVIEW_TEMPLATE_LIST_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const GQL_FETCH_REVIEW_TEMPLATES = gql`
  query ListReviewTemplates {
    reviewTemplates {
      ${REVIEW_TEMPLATE_LIST_GQL}
    }
  }
`

const EMPTY_ARR = []

export default function useFetchReviewTemplates(fetchPolicy) {
  const { data, loading } = useQuery(GQL_FETCH_REVIEW_TEMPLATES, {
    fetchPolicy
  })

  return {
    reviewTemplates: data?.reviewTemplates || EMPTY_ARR,
    reviewTemplatesLoading: loading
  }
}
