import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { REVIEW_TEMPLATE_GQL } from '../../components/admin/manage-review-templates/constants/review-template.gql'

const LINK_COMMON_QUESTION_GQL = gql`
  mutation LinkCommonReviewQuestion($reviewTemplateId: ID!, $commonQuestionId: ID!) {
    linkCommonReviewQuestion(reviewTemplateId: $reviewTemplateId, commonQuestionId: $commonQuestionId) {
      ${REVIEW_TEMPLATE_GQL}
    }
  }
`

export default function useLinkCommonReviewQuestion() {
  const { showErrorNotification } = useOverlayNotification()

  const [linkCommonReviewQuestionMutation, { loading }] = useMutation(
    LINK_COMMON_QUESTION_GQL
  )

  const linkCommonReviewQuestion = useCallback(
    (reviewTemplateId, commonQuestionId, onCompleted) => {
      return linkCommonReviewQuestionMutation({
        variables: {
          reviewTemplateId,
          commonQuestionId
        },
        onCompleted: ({ linkCommonReviewQuestion }) => {
          onCompleted?.(linkCommonReviewQuestion)
        },
        onError: showErrorNotification
      })
    },
    [linkCommonReviewQuestionMutation, showErrorNotification]
  )

  return {
    linkCommonReviewQuestion,
    linkCommonReviewQuestionLoading: loading
  }
}
