import React, { useState } from 'react'
import { string } from 'prop-types'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useArchiveReviewTemplate from '../../../../hooks/manage-review-templates/useArchiveReviewTemplate'

import Button from '../../../shared/button'
import Dialog from '../../../shared/dialog'

const ArchiveTemplate = ({ reviewTemplateId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const { archiveReviewTemplate, archiveReviewTemplateLoading } =
    useArchiveReviewTemplate()

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }

  const handleCancel = () => {
    setConfirmOpen(false)
  }

  const handleArchive = () => {
    archiveReviewTemplate(reviewTemplateId, () => {
      navigate('/admin-manage-review-templates')
    })
  }

  return (
    <>
      <Button
        onClick={handleOpenConfirm}
        loading={archiveReviewTemplateLoading}
        mode="error"
      >
        {t('content:archive')}
      </Button>

      <Dialog
        title={t('content:archive')}
        isOpen={confirmOpen}
        onClose={handleCancel}
        onConfirm={handleArchive}
        cancelText={t('content:cancel')}
        confirmText={t('content:archive')}
        confirmDisabled={archiveReviewTemplateLoading}
        cancelDisabled={archiveReviewTemplateLoading}
      />
    </>
  )
}

ArchiveTemplate.propTypes = {
  reviewTemplateId: string.isRequired
}

ArchiveTemplate.defaultProps = {}

export default ArchiveTemplate
