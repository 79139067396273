import React from 'react'

import Label from '../../../shared/label'
import TextArea from '../../../shared/textarea'
import Input from '../../../shared/input'
import Radio from '../../../shared/radio'

const InputComponent = ({ label }) => {
  return (
    <Label title={label} marginless>
      <Input />
    </Label>
  )
}

const TextAreaComponent = ({ label }) => {
  return (
    <Label title={label} marginless>
      <TextArea rows={2} />
    </Label>
  )
}

const RatingComponent = ({ label }) => {
  return (
    <Label title={label} marginless>
      <div className="flex gap-2">
        {[1, 2, 3, 4, 5].map((item) => {
          return (
            <div key={item} className="p-2 bg-[#EAF5FB] rounded-3xl">
              <Radio className="w-6 h-6 text-slate-400" text={item} />
            </div>
          )
        })}
      </div>
    </Label>
  )
}

const RadioComponent = ({ label, previewLocale, answers }) => {
  return (
    <Label title={label} marginless>
      <div className="flex flex-col">
        {answers?.map((answer, index) => {
          const answerText = answer?.texts?.[previewLocale] || answer?.texts?.en_GB

          return <Radio key={index} label={answerText} />
        })}
      </div>
    </Label>
  )
}

const TilesComponent = ({ label, previewLocale, answers }) => {
  return (
    <Label title={label} marginless>
      <div className="flex gap-2">
        {answers?.map((answer, index) => {
          const answerText = answer?.texts?.[previewLocale] || answer?.texts?.en_GB
          return (
            <div
              key={index}
              className="flex w-full p-2 bg-blue-500 rounded-md items-center justify-center"
            >
              <p className="text-center font-medium text-white">{answerText}</p>
            </div>
          )
        })}
      </div>
    </Label>
  )
}

const PreviewComponent = ({ question, previewLocale, index }) => {
  const labelText = question.texts[previewLocale] || question.texts.en_GB
  const label = `${index + 1}. ${labelText}${question.required ? '*' : ''}`

  switch (question.type) {
    case 'INPUT':
      return <InputComponent label={label} />
    case 'TEXTAREA':
      return <TextAreaComponent label={label} />
    case 'RATING':
      return <RatingComponent label={label} />
    case 'RADIO':
      return (
        <RadioComponent
          answers={question.reviewAnswers}
          previewLocale={previewLocale}
          label={label}
        />
      )
    case 'TILES':
      return (
        <TilesComponent
          answers={question.reviewAnswers}
          previewLocale={previewLocale}
          label={label}
        />
      )

    default:
      return <span>Preview not implemented for {question.type}</span>
  }
}

const QuestionTypePreview = ({ question, previewLocale, index }) => {
  return (
    <div className="px-4 py-3 bg-slate-50 rounded pointer-events-none">
      <PreviewComponent question={question} previewLocale={previewLocale} index={index} />
    </div>
  )
}

QuestionTypePreview.propTypes = {}

QuestionTypePreview.defaultProps = {}

export default QuestionTypePreview
